import { apiEndPoints } from "utils/api-endpoints";
import HTTPService from "utils/httpService";

export const getAPIDataById = (param: number): Promise<IAPI> => {
  return HTTPService.get(apiEndPoints.getAPIDataById + param);
};

interface IAPIData {
  ErrorDescription: string;
  ErrorTypes: string[];
  HTTPstatus: [{ status_code: number; message: string }];
  appFeatures: string[];
  appId: string;
  appSecretKey: string;
  updatedAt: Date;
  usageType: "both";
  app_url: string;
  createdAt: string;
  description: string;
  desktop_url: string;
  document_url: string;
  extension_url: string;
  features: string[];
  id: number;
  ios_url: string;
  isAvailable: number;
  liveUrlDomain: string;
  logo: string;
  logoIcon: string;
  name: string;
  order: number;
  redirect_url: string;
  shortDescription: string;
  status: string;
  subtitle: string;
  suggestionApplication: string;
  type: string;
}
export interface IAPI {
  data: IAPIData;
  message: "Application info get successfully";
  status: 200;
  subscribed: 1;
}
