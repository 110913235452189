import { apiEndPoints } from "utils/api-endpoints";
import HTTPService from "utils/httpService";

export const removeTemplateAPI = (id: string, data: any): Promise<IremoveTemplateAPI> => {
  return HTTPService.delete(apiEndPoints.universeAPI.folderTemplate.removeTemplate + id + "/file", data);
};

interface IremoveTemplateAPI {
  status: number;
  message: string;
}
