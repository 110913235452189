export const apiEndPoints = {
  underMaintanance: "settings",

  loginAPI: "auth/login",
  registerAPI: "auth/register",
  verifyUserAPI: "auth/userverify",
  getAPPlicationsByUserId: "auth/apps/",
  submitUserKyc: "auth/kyc",

  getAvailableApps: "applications/available/application",
  kycList: "users/kyc",
  allApplicationData: "applications",
  apiDescription: "api-descriptions/",
  universeRequest: "networks",
  appsPermission: "permissions",
  approveKyc: "users/approve-kyc",
  members: "users/members",
  availableApps: "applications/available-apps",
  getAPIDataById: "applications/",
  unSubscribe: "user-applications/unsubscribe",
  subscribe: "user-api-creds",
  allAPIData: "applications/api",
  getProfileAPI: "users/user-info",
  notification: "notification",
  networkDomains: "network-domains",
  networkRegions: "network-regions",

  chat: {
    send: "universe",
  },
  webHook: {
    createWebHooksAPI: "bstamp/webhooks",
    getWebHooksAPI: "bstamp/webhooks",
    webHookAuthenticate: "bstamp/authenticate",
    getEventList: "bstamp/webhooks/events",
  },
  universeAPI: {
    network: {
      info: "network-info",
      get: "networks",
    },
    channelManagement: {
      shared: {
        list: "channels/shared",
      },
      remainder: "channels/invitation-remainder",
      resend: "otp/resend",
      find: "channels",
      create: "channels",
      list: "channels",
      findUser: "users",
      invitation: "channels/channel-invitations",
      sendOtp: "otp/resend",
      verificationOtp: "otp/verify",
      view: "channels/",
      addParticipantsAPI: "channels/participants/",
      invitationRejectedAccepted: "channels/invitation/",
    },
    contracts: {
      upload: "contracts",
    },
    dashboard: {
      requesthistory: {
        list: "dashboard/request-history",
      },
      blockchain: "dashboard/blockchain",
      user: "dashboard/channels/users",
      activity: "dashboard/activity-logs",
    },
    templates: {
      list: "templates",
      view: "templates/",
      reference: "templates/reference",
      upload: "templates/upload",
      chainCode: "chain-codes",
      contractsList: "contracts",
      listChainCode: "chain-codes",
    },
    folderTemplate: {
      create: "repository",
      list: "repository",
      view: "repository/",
      move: "repository/",
      rename: "repository/",
      removeTemplate: "repository/", //  "/repository/{repositoryId}/file"
      delete: "repository/",
    },
    smartContract: {
      list: "contracts",
      view: "contracts/",
      install: "contracts/", //  /contracts/{contractId}/channels
      installationContract: "contracts/channels",
    },
    myBlockChain: {
      peer: {
        list: "peer",
        create: "peer",
        view: "peer/",
      },
      certificate: {
        list: "certificate-authorities",
      },
      order: {
        list: "order",
        view: "order/",
      },
      blockProcess: {
        get: "/metrics/block-process",
      },
      transactionCount: {
        get: "/metrics/transaction-count",
      },
      cpuUsage: {
        get: "/metrics/cpu-usage",
      },
      memoryUsage: {
        get: "/metrics/memory-usage",
      },
      blockchainHeight: {
        get: "/metrics/blockchain-height",
      },
      storageSpace: {
        get: "/metrics/storage-space",
      },
    },
    peerNode: {
      overview: {
        list: "peer",
      },
      metricsMonitoring: {
        list: "peer",
      },
      cloudConfigurations: {
        list: "peer",
      },
      settings: {
        list: "peer",
      },
    },
    notification: {
      delete: "notification",
    },
  },
  auth: {
    logout: "auth/logout",
  },
  tokenEngineForm: {
    submit: "token-engine",
  },
  logs: {
    logsHistory: "/peer",
  },
};
