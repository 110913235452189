/* eslint- react-hooks/rules-of-hooks */

import ConnectToChain from "components/dashboard/ConnectToChain";
import Documentation from "components/dashboard/Documentation";
import { Col, Row } from "reactstrap";
import "../../../styles/channelmanagement/channelmanagement.scss";
import "../../../styles/dashboard_layout/dashboard.scss";
import ChannelInvitationComponent from "./ChannelInvitationComponent";
import ChannelManagementTable from "./ChannelManagementTable";
import ParticipantsChannelTable from "./ParticipantsChannelTable";

const ChannelManagement = () => {
  return (
    <div className="channelmanagement-wrapper">
      <Row>
        <Col md="12" lg="12" xl="8">
          <div className="title">
            <h5 className="c-tx-primary f-700 mb-20">Channel Management</h5>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md="12" lg="12" xl="8">
          {/* Channel Management */}
          <ChannelManagementTable />

          {/* Received Invitation */}
          <ChannelInvitationComponent />

          <ParticipantsChannelTable />
        </Col>
        <Col md="12" lg="12" xl="4">
          <Row>
            <Col md="6" lg="6" xl="12">
              <ConnectToChain />
            </Col>
            <Col md="6" lg="6" xl="12">
              <Documentation />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default ChannelManagement;
