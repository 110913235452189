import { Button, Col } from "reactstrap";
import { toastSuccess } from "utils/commonFunctions";

const SettingListComponent = () => {
  // const dispatch = useDispatch();
  // eslint-disable-next-line
  // const [settingList, setSettingList] = useState<any[]>([]);
  // const [pagination, setPagination] = useState({ limit: 10, page: 1, totalCount: 0 });
  // const getSettingListListAction = () => {
  //   dispatch(setLoading(true));
  //   getSettingsListAPI({ limit: pagination.limit, page: pagination.page }).then((res: any) => {
  //     if (res?.status === 200) {
  //       setSettingList(res?.data?.data);
  //       setPagination({ ...pagination, totalCount: res?.data?.count });
  //     }
  //     dispatch(setLoading(false));
  //   });
  // };
  // useEffect(() => {
  //   getSettingListListAction();
  //   // eslint-disable-next-line
  // }, []);
  return (
    <Col md="12" lg="12" xl="8" xxl="8">
      <div className="backdrop-block cloud-configuration-wrapper h-100">
        <div className="title">
          <h5 className="c-tx-primary f-600 mb-10">Node Settings</h5>
        </div>
        <ul className="cloud-list">
          <li>
            <div className="first-peer-title">
              <p className="c-tx-primary f-700">Change node name</p>
              <p className="c-tx-ninth small mt-1">Change the name that identifies the node.</p>
            </div>
            <div className="second-peer-title">
              <div className="text-bl">
                <p className="c-tx-primary f-700">Sueshaw</p>
              </div>
              <div className="">
                <Button className="custom-primary-outline new-btn br-5">CHANGE</Button>
              </div>
            </div>
          </li>
          <li>
            <div className="first-peer-title">
              <p className="c-tx-primary f-700">Change node size</p>
              <p className="c-tx-ninth small mt-1 ">Change the size of the node to increase memory and CPU.</p>
            </div>
            <div className="second-peer-title">
              <div className="text-bl">
                <p className="c-tx-primary f-700">Small</p>
              </div>
              <div className="">
                <Button className="custom-primary-outline new-btn br-5">CHANGE</Button>
              </div>
            </div>
          </li>
          <li>
            <div className="first-peer-title">
              <p className="c-tx-primary f-700">Restart node</p>
              <p className="c-tx-ninth small mt-1 ">Restart the node keeping its actual configuration.</p>
            </div>
            <div className="second-peer-title">
              <div className="text-bl"></div>
              <div className="">
                <Button className="custom-primary-outline new-btn br-5" onClick={() => toastSuccess("Coming Soon...")}>
                  RESTART
                </Button>
              </div>
            </div>
          </li>
          <li>
            <div className="first-peer-title">
              <p className="c-tx-primary f-700">Reset node</p>
              <p className="c-tx-ninth small mt-1 ">Hard reset node to apply new configurations.</p>
            </div>
            <div className="second-peer-title">
              <div className="text-bl"></div>
              <div className="">
                <Button className="custom-primary-outline new-btn br-5" onClick={() => toastSuccess("Coming Soon...")}>
                  RESET
                </Button>
              </div>
            </div>
          </li>
          <li>
            <div className="first-peer-title">
              <p className="c-tx-primary f-700">Delete node</p>
              <p className="c-tx-ninth small mt-1 ">Delete the node.</p>
            </div>
            <div className="second-peer-title">
              <div className="text-bl"></div>
              <div className="">
                <Button className="custom-primary-outline new-btn br-5" onClick={() => toastSuccess("Coming Soon...")}>
                  DELETE
                </Button>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </Col>
  );
};

export default SettingListComponent;
