import moment from "moment";
import { memo } from "react";
import { Col, Row } from "reactstrap";
import "../../styles/footer/footer.scss";
// import "../../styles/components/footer/footer.scss";

const FooterUnderComing = () => {
  const rediretUrl = (type: any) => {
    if (type === "privacy") {
      window.open(`${process.env.REACT_APP_privacy}`, "_blank");
    } else if (type === "terms") {
      window.open(`${process.env.REACT_APP_tCondition}`, "_blank");
    } else if (type === "about") {
      window.open(`${process.env.REACT_APP_edexaDomain}/about`, "_blank");
    } else if (type === "help") {
      window.open(`${process.env.REACT_APP_edexaDomain}/`, "_blank");
    }
  };

  return (
    <div className="footer-wrapper d-flex justify-content-center">
      <div className="w-100" style={{ maxWidth: "99%" }}>
        <Row>
          <Col sm="6" className="text-center text-sm-start">
            <p className="c-tx-ninth mb-0">edeXa - Customer Admin Universe © {moment().year()}</p>
          </Col>
          <Col sm="6">
            <ul className="page-link-list justify-content-center justify-content-sm-end">
              <li
                onClick={(event) => {
                  event?.preventDefault();
                  rediretUrl("privacy");
                }}>
                <a href="#">Privacy</a>
              </li>
              <li
                onClick={(event) => {
                  event?.preventDefault();
                  rediretUrl("terms");
                }}>
                <a href="#">Terms</a>
              </li>
              <li
                onClick={(event) => {
                  event?.preventDefault();
                  rediretUrl("about");
                }}>
                <a href="#">About</a>
              </li>
            </ul>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default memo(FooterUnderComing);
