import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { IRootReducer } from 'store/root-reducer'

const useComingSoonHook = () => {
  const navigate = useNavigate()
  const globalSetting = useSelector(
    (state: IRootReducer) => state.GlobalSettingReducer
  )
  const location = useLocation()
  useEffect(() => {
    if (
      globalSetting.comingSoon &&
      location.pathname.includes('application')
    ) {
      navigate('/application')
    } // eslint-disable-next-line
    else if (
      globalSetting.comingSoon &&
      !location.pathname.includes('application')
    ) {
      navigate('/')
    }
  }, [globalSetting.comingSoon])

  return null
}

export default useComingSoonHook
