import { FC, useState } from "react";
import { Button } from "reactstrap";
import ChanelViewModalComponent from "./ChanelViewModalComponent";

interface IChannelListingComponent {
  data: any;
  index: number;
  page: number;
  limit: number;
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
  setId: React.Dispatch<React.SetStateAction<string>>;
}

const ChannelListingComponent: FC<IChannelListingComponent> = ({ data, index, limit, page, setModal, setId }) => {
  const [viewmodal, setViewModal] = useState(false);

  return (
    <>
      <tr>
        <td>{limit * page + (index + 1) - limit}</td>
        <td>{data?.channelName}</td>
        <td>{data?.totalParticipate}</td>
        <td>{data?.channelHeight}</td>
        <td className="text-center">
          <div className="btn-wrapper_block">
            <Button
              className="custom-primary key w-100"
              onClick={() => {
                setId(data?.id);
                setModal(true);
              }}>
              View
            </Button>
          </div>
        </td>
      </tr>
      <ChanelViewModalComponent id={""} viewmodal={viewmodal} setViewModal={setViewModal} />
    </>
  );
};

export default ChannelListingComponent;
