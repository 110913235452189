import { apiEndPoints } from "utils/api-endpoints";
import HTTPService from "utils/httpService";

export const getAllNotificationsList = (params: { limit: number; page: number }): Promise<INotificationListResponse> => {
  return HTTPService.get(apiEndPoints.notification, params);
};

export const getNotificationDetailById = (id: string): Promise<INotification> => {
  return HTTPService.get(`${apiEndPoints.notification}/${id}`);
};

export const deleteNotificationById = (notificationId: string[]): Promise<{ status: number; message: string }> => {
  return HTTPService.patch(apiEndPoints.notification, {
    notificationId
  });
};

export interface INotificationListResponse {
  message: string;
  status: number;
  data: {
    total: number;
    unRead: number;
    data: INotification[];
  };
}
export interface INotification {
  id: string;
  message: string;
  status: number;
  userId: number;
  createdAt: string;
  userNotification: {
    email: string;
    name: string;
  };
}
