import { apiEndPoints } from "utils/api-endpoints";
import HTTPService from "utils/httpService";

export const installationContractAPI = (id: string, data: IinstallationContractAPI): Promise<IinstallationContractAPIRes> => {
  return HTTPService.post(apiEndPoints.universeAPI.smartContract.install + id + "/channels", data);
};

interface IinstallationContractAPI {
  channelId: any;
}

interface IinstallationContractAPIRes {
  status: number;
  message: string;
}
