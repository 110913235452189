/* eslint-disable react-hooks/exhaustive-deps */
import { getMemoryUsageGraphApiInfo } from 'actions/universeActions/myBlockChain/blockProcessAPI'
import { HighchartsReact } from 'highcharts-react-official'
import Highcharts from 'highcharts/highstock'
import { useEffect, useState } from 'react'
import { Col, Row } from 'reactstrap'
import { getGraphOptions } from 'utils/graphs/getOptions'
import CommonGraphs from 'utils/graphs/graphCommonFunctions'
import '../../../../src/styles/pages/myblockchain/myblockchain.scss'
import '../../../styles/PeerNode/peerNodeChart.scss'

const MemoryUsage = () => {
  const [
    storeSelectedTimeAndItsValue,
    setStoreSelectedTimeAndItsValue
  ] = useState<any>({})
  const [memoryUsageGraphData, setMemoryUsageGraphData] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [profile, setProfile] = useState<any>([])

  // get Time
  const handleSelectedTime = (startTime: number) => {
    setStoreSelectedTimeAndItsValue({
      startTime,
      endTime: Math.floor(Date.now() - 24 * 60 * 60 * 1000) / 1000
    })
  }

  useEffect(() => {
    setIsLoading(true)
    let params: any = {}
    params = {
      storeSelectedTimeAndItsValue
    }
    getMemoryUsageGraphApiInfo(
      params,
      profile.value ? profile.value : ''
    )
      .then((response: any) => {
        setMemoryUsageGraphData(response?.data)
        setIsLoading(false)
      })
      .catch((err: any) => {
        setIsLoading(false)
      })
  }, [storeSelectedTimeAndItsValue, profile?.value])

  //  getting options from common
  const optionsData: any = getGraphOptions(memoryUsageGraphData)

  return (
    <>
      <div
        className="backdrop-block"
        style={{ minHeight: '495px', maxHeight: '495px' }}
      >
        <>
          <CommonGraphs
            handleSelectedTime={handleSelectedTime}
            isLoading={isLoading}
            setProfile={setProfile}
            profile={profile}
            graphName="Memory Usage"
          />
        </>

        {memoryUsageGraphData.length > 0 && !isLoading ? (
          <Row>
            <Col
              md="12"
              style={{
                visibility: `${isLoading ? 'hidden' : 'visible'}`
              }}
            >
              <div className="hide-highcharts-tag">
                <HighchartsReact
                  highcharts={Highcharts}
                  options={optionsData}
                />
              </div>
            </Col>
          </Row>
        ) : (
          memoryUsageGraphData.length === 0 &&
          !isLoading && (
            <p
              style={{
                marginTop: '20%',
                marginLeft: '40%'
              }}
            >
              NO DATA FOUND
            </p>
          )
        )}
      </div>
    </>
  )
}

export default MemoryUsage
