import { Application } from "components/apps";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Col, Row, Tooltip } from "reactstrap";

const AllApplicationList = ({ listingData }: any) => {
  const [allApplicationData, setAllApplicationData] = useState<any[]>([]);
  const [toggle, setToggle] = useState(false);
  const [applicationToolTip, setApplicationToolTip] = useState<any>({});

  const toogleTooltip = (i: number) => {
    setApplicationToolTip({
      [i]: !applicationToolTip[i]
    });
  };

  // load only three apps on first mound
  // if user click on load all then render all the apps to dom
  useEffect(() => {
    // slice to only 3 values
    setAllApplicationData(listingData?.totalApplications?.slice(0, 3));
  }, [listingData]);

  useEffect(() => {
    setAllApplicationData(toggle ? listingData.totalApplications : listingData?.totalApplications?.slice(0, 3));
  }, [toggle, listingData]);

  return (
    <>
      <div className="list-title">
        <Row className="align-items-center justify-content-center">
          <Col md="6" sm="12" xs="12">
            <div className="title">
              <h1 className="c-tx-primary mb-md-0 f-700">All Applications</h1>
            </div>
          </Col>
          <Col md="6" sm="12" xs="12">
            <div className="d-flex justify-content-md-end  align-items-center">
              <Link
                to="/"
                className="custom-link"
                onClick={(e) => {
                  e.preventDefault();
                  setToggle(!toggle);
                  // setAllApplicationData(listingData?.totalApplications);
                }}>
                {" "}
                VIEW ALL ({listingData?.totalApplications?.length})
              </Link>
            </div>
          </Col>
        </Row>
      </div>
      <Row>
        {allApplicationData &&
          allApplicationData?.map((apiItem: any, index: number) => {
            return (
              <Col xl="4" lg="6" md="6" sm="6" xs="12" key={index}>
                {/* @ts-ignore */}
                <div id={`applicationTooltip${index}`}>
                  <Application api="enable" subscribe="enable" apifeature="enable" allAPIData={apiItem} key={index + 1} />
                  {(!allApplicationData[index]?.isAvailable || allApplicationData[index]?.appId === "ooyxk2Hxqro13DmsThRrdn23O") && (
                    <Tooltip placement="top" isOpen={applicationToolTip[`${index}`]} target={`applicationTooltip${index}`} toggle={() => toogleTooltip(index)}>
                      Coming Soon
                    </Tooltip>
                  )}
                </div>
              </Col>
            );
          })}
      </Row>
    </>
  );
};

export default AllApplicationList;
