import { apiEndPoints } from 'utils/api-endpoints'
import HTTPService from 'utils/httpService'

export const getPeerListAPI = (
  params: any,
  search?: string
): Promise<IgetPeerListAPIRes> => {
  if (search) {
    return HTTPService.get(
      `${apiEndPoints.universeAPI.myBlockChain.peer.list}?search=${
        search || ''
      }`,
      params
    )
  } else {
    return HTTPService.get(
      apiEndPoints.universeAPI.myBlockChain.peer.list,
      params
    )
  }
}

export interface IgetPeerListAPIResData {
  id: string
  name: string
  peerRegionId: {
    name: string
  }
  peerUrl: string
  status: number
}

interface IgetPeerListAPIRes {
  message: string
  status: number
  data: {
    count: number
    data: IgetPeerListAPIResData[]
  }
}
