import { Api } from "components/api";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Col, Row, Tooltip } from "reactstrap";

const AllAPIList = ({ listingData, getAPIDetails }: any) => {
  const [allAPIData, setAllAPIData] = useState<any[]>([]);
  const [toggle, setToggle] = useState(false);
  // load only three apps on first mound
  // if user click on load all then render all the apps to dom
  useEffect(() => {
    // slice to only 3 values
    setAllAPIData(listingData?.slice(0, 3));
  }, [listingData]);

  const [apiToolTip, setApiToolTip] = useState<any>({});

  const toogleTooltip = (i: number) => {
    setApiToolTip({
      [i]: !apiToolTip[i]
    });
  };
  useEffect(() => {
    setAllAPIData(toggle ? listingData : listingData?.slice(0, 3));
  }, [listingData, toggle]);

  return (
    <React.Fragment>
      <div className="list-title">
        <Row className="align-items-center justify-content-center">
          <Col md="6" sm="12" xs="12">
            <div className="title">
              <h2 className="c-tx-primary mb-md-0 h2 f-700">All API's</h2>
            </div>
          </Col>
          <Col md="6" sm="12" xs="12">
            <div className="d-flex justify-content-end align-items-center">
              <Link
                to="#"
                className="custom-link"
                onClick={(e) => {
                  e.preventDefault();
                  // setAllAPIData(listingData);
                  setToggle(!toggle);
                }}>
                {" "}
                VIEW ALL ({listingData?.length} API'S)
              </Link>
            </div>
          </Col>
        </Row>
      </div>
      <Row>
        {allAPIData &&
          allAPIData.length > 0 &&
          allAPIData?.map((apiItem: any, index: number) => {
            return (
              <Col xl="4" lg="6" md="6" sm="6" xs="12" key={index}>
                <div id={`apiTooltip${index}`}>
                  <Api api="enable" subscribe="enable" allAPIData={apiItem} getAPIDetails={getAPIDetails} />
                  {!allAPIData[index]?.isAvailable && (
                    <Tooltip placement="top" isOpen={apiToolTip[`${index}`]} target={`apiTooltip${index}`} toggle={() => toogleTooltip(index)}>
                      Coming Soon
                    </Tooltip>
                  )}
                </div>
              </Col>
            );
          })}
      </Row>
    </React.Fragment>
  );
};

export default AllAPIList;
