import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC } from "react";
import "../../styles/template/template.scss";

interface IFolder {
  setCreateModal: React.Dispatch<
    React.SetStateAction<{
      open: boolean;
      id: string;
      rename: string;
    }>
  >;
}

const FolderEmpty: FC<IFolder> = ({ setCreateModal }) => {
  return (
    <>
      <div className="element templateblock folder cursor-pointer mr-20 h-100" onClick={() => setCreateModal({ open: true, rename: "", id: "" })} style={{ minHeight: "68px" }}>
        <div className="block-2 d-flex align-items-center">
          <div className="bl_1">
            <h6 className="c-tx-primary mb-0 text-truncate f-700">Create new folder</h6>
          </div>
          <div className="bl_2 d-flex justify-content-center" style={{ width: "20px" }}>
            <FontAwesomeIcon icon={faPlus} style={{ color: "#868686" }} />
          </div>
        </div>
      </div>
    </>
  );
};

export default FolderEmpty;
