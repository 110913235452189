import { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Button, Modal } from "reactstrap";
import { IRootReducer } from "store/root-reducer";
import { setTemplateData, setTemplateModal, setTemplateSubmit } from "store/templateReducer/action";

const TemplateViewDetailsModal = () => {
  const dispatch = useDispatch();
  const { ReviewTemplateModal } = useSelector((state: IRootReducer) => state.TemplateReducer.modal);
  const { data, templateGetData } = useSelector((state: IRootReducer) => state.TemplateReducer);

  return (
    <Modal
      isOpen={ReviewTemplateModal}
      className="custom-popup-channel"
      toggle={() => {
        dispatch(setTemplateModal("ReviewConfimationTemplateModal"));
      }}
      size="sm"
      style={{ maxWidth: "320px" }}
      centered
      external={
        <Link
          to="#"
          className="f-700 text-white close modal-close close small text-uppercase thankYouCloseButtonHide font-30"
          onClick={() => {
            dispatch(setTemplateData({}));
            dispatch(setTemplateModal("ReviewConfimationTemplateModal"));
          }}>
          CLOSE
        </Link>
      }>
      <div className="erc-template-wrapper modal-pad-30">
        <div>
          <h5 className="mb-20 c-tx-primary f-600">{templateGetData.name} Template</h5>
        </div>
        <div>
          <ul className="list-inforation">
            {data &&
              Object.entries(data)?.map(([key, value]: any[], i: any) => (
                <li key={key}>
                  <div className="label_bl">
                    <p className="c-tx-primary f-700">{key} </p>
                  </div>
                  <div className="cln">:</div>
                  <div className="ct_2">
                    <p className="c-tx-primary">{typeof value !== "object" ? data[key] : data[key]?.label}</p>
                  </div>
                </li>
              ))}
          </ul>
          <div className="btn-wrapper d-flex justify-content-end mt-10">
            <Button className="custom-primary new-btn" onClick={() => dispatch(setTemplateModal("IInstallationTemplateModal"))}>
              Edit
            </Button>
            <Button
              className="custom-primary new-btn ml-10"
              onClick={() => {
                dispatch(setTemplateSubmit(true));
              }}>
              Submit
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default memo(TemplateViewDetailsModal);
