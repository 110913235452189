import { deleteNotificationAPI } from "actions/deleteNotificationAPI";
import LoadingSpinner from "components/loader/LoadingSpinner";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Container, Row } from "reactstrap";
import { setNotificationCount } from "store/notification/action";
import { IRootReducer } from "store/root-reducer";
import { deleteNotificationById, getAllNotificationsList, INotification } from "utils/common/helper/notification";
import { timeSince, toastError, toastSuccess } from "utils/commonFunctions";
import "../../../styles/notification/notification.scss";

const NotificationPage = () => {
  const dispatch = useDispatch();
  const [notificationListData, setNotificationListData] = useState<INotification[]>([]);
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [paginationConfig, setPaginationConfig] = useState({
    limit: 10,
    page: 1
  });

  useEffect(() => {
    setLoading(true);
    getAllNotificationsList(paginationConfig)
      .then((response: any) => {
        setCount(response.data.total);
        setLoading(false);
        let readArry: string[] = [];
        // eslint-disable-next-line array-callback-return
        response?.data?.data?.map((data: any) => {
          if (data?.status === 0) {
            return readArry.push(data?.id);
          }
        });
        dispatch(setNotificationCount(response.data.unRead - readArry.length));
        readArry?.length > 0 && deleteNotification(readArry);
        setNotificationListData((prev) => [...prev, ...response.data.data]);
      })
      .catch((err) => {
        setLoading(false);
      }); // eslint-disable-next-line
  }, [paginationConfig]);

  const IncrementPage = () => {
    setPaginationConfig((prev) => ({ ...prev, page: prev.page + 1 }));
  };

  const deleteNotification = (notificationId: string[]) => {
    deleteNotificationById(notificationId).catch((err) => {
      toastError(err?.message);
    });
  };
  const notificationCount = useSelector((state: IRootReducer) => state.NoticationReducer.count);

  const handleDelete = (notificationId: string[], read: boolean) => {
    let params: any = {};
    if (notificationId.length > 0) {
      params = { notificationId, read };
    } else {
      params = { read };
    }
    deleteNotificationAPI(params).then((res) => {
      if (res.status === 200) {
        if (read) {
          dispatch(setNotificationCount(0));
        } else {
          if (notificationCount !== 0) {
            dispatch(setNotificationCount(notificationCount - 1));
          }
        }
        if (notificationId.length === 1) {
          const arr = notificationListData.filter((data) => data.id !== notificationId[0]);
          setCount(count - 1);
          setNotificationListData(arr);
        } else {
          setNotificationListData([]);
        }
        toastSuccess(res.message);
      }
    });
  };

  return (
    <>
      <div className="user-management-wrapper">
        <Container>
          <div className="list-title">
            <Row className="my-4">
              <Col md="6" sm="12" xs="12">
                <div className="title">
                  <h1 className="c-tx-primary mb-md-0 f-700">Notification</h1>
                </div>
              </Col>
            </Row>
          </div>

          <div className="rounded-10 border-grey w-100 contact-info">
            <div className="d-flex align-items-center justify-content-between flex-wrap info-title border-bottom p-3">
              <div>
                <p className="font-20 fw-bold">Notification edeXa Account</p>
                <p className="font-14 fw-normal color-light-grey">You will be notified with all notifications, without let you to missout any important occurence.</p>
              </div>
              <div className="d-flex">
                {notificationListData.length! > 0 && (
                  <div className="font-14 color-blue fw-bold cursor-pointer" onClick={() => handleDelete([], true)}>
                    Delete All
                  </div>
                )}
              </div>
            </div>
            <div className="notification-container">
              {notificationListData.length === 0 && !loading ? (
                <>
                  <div className="security-box password-box align-notification p-3" style={{ cursor: "default" }}>
                    <div className="d-flex align-items-center info-field">
                      <p className="font-14 fw-normal color-light-grey">No more notification </p>
                    </div>
                  </div>
                </>
              ) : (
                notificationListData.map((notification, i) => {
                  return (
                    <>
                      <div key={i} className={`p-3 notification-message ${notification?.status === 0 && "unread-msg"} ${i === notificationListData.length - 1 ? "border-bottom-radius-10" : ""} `}>
                        <div className="d-flex align-items-center info-field w-75">
                          <div className="notification-info">
                            <div
                              style={{
                                flexBasis: "30px",
                                marginLeft: "4px"
                              }}>
                              <p
                                className="font-12 fw-normal notification-text"
                                style={{
                                  background: notification?.status === 0 ? "#073D83" : "#DADCE0"
                                }}></p>
                            </div>
                            <p className="font-14 fw-normal color-light-grey">{notification?.message}</p>
                          </div>
                        </div>
                        <div className="w-25 d-flex justify-content-end">
                          <p className={"font-14 text-right mr-20 color-light-grey"}>{timeSince(notification.createdAt)}</p>
                          <p className={"font-14 text-right color-blue fw-bold close-icon "} onClick={() => handleDelete([notification.id], false)}>
                            Delete
                          </p>
                        </div>
                      </div>
                      {i !== notificationListData.length - 1 ? <hr className="m-0 border-bottom opacity-1" /> : ""}
                    </>
                  );
                })
              )}
            </div>

            {!loading ? (
              count > 10 ? (
                count !== notificationListData.length ? (
                  <>
                    <hr className="m-0 border-bottom opacity-1" />
                    <p className="text-center my-3 cursor-pointer color-blue f-700" onClick={IncrementPage}>
                      Load More
                    </p>
                  </>
                ) : (
                  ""
                )
              ) : loading ? (
                <LoadingSpinner size={"sm"} />
              ) : (
                ""
              )
            ) : (
              <LoadingSpinner size={"sm"} />
            )}
          </div>
        </Container>
      </div>
    </>
  );
};

export default NotificationPage;
