import { DELETEID, SETVISIBLE, SUBSCRIBEDID } from "./type";

export const setConfirmationModalVisibility = (payload: boolean) => {
  return { type: SETVISIBLE, payload };
};

export const setConfirmationModelId = (id: number) => {
  return { type: DELETEID, id };
};

export const setSubscribedId = (id: number) => {
  return { type: SUBSCRIBEDID, id };
};
