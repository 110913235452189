import { apiEndPoints } from "utils/api-endpoints";
import HTTPService from "utils/httpService";

export const getUnderMaintananceAPI = (): Promise<IgetUnderMaintananceAPI> => {
  return HTTPService.get(apiEndPoints.underMaintanance);
};

interface IgetUnderMaintananceAPI {
  message: string;
  status: number;
  data: {
    underMaintenance: boolean;
  };
}
