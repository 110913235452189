import { apiEndPoints } from "utils/api-endpoints";
import HTTPService from "utils/httpService";

export const listChainCodeAPI = (params: any): Promise<IlistChainCodeAPI> => {
  return HTTPService.get(apiEndPoints.universeAPI.templates.listChainCode, params);
};

export interface IlistChainCodeAPIData {
  description: string;
  name: string;
  id: string;
}

interface IlistChainCodeAPI {
  status: number;
  message: string;
  data: {
    count: number;
    data: any[];
  };
}
