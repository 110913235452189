import { apiEndPoints } from "utils/api-endpoints";
import HTTPService from "utils/httpService";

export const referenceTemplateAPI = (params: IreferenceTemplateAPI): Promise<IreferenceTemplateAPIRes> => {
  return HTTPService.get(apiEndPoints.universeAPI.templates.reference, params);
};

interface IreferenceTemplateAPI {
  module: string;
  search?: string;
}
interface IreferenceTemplateAPIResData {
  id: string;
  name: string;
}
interface IreferenceTemplateAPIRes {
  message: string;
  status: number;
  data: IreferenceTemplateAPIResData[];
}
