import axios from "axios";
import { Buffer } from "buffer";
import CryptoJS, { AES, enc } from "crypto-js";
import moment from "moment";
import toast from "react-hot-toast";
import * as io from "socket.io-client";

import { cookieExpiresInDays, cookieKeys, dateFormat, localStorageKeys, toasterPosition, universeStatus } from "./constants";

export const socketConnection = io.io(process.env.REACT_APP_UNIVERSESOCKETPOINT || "", {
  path: process.env.REACT_APP_SOCKETPATH,
  transports: ["websocket", "polling"]
});

const toastSuccess = (message: string) => {
  toast.remove();
  toast.success(message, {
    position: toasterPosition,
    style: {
      color: "#000",
      minWidth: 150,
      padding: 10,
      fontWeight: 500,
      marginBottom: 60,
      border: "1px solid #073E84"
    },
    iconTheme: { primary: "#073E84 ", secondary: "#fff" }
  });
};

const toastError = (message: string) => {
  toast.remove();
  toast.error(message, {
    position: toasterPosition,
    style: {
      color: "#000",
      fontWeight: 500,
      padding: 10,
      marginBottom: 60,
      border: "1px solid #ff0000"
    }
  });
};

const getUserDetails = () => {
  const isLoggedIN = getDecryptedLocalStorage(localStorageKeys.isLoggedIN) || "";
  if (isLoggedIN && isLoggedIN !== "") {
    return isLoggedIN;
  }
};

const redirectToEdexa = () => {
  window.open(process.env.REACT_APP_edexaDomain, "_blank");
};

// redirect to url and open in new window
const redirectTo = (url: any) => {
  window.open(url, "_blank");
};

export const handleUniverseStatus = (status?: number) => {
  if (status === universeStatus.APPROVED) {
    return "Approved";
  } else if (status === universeStatus.INPROGREESS) {
    return "In-Progress";
  } else if (status === universeStatus.REJECTED) {
    return "Rejected";
  } else if (status === universeStatus.PENDING) {
    return "Pending";
  }
};

// set raw cookie of query parameter for localstorage
export const setEncryptedCookieForLocalhost = (key: string, data: any) => {
  if (data && key) {
    const keyName = cookieKeys.cookieInitial + "-" + key.trim();
    const date = new Date();
    const expiryTime = new Date(date.setTime(date.getTime() + cookieExpiresInDays * 24 * 60 * 60 * 1000)).toUTCString();
    document.cookie = `${keyName}=${data};expires=${expiryTime};domain=${window.location.hostname.replace("accounts", "")};secure;path=/;`;
  }
};

// convert an image to base64
export const getBase64 = (file: any) => {
  if (file) {
    return new Promise((resolve, reject) => {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        return resolve(reader.result);
      };
      reader.onerror = function (error) {
        reject(false);
      };
    });
  } else {
    return new Promise((resolve, reject) => resolve(""));
  }
};
// base 64 function ends here

// @ country flags api starts here

// this api accepts country codes in small letters like - in and if in.svg then it will return an image of indian flag
export const countryFlag = (countryCode: string) => {
  return `https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/4x3/${countryCode.toLocaleLowerCase()}.svg`;
};

// country flag 2
// this api receives country codes in capital letters
export const countryFlag2 = (countryCode: string) => {
  return `https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/${countryCode.toLocaleUpperCase()}.svg`;
};

// @ country flags ends here

// call this function like this - validateFileSize(event.target.files[0], 1024)
export const validateFileSize = (file: any, size: number) => {
  // file will be the file boject
  if (file.size > size) {
    return false;
  } else {
    return true;
  }
};

export const setEncryptedLocalStorage = (key: string, data: any) => {
  if (data && key) {
    const encryptedString = encryptData(data);
    const keyName = cookieKeys.cookieInitial + "-" + key.trim();
    localStorage.setItem(keyName, encryptedString.toString());
  }
};

export const getDecryptedLocalStorage = (key: string) => {
  if (key) {
    const keyName = cookieKeys.cookieInitial + "-" + key.trim();
    const localStorageData = localStorage.getItem(keyName);
    if (localStorageData) {
      return decryptData(localStorageData);
    } else {
      const cookieUser = getEncryptedCookie(cookieKeys.cookieUser);
      if (!cookieUser) {
        removeDecryptedCookie(cookieKeys.cookieUser);
      }
    }
  }
};

export const getEncryptedCookie = (key: string) => {
  if (key) {
    const keyName = cookieKeys.cookieInitial + "-" + key.trim();
    const cookieData = getCookie(keyName);
    if (cookieData) {
      return decryptData(cookieData);
    }
  }
};

export const handleErrors = () => {
  // prevent production and staging console and warnings
  if (process.env.REACT_APP_ENV === "PRODUCTION" || process.env.REACT_APP_ENV === "STAGING") {
    console.log = () => {};
    console.error = () => {};
    console.debug = () => {};
    console.warn = () => {};
  }
};

export const removeDecryptedCookie = (key: string) => {
  if (key) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const keyName = cookieKeys.cookieInitial + "-" + key.trim();
    // put the name of the project of which you want to remove the cooki in current case it is "universe"
    document.cookie = `${keyName}=;expires=${new Date(0).toUTCString()};domain=${window.location.hostname.replace("universe", "")};path=/;`;
    // document.cookie = `${keyName}=;expires=${new Date(0).toUTCString()};domain=localhost;path=/;`;
  }
};

export const encryptData = (data: any) => {
  return AES.encrypt(JSON.stringify(data), cookieKeys.cryptoSecretKey);
};

export const decryptData = (data: any) => {
  const bytes = AES.decrypt(data.toString(), cookieKeys.cryptoSecretKey);
  if (bytes.toString()) {
    return JSON.parse(bytes.toString(enc.Utf8));
  }
};
export const decryptAPisData = (data: any) => {
  const bytes = AES.decrypt(data.toString(), cookieKeys.cryptoSecretKey);
  if (bytes.toString()) {
    return JSON.parse(bytes.toString(enc.Utf8));
  }
  return "";
};

const getCookie = (cookieName: any) => {
  let name = cookieName + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  if (decodedCookie) {
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i].trim();
      while (c.charAt(0) === "") {
        c = c.substring(1);
      }
      if (+c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
  }
  return "";
};

const handleLogout = () => {
  localStorage.clear();

  removeDecryptedCookie(cookieKeys.cookieUser);
  if (process.env.REACT_APP_ENV === "DEVELOPMENT") {
    window.location.href = process.env.REACT_APP_LOCALHOST_ENCRYPT_KEY || "";
    return "";
  }
  window.location.href = process.env.REACT_APP_authWithEdexaLogin || "";

  // navigate("/login");
};

/**
 * @capitalize_first_char
 */
const capitalizeFirstLetter = (string: string) => {
  return string?.toString().charAt(0).toUpperCase() + string.slice(1);
};

/**
 * @number_formatter - ex 1.2k 5M
 * @param text
 * @returns
 */

const numberFormatter = (num: number, digits: number) => {
  const lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "G" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" }
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return num >= item.value;
    });
  return item ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : "0";
};

/**
 * @format_text
 * @param {string} - first-name
 * @return {string} - First Name
 */

const getFormattedText = (text: string) => {
  if (text === "other") {
    return "Document";
  } else {
    try {
      return text
        .toString()
        .split("_")
        .map((item) => capitalizeFirstLetter(item))
        .join(" ");
    } catch (error) {
      return capitalizeFirstLetter(text);
    }
  }
};

const getAPIResponseFromUrl = (reqResData: any) => {
  let headers: any = {};
  reqResData?.headers?.forEach((e: any) => {
    headers[e?.key] = e?.value;
  });
  if (reqResData?.method === "GET") {
    return axios.get(reqResData?.url, {
      headers: headers,
      params: reqResData.data
    });
  } else if (reqResData.method === "POST") {
    return axios.post(reqResData.url, reqResData.data, {
      headers: headers
    });
  } else if (reqResData.method === "PUT") {
    return axios.put(reqResData.url, reqResData.data, {
      headers: headers
    });
  }
};

// formated file name in input type file
const returnFormattedBigFileNames = (fileName: string) => {
  if (!fileName) return "";
  return `${fileName?.slice(0, 25)}.${fileName?.slice(((fileName?.lastIndexOf(".") - 1) >>> 0) + 2)}`;
};

function isValidDate(date: string) {
  // @ts-ignore
  return date && Object.prototype.toString.call(date) === "[object Date]";
}

// return prettier date
// the format is used in dateFormat variable
const getFormattedDate = (utcDate: string) => {
  if (isValidDate(utcDate) || utcDate === "") {
    return "";
  }
  return moment(utcDate).format(dateFormat);
};

/**
 * This function will scroll to a given element by its Id and It also accept the offset.
 * what is offset - https://developer.mozilla.org/en-US/docs/Web/CSS/offset
 *
 * @param headerOffset {number} - it is the value which will be used as offset from top while scrolling
 * @param elementId {string} - this id of the element whom we want to scroll into view.
 */
export const scrollToTargetAdjusted = (headerOffset: number, elementId: string) => {
  var element = document.getElementById(elementId);
  var elementPosition: any = element?.getBoundingClientRect().top;
  // @ts-ignore
  var offsetPosition = elementPosition + window.pageYOffset - headerOffset;

  window.scrollTo({
    top: offsetPosition,
    behavior: "smooth"
  });
};

// get kyc status
/**
 * Accepts an status an as per status returns a particular css classname and the particular label for that status.
 
 * @param statusCode {number}
 * @returns {result} - {label: "", class: ""}
 */
export const getKyCStatus = (statusCode: number) => {
  let result = {
    label: "",
    class: ""
  }; // eslint-disable-next-line
  switch (statusCode) {
    case 0:
      result = {
        label: "Pending",
        class: "pending"
      };
      break;
    case 1:
      result = {
        label: "Approved",
        class: "active"
      };
      break;
    case 2:
      result = {
        label: "Rejected",
        class: "deactive"
      };
      break;

    default:
      result = {
        label: "Not Started",
        class: "pending"
      };
      break;
  }
  return result;
};

export const trimExtraSpacesFromString = (string: string) => {
  return string.trim() || string;
};

export const killWhiteSpace = (string: string) => {
  return string.replace(/\s/g, "") || string;
};

export const reduceWhiteSpace = (string: string) => {
  if (!string.trim().length) {
    return "";
  }
  return string.replace(/\s+/g, " ") || string;
};

export {
  capitalizeFirstLetter,
  getAPIResponseFromUrl,
  getCookie,
  getFormattedDate,
  getFormattedText,
  getUserDetails,
  handleLogout,
  numberFormatter,
  redirectTo,
  redirectToEdexa,
  returnFormattedBigFileNames,
  toastError,
  toastSuccess
};

export const handleJson = (data: any) => {
  let d: any = {};
  data.map((item: any) => {
    return (d[`${item.key}`] = item.value);
  });
  return d;
};

/**
 * check if a given element is in the viewport or not
 * @param el
 * @returns {boolean}
 *
 * @isTestWrittenForThisFunction `false`
 */
export function elementInViewport(el: HTMLElement | null | HTMLDivElement, callBack: IntersectionObserverCallback, options: IntersectionObserverInit) {
  if (!el) {
    return false;
  }

  let observer = new IntersectionObserver(callBack, options);
  observer.unobserve(el);
  observer.observe(el);
  return false;
}

export const handleEmail = (email: string) => {
  return email.split("@")[0];
};

export function timeSince(date: any) {
  return moment(date).fromNow();
}

export const handleTrim = (eventName: string, eventValue: string, setValue: any) => {
  if (eventValue.trim() === "") {
    setValue(eventName, "");
  } else {
    setValue(eventName, eventValue);
  }
};

export function nFormatter(num: number, digits: number) {
  var si = [
      { value: 1e18, symbol: "E" },
      { value: 1e15, symbol: "P" },
      { value: 1e12, symbol: "T" },
      { value: 1e9, symbol: "G" },
      { value: 1e6, symbol: "M" },
      { value: 1e3, symbol: "k" }
    ],
    i;
  for (i = 0; i < si.length; i++) {
    if (num >= si[i].value) {
      return (num / si[i].value).toFixed(digits).replace(/\.?0+$/, "") + si[i].symbol;
    }
  }
  return num;
}
export const handleApiColor = (method: string, span?: boolean) => {
  if (span) {
    return <span className={`${String(method).toLocaleLowerCase()}-api-color mb-0 f-700`}>{method}</span>;
  } else {
    return <p className={`${String(method).toLocaleLowerCase()}-api-color mb-0 f-700`}>{method}</p>;
  }
};

export const handleApiToaster = (status: number, message: string) => {
  if (status >= 400) {
    toastError(message);
  } else {
    toastSuccess(message);
  }
};

// this function is open to remix url with code
export function remixURL(code: string, upgradeable = false): URL {
  const remix = new URL("https://remix.ethereum.org");
  remix.searchParams.set("code", btoa(code).replace(/=*$/, ""));
  if (upgradeable) {
    remix.searchParams.set("deployProxy", upgradeable.toString());
  }
  return remix;
}

export const decryptFileBuffer = (encryptedBuffer: string, publicKey: string, accessKey: string) => {
  const a = CryptoJS.AES.decrypt(encryptedBuffer, publicKey, {
    iv: accessKey as any,
    mode: CryptoJS.mode.CTR,
    padding: CryptoJS.pad.NoPadding
  });
  return Buffer.from(a.toString(CryptoJS.enc.Base64), "base64");
};

export const decodeBase64Key = (key: any) => {
  return atob(key); // Decoded string
};

/**
 * This function will take cookie name as input and returns its decrypted value
 *
 * @param {string} key the name of the cookie
 * @returns {string} decrypted cookie string
 *
 * @isTestWrittenForThisFunction `true`
 */
export const getDecryyptedCookie = (key: string) => {
  if (key) {
    const keyName = cookieKeys.cookieInitial + "-" + key.trim();
    const cookieData = getCookie(keyName);
    if (cookieData) {
      return decryptAPisData(cookieData);
    }
  }
};
