import { TitleComponent } from 'components/titleComponent'
import React, { useState } from 'react'
import 'react-circular-progressbar/dist/styles.css'
import { Col, Row } from 'reactstrap'
// import "../../../styles/pages/dashboard_layout/dashboard.scss";
import '../../../styles/dashboard_layout/dashboard.scss'
const ConnectToChain = React.lazy(
  () => import('components/dashboard/ConnectToChain')
)
const Documentation = React.lazy(
  () => import('components/dashboard/Documentation')
)

const ActivityLogComponent = React.lazy(
  () => import('components/dashboard/ActivityLogComponent')
)
const ChainCodeComponent = React.lazy(
  () => import('components/dashboard/ChainCodeComponent')
)
const ChannelComponent = React.lazy(
  () => import('components/dashboard/ChannelComponent')
)
const MyAPIComponent = React.lazy(
  () => import('components/dashboard/MyAPIComponent')
)
const MyBlockChainDashboardComponent = React.lazy(
  () => import('components/dashboard/MyBlockChainDashboardComponent')
)
const SubscibeDetailsComponent = React.lazy(
  () => import('components/dashboard/SubscibeDetailsComponent')
)
const TransactionDetails = React.lazy(
  () => import('components/dashboard/TransactionDetails')
)
const UserComponent = React.lazy(
  () => import('components/dashboard/UserComponent')
)

const RequestHistory = React.lazy(
  () => import('components/dashboard/RequestHistory')
)
/* const ConnectToChain = React.lazy(() => import("component/Dashboard/ConnectToChain"));
const Documentation = React.lazy(() => import("component/Dashboard/Documentation"));
const TitleComponent = React.lazy(() => import("Pages/common/titleComponent"));
const ActivityLogComponent = React.lazy(() => import("./components/activityLogComponent"));
const ChainCodeComponent = React.lazy(() => import("./components/chainCodeComponent"));
const ChannelComponent = React.lazy(() => import("./components/channelComponent"));
const MyAPIComponent = React.lazy(() => import("./components/myAPIComponent"));
const MyBlockChainDashboardComponent = React.lazy(() => import("./components/myBlockChainDashboardComponent"));
const SubscibeDetailsComponent = React.lazy(() => import("./components/subscibeDetailsComponent"));
const TransactionDetails = React.lazy(() => import("./components/transactionDetails"));
const UserComponent = React.lazy(() => import("./components/userComponent"));
const RequestHistory = React.lazy(() => import("./requestHistory/RequestHistory")); */

const Dashboard = () => {
  const [channelData, setChannelData] = useState<any>([])
  return (
    <>
      <TitleComponent title="Dashboard" />
      {/* <Row>
          <SubscribeComponent />
        </Row> */}
      <div className="backdrop-block">
        <Row>
          <MyBlockChainDashboardComponent />
        </Row>
      </div>
      <Row>
        <Col md="6">
          {/*  <MyBlockChainComponent />   */}
          <MyAPIComponent />
        </Col>
        <Col md="6">
          <TransactionDetails getChannelData={channelData} />
        </Col>
      </Row>
      <Row>
        <Col md="6" lg="6" xl="6" xxl="4">
          <ChannelComponent setData={setChannelData} />
        </Col>
        <Col md="6" lg="6" xl="6" xxl="4">
          <ChainCodeComponent />
        </Col>
        <Col md="6" lg="6" xl="6" xxl="4">
          <SubscibeDetailsComponent />
        </Col>
        <Col md="6" lg="6" xl="6" xxl="4">
          <UserComponent />
        </Col>
        <Col md="6" lg="6" xl="6" xxl="4">
          <ConnectToChain />
        </Col>
        <Col md="6" lg="6" xl="6" xxl="4">
          <Documentation />
        </Col>
        <Col md="12" lg="12" xl="6" xxl="6">
          <ActivityLogComponent />
        </Col>
        <Col md="12" lg="12" xl="6" xxl="6">
          <RequestHistory />
        </Col>
      </Row>
    </>
  )
}

export default Dashboard
