import { apiEndPoints } from "utils/api-endpoints";
import HTTPService from "utils/httpService";

export const getAllAPIData = (params: { page: number; limit: number; }): Promise<IGetAllAPIData> => {
  return HTTPService.get(apiEndPoints.allAPIData, params);
};

interface IApplication {
  ErrorDescription: string;
  ErrorTypes: string[];
  HTTPstatus: [{ status_code: number; message: string }];
  appFeatures: string[];
  appId: string;
  app_url: string;
  createdAt: string;
  description: string;
  desktop_url: string;
  document_url: string;
  extension_url: string;
  features: string[];
  id: number;
  ios_url: string;
  isAvailable: number;
  liveUrlDomain: string;
  logo: string;
  logoIcon: string;
  name: string;
  order: number;
  redirect_url: string;
  shortDescription: string;
  status: string;
  subtitle: string;
  suggestionApplication: string;
  type: string;
}
interface IData {
  newApplications: [IApplication];
  myApplications: [IApplication];
  SuggestedApplications: [IApplication];
  totalApplications: [IApplication];
}
export interface IGetAllAPIData {
  data: [IData];
  message: string;
  status: number;
}
