import { faRedoAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ConnectToChain from "components/dashboard/ConnectToChain";
import Documentation from "components/dashboard/Documentation";
import SettingListComponent from "components/setting/SettingListComponent";

// import ConnectToChain from "component/Dashboard/ConnectToChain";
// import Documentation from "component/Dashboard/Documentation";

import { Col, Row } from "reactstrap";
import "../../../styles/PeerNode/peerNode.scss";
// import SettingListComponent from "./setting/SettingListComponent";

const Rotate = <FontAwesomeIcon icon={faRedoAlt} />;

const Setting = () => {
  return (
    <div className="peer-node-overview-wrapper">
      <div className="page-title">
        <Row>
          <Col md="6">
            <h5 className="c-tx-primary f-700 mb-20">Settings</h5>
          </Col>
          <Col md="6" className="text-end">
            <h6>{Rotate}</h6>
          </Col>
        </Row>
      </div>
      <Row className="mb-20">
        <SettingListComponent />
        <Col md="12" lg="12" xl="4" xxl="4">
          <Row>
            <Col md="6" lg="6" xl="12" xxl="12">
              <ConnectToChain />
            </Col>
            <Col md="6" lg="6" xl="12" xxl="12">
              <Documentation />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default Setting;
