/* eslint-disable react-hooks/rules-of-hooks */
import { InetworkStatusInfoAPIData, networkStatusInfoAPI } from "actions/networkStatusInfoAPI";
import Steps from "pages/main/edexaUniverse/Steps";
import { FC, useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { Button, Col, Row } from "reactstrap";
import { setLoading } from "store/loader/action";
import "../../styles/allnetwork/allnetwork.scss";
import "../../styles/dashboard_layout/dashboard.scss";

interface IHomeRequest {
  status?: string;
  toggleEdexaUniverseModal?: () => void;
}

const HomeRequest: FC<IHomeRequest> = ({ status, toggleEdexaUniverseModal }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [data, setData] = useState<InetworkStatusInfoAPIData>();

  const networkStatusInfoAction = useCallback(() => {
    dispatch(setLoading(true));
    networkStatusInfoAPI().then((res) => {
      if (res.status === 200) {
        setData(res.data);
      }
      dispatch(setLoading(false));
    });
  }, [dispatch]);

  useEffect(() => {
    networkStatusInfoAction();
    // eslint-disable-next-line
  }, [])

  return (
    <>
      {/* // private blockchain popup */}
      <Row>
        <Col md="12">
          <div className="allnetwork-wrapper">
            <div className="title">
              <h5 className="c-tx-primary f-600 mb-10">All Networks</h5>
            </div>
            <ul className="listofnetwork ">
              <li>
                <div className="title-drp ">
                  <div className="block-1">
                    <p className="c-tx-primary f-700 first-title">Requested Information</p>
                  </div>
                </div>
              </li>
              <li className="detail-li">
                <div className="bl_cmn">
                  <p className="c-tx-primary  f-700 first-title">{data?.envName} </p>
                  <p className="c-tx-ninth mb-0  small">Environments</p>
                </div>
                <div className="bl_cmn">
                  <p className="c-tx-primary  f-700 first-title">1</p>
                  <p className="c-tx-ninth mb-0  small">Memberships</p>
                </div>{" "}
                <div className="bl_cmn">
                  <p className="c-tx-primary  f-700 first-title">1</p>
                  <p className="c-tx-ninth mb-0  small">Enabled Regions</p>
                </div>{" "}
              </li>
              <li className="detail-li">
                <div className="bl_cmn">
                  <p className="c-tx-primary  f-700 first-title">{data?.networkName} </p>
                  <p className="c-tx-ninth mb-0  small">Channel Name</p>
                </div>
                <div className="bl_cmn">
                  <p className="c-tx-primary  f-700 first-title">{data?.networkDescription} </p>
                  <p className="c-tx-ninth mb-0  small">Channel Mission Statement</p>
                </div>
                <div className="bl_cmn">
                  <p className="c-tx-primary  f-700 first-title">{data?.provider} </p>
                  <p className="c-tx-ninth mb-0  small">Provider</p>
                </div>
              </li>
              <li>
                <div className="d-flex mt-20 link-wrapper justify-content-between align-items-center">
                  <Link
                    to="#"
                    className="custom-link ps-0"
                    style={{
                      color: `${status === "REJECTED" ? "#E04F5F" : ""} `
                    }}>
                    Your Deploy Request is {status}
                  </Link>
                  {status === "REJECTED" && (
                    <Button
                      className="custom-primary key"
                      onClick={() => {
                        if (status === "REJECTED" && toggleEdexaUniverseModal) {
                          toggleEdexaUniverseModal();
                        }
                      }}>
                      Resend Request
                    </Button>
                  )}
                </div>
                <Steps />
              </li>
            </ul>
          </div>
        </Col>
      </Row>
      {/* // other popup */}
      {/* <Row>
          <Col md="12">
            <div className="allnetwork-wrapper">
              <div className="title">
                <h5 className="c-tx-primary f-600 mb-10">
                  All Networks
                </h5>
              </div>
              <ul className="listofnetwork ">
                <li>
                  <div className="title-drp ">
                    <div className="block-1">
                      <p className="c-tx-primary f-700 first-title">
                        Requested Information
                      </p>
                    </div>
                  </div>
                </li>
                <li className="detail-li">
                  <div className="bl_cmn">
                    <p className="c-tx-primary  f-700 first-title">
                      {data?.envName}{' '}
                    </p>
                    <p className="c-tx-ninth mb-0  small">
                      Environments
                    </p>
                  </div>
                  <div className="bl_cmn">
                    <p className="c-tx-primary  f-700 first-title">
                      1
                    </p>
                    <p className="c-tx-ninth mb-0  small">
                      Memberships
                    </p>
                  </div>{' '}
                  <div className="bl_cmn">
                    <p className="c-tx-primary  f-700 first-title">
                      {data?.networkDomainType?.name} :{' '}
                      {data?.networkRegionType?.name}
                    </p>
                    <p className="c-tx-ninth mb-0  small">
                      Home Region
                    </p>
                  </div>{' '}
                  <div className="bl_cmn">
                    <p className="c-tx-primary  f-700 first-title">
                      1
                    </p>
                    <p className="c-tx-ninth mb-0  small">
                      Enabled Regions
                    </p>
                  </div>{' '}
                </li>
                <li>
                  <div className="title-drp ">
                    <div className="block-1">
                      <p className="c-tx-primary f-700 first-title">
                        Environments
                      </p>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="d-flex mt-20 link-wrapper justify-content-between align-items-center">
                    <Link
                      to={'#'}
                      className="custom-link ps-0"
                      style={{
                        color: `${
                          status === 'REJECTED' ? '#E04F5F' : ''
                        } `
                      }}
                    >
                      Your Request is {status}
                    </Link>
                    {status === 'REJECTED' && (
                      <Button
                        className="custom-primary key"
                        onClick={() => {
                          if (
                            status === 'REJECTED' &&
                            toggleEdexaUniverseModal
                          ) {
                            toggleEdexaUniverseModal()
                          }
                        }}
                      >
                        Resend Request
                      </Button>
                    )}
                  </div>
                </li>
              </ul>
            </div>
          </Col>
        </Row> */}
    </>
  );
};

export default HomeRequest;
