import { getDecryptedLocalStorage, getEncryptedCookie, setEncryptedCookieForLocalhost, setEncryptedLocalStorage } from "utils/commonFunctions";
import { cookieKeys, localStorageKeys } from "utils/constants";

/**
 * @param {callProfileData: true | false}
 * @returns {isCookieSetOnLocal: true | false}
 */
const useLocalhostCookieSetupOnRedirect = () => {
  // if local development then use this approach to set cookies
  // we do not have to go to application tab and change cookie domain manually
  let isCookieSetOnLocal = false;

  if (process.env.REACT_APP_ENV === "DEVELOPMENT") {
    const urlParams = new URLSearchParams(window.location.search);
    const userData: string = urlParams.get("use") || "";
    // remove query string
    window.history.pushState({}, "", window.location.origin);
    if (userData) {
      setEncryptedCookieForLocalhost(cookieKeys.cookieUser, decodeURIComponent(userData));

      const cookieUser = getEncryptedCookie(cookieKeys.cookieUser);
      const token = getDecryptedLocalStorage(localStorageKeys.userToken);
      if (token !== cookieUser.token) {
        setEncryptedLocalStorage(localStorageKeys.userToken, cookieUser.token);
        isCookieSetOnLocal = true;
      }
    }
  }
  // if cookie is set successfully then return true
  return { isCookieSetOnLocal };
};

export default useLocalhostCookieSetupOnRedirect;

/**
 * @how_to_use
 * if you pass true then you do not have to call the getProfile data function seperately
 * useLocalhostCookieSetupOnRedirect();
 */
