import { faEye, faEyeSlash } from "@fortawesome/free-regular-svg-icons";
import { faCheck, faDownload, faPlay, faStar, faStarHalfAlt, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { webHookAuthenticateApi } from "actions/webHook/webHookAuthenticateAPI";
import IconComponent from "components/iconComponent/IconComponent";
import { ConfirmationModal } from "components/models";
import CommonConfirmationModal from "components/models/CommonConfirmationModal";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import { Breadcrumb, BreadcrumbItem, Button, Col, Container, FormGroup, Input, InputGroup, InputGroupText, Label, Modal, ModalBody, Row } from "reactstrap";
import { setConfirmationModalVisibility } from "store/confirmationModule/action";
import { setLoading } from "store/loader/action";
import LeftArrow from "utils/common/helper/LeftArrow";
import { getAPIDescription } from "utils/common/helper/apiDescription";
import { subscribeFromAPI, unsubscribeFromAPI } from "utils/common/helper/unSubscribe";
import { getEncryptedCookie, numberFormatter, scrollToTargetAdjusted, setEncryptedLocalStorage, toastError, toastSuccess } from "utils/commonFunctions";
import { applicationRating, cookieKeys, localStorageKeys } from "utils/constants";
import "../../../styles/apiDetails/apiDetails.scss";
import "../../../styles/apiDetails/apiExplore.scss";
import ApiDetailComponent from "./ApiDetails";

const Filledstar = <FontAwesomeIcon icon={faStar} />;
const Play = <FontAwesomeIcon icon={faPlay} />;
const Check = <FontAwesomeIcon icon={faCheck} />;
const halfStar = <FontAwesomeIcon icon={faStarHalfAlt} />;

const APIExplorer = () => {
  const params: any = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [showPassword, setShowPassword] = useState(false);

  const togglePassword = () => {
    setShowPassword(!showPassword);
  };

  const { isScrolling } = useSelector((state: any) => state.scrollingReducer);

  const [apiDetails, setApiDetails] = useState<any>();
  const [activeAPICall, setActiveAPICall] = useState<any>();
  const [currentActiveHttpRequest, setCurrentActiveHttpRequest] = useState([]);
  const [valueOfSandBox, setValueOfSandBox] = useState();
  const [changePopupText, setChangePopupText] = useState("");
  const [featureModelVisibility, setFeatureModelVisibility] = useState(false);
  const [developerModal, setDeveloperModal] = useState<boolean>(false);
  const [showSecretKey, setShowSecretKey] = useState<boolean>(false);
  const [isSandboxOrLive, setIsSandboxOrLive] = useState(true);
  const [version, setVersion] = useState("");
  const [open, setOpen] = useState(false);
  const [reqResData, setReqResData] = useState<any>({
    req: {},
    res: {}
  });

  const live = "Live";
  const sandbox = "Sandbox";
  const userToken = getEncryptedCookie(cookieKeys.cookieUser);

  /**
   * @postman_button
   * @param {*} - {window, document, "_pm", "PostmanRunObject", "https://run.pstmn.io/button.js"}
   */
  // @ts-ignore
  function addPostmanButtonScript(p, o, s, t, m, a, n) {
    // @ts-ignore
    !p[s] &&
      (p[s] = function () {
        (p[t] || (p[t] = [])).push(arguments);
      });
    // @ts-ignore
    !o.getElementById(s + t) &&
      o.getElementsByTagName("head")[0].appendChild(
        // @ts-ignore
        ((n = o.createElement("script")),
        // @ts-ignore
        (n.id = s + t),
        (n.async = 1),
        (n.src = m),
        n)
      );
  }

  const toggleFeatureModel = () => setFeatureModelVisibility(!featureModelVisibility);

  const handleSandbox = (e: any) => {
    const liveIsChecked = e.target.checked;
    liveIsChecked ? setChangePopupText(live) : setChangePopupText(sandbox);
    setValueOfSandBox(liveIsChecked);
    toggleFeatureModel();
  };

  useEffect(() => {
    dispatch(setLoading(true));
    getAPIDescription(params.id).then((response: any) => {
      dispatch(setLoading(false));
      setTimeout(() => {
        // @ts-ignore
        addPostmanButtonScript(window, document, "_pm", "PostmanRunObject", "https://run.pstmn.io/button.js");
      }, 500);
      response?.data?.forEach((el: any) => {
        const requestParams: any = {};
        const respParams: any = {
          status: 200,
          message: "test_message",
          data: ["Api response data will be shown here"],
          error: []
        };
        el["requestParams"] = requestParams;
        el["respParams"] = respParams;
        el["isExecute"] = false;
        if (el.body.requireParams.raw.length) {
          el.body.requireParams.raw.forEach((elChild: any, i: any) => {
            elChild.errorReqire = false;
            el.requestParams[elChild.key] = "[Your value goes here]";
          });
        } else {
          el.requestParams = requestParams;
        }
        el?.header.forEach((elChild: any) => {
          if (elChild?.key === "secret-key") {
            elChild.value = el?.secretKey;
          } else if (elChild?.key === "client-id") {
            elChild.value = response?.clientId;
          } else {
            // eslint-disable-next-line
            elChild.value = elChild?.value;
          }
        });
      });

      setApiDetails({
        data: response,
        subscribed: response?.inUse
      });
      setActiveAPICall(response?.data?.[0]);
      setCurrentActiveHttpRequest(response?.data?.map((item: Object, index: number) => index === 0));
    });
  }, [dispatch, params.id, apiDetails?.subscribed]);

  useEffect(() => {
    if (location?.state) {
      setDeveloperModal(true);
    } else {
      setDeveloperModal(false);
    }
  }, []);

  const toggleKeyInfoModal = () => {
    setDeveloperModal(false);
    navigate(location.pathname, {});
  };

  const handleCopy = (key: string, text: string) => {
    const keysToCopy = `${key}`;
    navigator.clipboard
      .writeText(keysToCopy)
      .then(() => {
        toastSuccess(text);
      })
      .catch((error) => {
        console.error("Failed to copy text:", error);
      });
  };

  const handleDownload = (clientId: string, secretKey: string) => {
    const keysToCopy = `client_key,secret_key\n${clientId},${secretKey}`;

    // Create the CSV content
    const csvContent = String(keysToCopy);
    // Create a Blob from the CSV content
    const blob = new Blob([csvContent], { type: "text/csv" });
    // Create a URL for the Blob
    const blobUrl = URL.createObjectURL(blob);
    // Create a link element
    const link = document.createElement("a");
    link.href = blobUrl;
    link.download = "edexa-api-keys.csv"; // Set the desired file name
    // Trigger the download
    link.click();
    // Clean up resources
    URL.revokeObjectURL(blobUrl);
  };

  /**
   * @onSubscribeClick
   */
  const apiSubscriptionToggle = () => {
    function handleSubscribtion(response: any, subscribed: boolean) {
      dispatch(setConfirmationModalVisibility(false));
      setApiDetails({ ...apiDetails, subscribed });
      toastSuccess(response.message);
      dispatch(setLoading(false));
    }

    let subscribed = apiDetails?.subscribed;
    dispatch(setLoading(true));
    if (subscribed) {
      // unsubscribe
      unsubscribeFromAPI({
        applicationId: params?.id,
        subscribe: 0
      }).then((response: any) => {
        handleSubscribtion(response, false);
      });
    } else {
      // subscribe
      subscribeFromAPI({
        applicationId: params?.id,
        subscribe: 1
      }).then((response: any) => {
        handleSubscribtion(response, true);
      });
    }
  };

  // handle user copy btn click
  const [docUrlCopied, setDocUrlCopied] = useState(false);
  const handleCopyLink = (text: string, field?: string) => {
    if (field === "docUrl" && text) {
      window.navigator.clipboard.writeText(text).then(() => {
        setDocUrlCopied(true);
        toastSuccess("Document url copied to clipboard");
        setTimeout(() => {
          setDocUrlCopied(false);
        }, 2000);
      });
      return null;
    }
    if (text && field) {
      window.navigator.clipboard.writeText(text).then(() => {
        field === "clientId" ? toastSuccess("Client Id copied to clipboard") : toastSuccess("Secret key copied to clipboard");
      });
    }
  };
  // set current active left sidebar tab
  // initial index will always be zero
  const setActiveHttpRequest = (Iindex: number) => {
    const newHttpTabs = apiDetails?.data?.data?.map((item: Object, index: number) => index === Iindex);
    setCurrentActiveHttpRequest(newHttpTabs);
  };

  /**
   * @http_api_execution
   */
  // execute current api and get the response
  // show the response on dom
  // making request and response object

  // set request on active tab change or sandbox live mode change
  useEffect(() => {
    // @headers - making request headers
    let headersvalues = {};
    activeAPICall?.header?.forEach((item: any, indexVal: any) => {
      if (item.key === "client-id") {
        headersvalues = {
          ...headersvalues,
          [item?.key]: apiDetails?.data?.clientId
        };
      } else if (item.key === "secret-key") {
        headersvalues = {
          ...headersvalues,
          [item?.key]: apiDetails?.data?.secretKey
        };
      } else if (item.key === "Authorization") {
        headersvalues = {
          ...headersvalues,
          [item?.key]: `Bearer ${userToken?.token}`
        };
      } else {
        headersvalues = { ...headersvalues, [item?.key]: item?.value };
      }
    });
    setReqResData({
      ...reqResData,
      req: {
        req: activeAPICall?.request_type,
        param: { name: "" },
        headers: { ...headersvalues },
        url: !isSandboxOrLive ? activeAPICall?.url : activeAPICall?.liveUrl
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeAPICall, isSandboxOrLive, apiDetails]);

  const goBack = () => {
    navigate(-1);
  };

  const [clientKey, setClientKey] = useState("");
  const [secretKey, setSecretKey] = useState("");

  const handleAuthenticate = () => {
    dispatch(setLoading(true));
    let data = {
      "client-id": clientKey,
      "secret-key": secretKey
    };
    webHookAuthenticateApi(data)
      .then((res: any) => {
        if (res.status === 200) {
          setEncryptedLocalStorage(localStorageKeys.authenticateToken, res?.data);
          navigate("/webhooks");
        }
        dispatch(setLoading(false));
      })
      .catch((err) => {
        dispatch(setLoading(false));
        toastError(err.data.message);
      });
  };

  const handleModalOpen = () => {
    setOpen(!open);
  };

  return (
    <>
      <div className="api-detail-wrapper explore-details">
        {/**
         * @explorer_breadcrumb
         * @explorer_headers
         */}
        <div className={`custom-stick ${isScrolling ? "" : "top-0"}`}>
          <div className="breadcum-block">
            <Container>
              <div className="breadcum-wrapper">
                <span className="cursor-pointer backarrow d-inline-flex d-md-none" onClick={() => goBack()}>
                  <LeftArrow />
                </span>
                <Breadcrumb className="mb-0">
                  <BreadcrumbItem>
                    <Link to="/apis">API's</Link>
                  </BreadcrumbItem>
                  <BreadcrumbItem active>API Explorer</BreadcrumbItem>
                </Breadcrumb>
              </div>
            </Container>
          </div>
          {/**
           * @explorer_headers
           * @buttons
           */}
          <div className="api-details-header">
            <Container>
              <Row className="align-items-center">
                <Col md="12" lg="6" className="position-relative">
                  <span className=" cursor-pointer backarrow d-none d-md-flex" onClick={() => goBack()}>
                    <LeftArrow />
                  </span>
                  <h1 className="h2 c-tx-third mb-0">
                    API Explorer of
                    <span className="f-600"> {apiDetails?.data?.name} </span>{" "}
                  </h1>
                </Col>
                <Col md="12" lg="6" className="justify-content-lg-end">
                  <div className="api-detail-btn-list btn-custom-group">
                    <Button className="custom-primary-outline" onClick={() => navigate(`/apis/apidetails/${params?.id}`)}>
                      Api Details
                    </Button>
                    {String(apiDetails?.data?.name).includes("bStamp") && apiDetails?.data?.inUse
                      ? version === "Version 2" && (
                          <Button className="custom-primary-outline" onClick={handleModalOpen}>
                            Webhooks
                          </Button>
                        )
                      : null}
                    {/* <Button
                      className={`custom-primary${apiDetails?.subscribed === 1 ? "-outline" : ""}`}
                      onClick={() => {
                        if (apiDetails?.subscribed === 1) {
                          dispatch(setConfirmationModalVisibility(true));
                          return;
                        }
                        apiSubscriptionToggle();
                      }}>
                      {`${apiDetails?.subscribed === 1 ? "UNSUBSCRIBE" : "SUBSCRIBE"}`}
                    </Button> */}

                    {apiDetails?.data?.postmanScript && (
                      <Button
                        className="custom-primary safron postman-run-button"
                        onClick={() => {
                          // @ts-ignore
                        }}
                        data-postman-action="collection/import"
                        data-postman-var-1={apiDetails?.data?.postmanScript}>
                        <span className="icon me-2">{Play}</span>
                        Run in Postman
                      </Button>
                    )}
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
        {/**
         * @api_details
         */}
        <div className="details-wrapper common-pad">
          <Container>
            <Row>
              {/**
               * @image @logo
               */}
              <Col md="3">
                <div className={`image-wrapper ${isScrolling ? "" : "is-stickey"}`}>
                  <div className="img-block position-relative d-flex w-100 h-100 align-items-center justify-content-center">
                    {(apiDetails?.data?.logoIcon || apiDetails?.data?.logo) && (
                      <img src={apiDetails?.data?.logoIcon || apiDetails?.data?.logo} alt="api logo" className="img-fluid" style={{ height: 150 }} />
                    )}
                    <div className={`custum-badge ${apiDetails?.data?.isAvailable ? "" : "bg-secondary"}`}>{apiDetails?.data?.isAvailable ? "Available" : "Inaccessible"}</div>
                  </div>
                </div>
              </Col>
              {/**
               * @description
               * @features
               * @api_credentials
               */}

              <Col md="9">
                <div className="content-block-wrapper mt-4 mt-md-0">
                  <div className="inner-title">
                    <Row className="align-items-center">
                      <Col md="6">
                        <h3 className="mb-0 c-tx-third f-700">About</h3>
                      </Col>
                      <Col md="6">
                        <div className="d-sm-flex my-1 justify-content-md-end align-items-center">
                          <ul className="d-inline-sm-flex d-flex star-list">
                            <IconComponent icon={faStar} className="small c-tx-third mb-0" />
                            <IconComponent icon={faStar} className="small c-tx-third mb-0" />
                            <IconComponent icon={faStar} className="small c-tx-third mb-0" />
                            <IconComponent icon={faStar} className="small c-tx-third mb-0" />
                            <IconComponent icon={faStar} className="small c-tx-third mb-0" />
                            {applicationRating?.find((item) => item.id === parseInt(params.id))?.rating === 5 ? (
                              <IconComponent icon={Filledstar} className="small c-tx-third mb-0 icon-d" />
                            ) : (
                              <IconComponent icon={halfStar} className="small c-tx-third mb-0 icon-d" />
                            )}
                          </ul>
                          <div className="inline-flex ms-sm-2 mt-2 mt-sm-0">
                            {" "}
                            ({applicationRating.find((item) => item.id === parseInt(params.id))?.reviewCount}) | Subscribed User :{" "}
                            {numberFormatter(applicationRating.find((item) => item.id === parseInt(params.id))?.review || 2200, 1)}
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <div className="divider" />
                  <div className="content-bl">
                    <p className="c-tx-ninth"> {apiDetails?.data?.description}</p>
                  </div>
                </div>
                <div className="mt-30 c-tx-ninth">
                  <b className="post-api-color me-1">Note:</b>
                  The API Playground serves as a testing ground for live APIs in Test Mode. API access keys are employed for edeXa SDKs or direct API calls, and you have the option to generate
                  multiple access keys. Explore the{" "}
                  <a href={`${process.env.REACT_APP_accountDomain}/api-keys`} target="_blank" rel="noreferrer" className="f-700 c-tx-forth">
                    Developer Settings
                  </a>{" "}
                  to generate new keys.
                </div>
                {/**
                 * @features
                 * @credentials
                 */}
                <div className="content-block-wrapper">
                  {/**
                   * @features
                   */}
                  {apiDetails?.data?.features && (
                    <>
                      <div className="inner-title">
                        <Row className="align-items-center">
                          <Col md="12">
                            <h3 className="mb-0 c-tx-third text-capitalize f-700">Features</h3>
                          </Col>
                        </Row>
                      </div>
                      <div className="divider"></div>
                      <div className="">
                        <Row>
                          <Col md="12" lg="6">
                            <ul className="point-list mt-0">
                              {apiDetails?.data?.features &&
                                // parse string to json
                                // slice to half array and render
                                // ex - [1, 2, 3, 4] - output - [1,2]
                                apiDetails?.data?.features?.slice(0, apiDetails?.data?.features?.length / 2)?.map((feature: string, index: number) => {
                                  return (
                                    <li key={index}>
                                      <div className="icon">
                                        <p className="c-tx-tenth mb-0">{Check}</p>
                                      </div>
                                      <p className="c-tx-tenth mb-0">{feature}</p>
                                    </li>
                                  );
                                })}
                            </ul>
                          </Col>
                          <Col md="12" lg="6">
                            <ul className="point-list mt-0">
                              {apiDetails?.data?.features &&
                                // parse string to json
                                // slice from half to array length
                                // ex - [1, 2, 3, 4] - output - [3,4]
                                apiDetails?.data?.features?.slice(apiDetails?.data?.features?.length / 2, apiDetails?.data?.features?.length)?.map((feature: string, index: number) => {
                                  return (
                                    <li key={index}>
                                      <div className="icon">
                                        <p className="c-tx-tenth mb-0">{Check}</p>
                                      </div>
                                      <p className="c-tx-tenth mb-0">{feature}</p>
                                    </li>
                                  );
                                })}
                            </ul>
                          </Col>
                        </Row>
                      </div>
                    </>
                  )}
                  {/**
                   * @api_credentials
                   */}
                  <div className="form-block">
                    {apiDetails?.data?.inUse && (
                      <Row>
                        <Col md="12">
                          <div className="switch-block">
                            <p className="f-700 mb-0">SandBox</p>
                            <div className="switch-container">
                              <label>
                                <input className="switch" type="checkbox" checked={isSandboxOrLive} onChange={handleSandbox} />
                                <div>
                                  <div></div>
                                </div>
                              </label>
                            </div>
                            <p className="f-700 mb-0">Live</p>
                          </div>
                        </Col>
                        {/* clientid */}
                        {/* {apiDetails?.data?.clientId && (
                          <Col md="6">
                            <FormGroup>
                              <Label for="clientId">Client ID</Label>
                              <div className="position-relative">
                                <Input
                                  id="clientId"
                                  name="clientId"
                                  placeholder="Client Id"
                                  type="text"
                                  value={
                                    apiDetails?.data?.clientId || ''
                                  }
                                />
                                <div className="icon-absolute ">
                                  <Link
                                    to="/"
                                    onClick={(event) => {
                                      event?.preventDefault()
                                      handleCopyLink(
                                        apiDetails?.data?.clientId,
                                        'clientId'
                                      )
                                    }}
                                  >
                                    <span data-name="userIdCopy">
                                      {Copy}
                                    </span>
                                  </Link>
                                </div>
                              </div>
                            </FormGroup>
                          </Col>
                        )} */}
                        {/* secret */}
                        {/* {apiDetails?.data?.secretKey && (
                          <Col md="6">
                            <FormGroup>
                              <Label for="secretKey">
                                Secret Key
                              </Label>
                              <div className="position-relative">
                                <Input
                                  id="secretKey"
                                  name="text"
                                  placeholder="Secret Key"
                                  // @ts-ignore
                                  type={'text'}
                                  value={
                                    apiDetails?.data?.secretKey || ''
                                  }
                                />
                                <div className="icon-absolute ">
                                  <Link
                                    to="/"
                                    onClick={(event) => {
                                      event?.preventDefault()
                                      handleCopyLink(
                                        apiDetails?.data?.secretKey,
                                        'secretKey'
                                      )
                                    }}
                                  >
                                    <span data-name="userIdCopy">
                                      {Copy}
                                    </span>
                                  </Link>
                                </div>
                              </div>
                            </FormGroup>
                          </Col>
                        )} */}
                        {/**
                         * @document_url
                         */}
                        {apiDetails?.data?.documentUrl && (
                          <Col md="12">
                            <FormGroup>
                              <Label for="apiurl">API Document Url</Label>
                              <div className="position-relative prevent-select">
                                <InputGroup>
                                  <InputGroupText
                                    style={{
                                      backgroundColor: "rgb(244 112 35)",
                                      color: "#fff",
                                      borderColor: "#dadce0",
                                      borderTopLeftRadius: "10px",
                                      borderBottomLeftRadius: "10px",
                                      fontSize: "14px"
                                    }}>
                                    Postman Url
                                  </InputGroupText>
                                  <Input
                                    id="apiurl"
                                    name="email"
                                    className="prevent-select"
                                    placeholder="Api Document Url"
                                    type="text"
                                    value={apiDetails?.data?.documentUrl || ""}
                                    disabled
                                    style={{
                                      cursor: "default",
                                      backgroundColor: "#fff",
                                      borderColor: "#dadce0"
                                    }}
                                  />
                                </InputGroup>
                                <div className="icon-absolute">
                                  <Link
                                    to="/"
                                    style={{ color: "#ff6c37" }}
                                    onClick={(event) => {
                                      event?.preventDefault();
                                      handleCopyLink(apiDetails?.data?.documentUrl, "docUrl");
                                    }}>
                                    {!docUrlCopied ? "Copy" : "Copied"}
                                  </Link>
                                </div>
                              </div>
                            </FormGroup>
                          </Col>
                        )}
                      </Row>
                    )}
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        {/**
         * @api_operations
         */}
        <div className="http-status-wrapper api-explorer-status-wrapper ">
          <Container>
            {apiDetails?.data?.name === "bStamp API" && (
              <Select
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 5,
                  colors: {
                    ...theme.colors,
                    primary25: "#073d8300",
                    primary: "#073d83"
                  }
                })}
                styles={{
                  option: (provided: any) => ({
                    ...provided,
                    cursor: "pointer"
                  }),
                  control: (provided: any) => ({
                    ...provided,
                    cursor: "pointer" // Set the cursor to pointer
                  })
                }}
                onChange={(e) => setVersion(String(e?.label))}
                className="width-25 mb-10"
                defaultValue={{ label: "Version 1", value: "1" }}
                options={[
                  { label: "Version 1", value: "1" },
                  { label: "Version 2", value: "2" }
                ]}
              />
            )}
            <Row>
              {/**
               * @left_section
               * @http_Status_code_summary
               */}
              <Col sm="12" md="12" lg="4" xl="3" xxl="3">
                <div className={`inner-common-block mb-3 mb-md-0 st-sticky ${isScrolling ? "" : "stickey-149"}`}>
                  <div className="status-header common-pad mb-0">
                    <p className="mb-0 c-tx-primary text-uppercase c-bg-sixth f-700">HTTP STATUS CODE SUMMARY</p>
                  </div>
                  <ul className="common-pad mb-0 code-list py-0">
                    {apiDetails?.data?.data &&
                      apiDetails?.data?.data?.map((api: any, index: number) => {
                        return (
                          <li key={index}>
                            {/* eslint-disable-next-line */}
                            <a
                              href="#"
                              onClick={(event) => {
                                event.preventDefault();
                                scrollToTargetAdjusted(150, api.id);
                              }}>
                              <div className="block-1">
                                <p className="c-tx-forth mb-0 f-700">{api.request_type}</p>
                              </div>
                              <div
                                role="button"
                                className={`block-2 ${currentActiveHttpRequest?.[index] ? "f-700" : ""}`}
                                onClick={() => {
                                  setActiveAPICall(api);
                                  setActiveHttpRequest(index);
                                }}>
                                <p className="c-tx-eleven mb-0">{api.name}</p>
                              </div>
                            </a>
                          </li>
                        );
                      })}
                  </ul>
                </div>
              </Col>

              <Col md="12" lg="8" xl="9" xxl="9">
                {apiDetails?.data?.data &&
                  apiDetails?.data?.data?.map((apiExplor: any, index: number) => <ApiDetailComponent apiDetailsState={apiDetails} apiExplor={apiExplor} index={index} key={index} />)}
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      {/* unsubscribe action confirmation */}
      <ConfirmationModal handleOnClickAction={apiSubscriptionToggle} title="Are you sure you want to unsubscribe" />

      <CommonConfirmationModal
        handleActions={setIsSandboxOrLive}
        value={valueOfSandBox}
        subTitle={`Are you sure, do you wants to change from ${changePopupText === "Live" ? "Sandbox" : "Live"} to ${changePopupText}`}
        featureModelVisibility={featureModelVisibility}
        toggleFeatureModel={toggleFeatureModel}
        title="Confirmation"
      />
      <Modal centered isOpen={developerModal} style={{ maxWidth: "600px" }}>
        <ModalBody className="p-4 position-relative">
          <FontAwesomeIcon icon={faXmark} onClick={toggleKeyInfoModal} className="cursor-pointer position-absolute" style={{ right: "25px" }} />
          <h2 className="fw-800 font-22 mb-0">API Key Info</h2>
          <div className="mt-30 c-tx-ninth">
            <b className="post-api-color me-1">Note:</b>
            The API Playground serves as a testing ground for live APIs in Test Mode. API access keys are employed for edeXa SDKs or direct API calls, and you have the option to generate multiple
            access keys. Explore the{" "}
            <a href={`${process.env.REACT_APP_accountDomain}/api-keys`} target="_blank" rel="noreferrer" className="f-700 c-tx-forth">
              Developer Settings
            </a>{" "}
            to generate new keys.
          </div>
          <div className="mt-30 c-tx-ninth">
            <b className="post-api-color me-1">Note:</b>
            This is your client and secret key which will only be shown once
          </div>
          <Container style={{ maxWidth: "100%" }}>
            <Row className="mt-4">
              <Col xs="6" sm="4">
                <strong>Client API Key ID</strong>
              </Col>
              <Col xs="6" sm="8" className="cursor-pointer" onClick={() => handleCopy(location?.state?.clientId, "Client Key is copied to clipboard")}>
                {location?.state?.clientId}
              </Col>
            </Row>
            <Row className="mt-4">
              <Col xs="6" sm="4">
                <strong>Client API Secret Key</strong>
              </Col>
              <Col
                xs="6"
                sm="8"
                style={{ lineBreak: "anywhere" }}
                onClick={() => showSecretKey && handleCopy(location?.state?.secretKey, "Secret Key is copied to clipboard")}
                className={`cursor-pointer ${showSecretKey ? "" : "secretKey"}`}>
                {location?.state?.secretKey}
              </Col>
              <Col xs="6" sm="8">
                <button className="revoke button mt-4" onClick={() => setShowSecretKey(!showSecretKey)}>
                  {showSecretKey ? "Hide your Secret Key" : "Reveal your Secret Key"}
                </button>
              </Col>
            </Row>
          </Container>
          <div className="d-flex justify-content-end gap-2 mt-20">
            <button className="revoke button" onClick={() => handleDownload(location?.state?.clientId, location?.state?.secretKey)}>
              <FontAwesomeIcon icon={faDownload} style={{ marginRight: "10px" }} />
              Download
            </button>
            <button className="revoke button" onClick={toggleKeyInfoModal}>
              Done
            </button>
          </div>
        </ModalBody>
      </Modal>
      <Modal isOpen={open} size="md" centered keyboard={false} backdrop="static">
        <ModalBody className="p-4 position-relative">
          <FontAwesomeIcon icon={faXmark} onClick={handleModalOpen} className="cursor-pointer position-absolute" style={{ right: "25px" }} />
          <h2 className="fw-800 font-22 mb-0">Webhook</h2>
          <Container style={{ maxWidth: "100%" }}>
            <Row className="mt-4">
              <Col>
                <Label for="firstname">Client Key</Label>
                <Input
                  id="clientKey"
                  name="clientKey"
                  placeholder="Client Key"
                  type="text"
                  onChange={(e) => {
                    setClientKey(e.target.value);
                  }}
                />
              </Col>
            </Row>
            <Row className="mt-4">
              <Col>
                <Label for="firstname">Secret Key</Label>
                <InputGroup>
                  <Input
                    id="secretKey"
                    name="secretKey"
                    placeholder="Secret Key"
                    type={showPassword ? "text" : "password"}
                    onChange={(e) => {
                      setSecretKey(e.target.value);
                    }}
                  />
                  <InputGroupText className="input-group-wrapper cursor-pointer input-group-text" onClick={() => togglePassword()}>
                    <InputGroupText style={{ border: 0 }}>{showPassword ? <FontAwesomeIcon icon={faEyeSlash} /> : <FontAwesomeIcon icon={faEye} />}</InputGroupText>
                  </InputGroupText>
                </InputGroup>
              </Col>
            </Row>
            <div className="mt-30 c-tx-ninth">
              <b className="post-api-color me-1">Note:</b>
              You can get your client key and secret key from your accounts.{" "}
              <a href={`${process.env.REACT_APP_accountDomain}/api-keys/create?client=bStamp`} target="_blank" rel="noreferrer" className="f-700 c-tx-forth">
                Developer Settings
              </a>{" "}
            </div>
          </Container>
          <div className="d-flex justify-content-end gap-2 mt-20">
            <button className="revoke button" onClick={handleModalOpen}>
              Cancel
            </button>
            <button disabled={clientKey.length === 0 ? true : secretKey.length === 0 ? true : false} className="revoke button" onClick={handleAuthenticate}>
              Authenticate
            </button>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default APIExplorer;
