// import FooterComponent from "Pages/common/Footer";
// import SidebarComponent from "Pages/common/Sidebar";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import { IRootReducer } from "store/root-reducer";
import FooterComponent from "../layout/Footer";
import SidebarComponent from "../layout/Sidebar";

const DashboardOutletContainer = () => {
  // check if user has permissions for the dashboard routes or not
  // if no permissions then return to root
  const user = useSelector((state: IRootReducer) => state.userDetails.user);

  return user?.networkStatus === 1 ? (
    <div className="dashboard-layout">
      <SidebarComponent />
      <div className="admin-content-wrapper">
        <div className="dashboard-content-wrapper">
          <Outlet />
        </div>
        <div className="footer-active-wrap">
          <FooterComponent />
        </div>
      </div>
      {/* <MobileFooter /> */}
    </div>
  ) : (
    <Navigate to={"/"} />
  );
};

export default DashboardOutletContainer;
