import { chainCodeAPI } from "actions/chainCodeAPI";
import { memo, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Button, Modal } from "reactstrap";
import { setLoading } from "store/loader/action";
import { IRootReducer } from "store/root-reducer";
import { closeTemplateModal, setTemplateData, setTemplateModal, setTemplateSubmit } from "store/templateReducer/action";
import { toastSuccess } from "utils/commonFunctions";

const ReviewConfirmationTemplateModal = () => {
  const dispatch = useDispatch();
  const modal = useSelector((state: IRootReducer) => state.TemplateReducer.modal);
  const { data, id, submit } = useSelector((state: IRootReducer) => state.TemplateReducer);

  const handleSubmit = useCallback(async () => {
    dispatch(setLoading(true));
    let newData = { ...data };
    await Object.keys({ ...data }).forEach((key) => {
      if (typeof newData[key] === "object") {
        newData[key] = newData[key]?.value;
      }
    });

    chainCodeAPI({
      ...newData,
      templateId: id
    }).then((res: any) => {
      if (res.status === 201) {
        toastSuccess(res?.message);
        dispatch(setTemplateModal("SuccessTemplateModal"));
        dispatch(setTemplateSubmit(false));
        dispatch(setTemplateData({}));
      }
      dispatch(setLoading(false));
    });
  }, [data, dispatch, id]);

  useEffect(() => {
    if (submit) {
      handleSubmit();
    } //eslint-disable-next-line
  }, [submit]);

  return (
    <Modal
      isOpen={modal.ReviewConfimationTemplateModal}
      className="custom-popup-channel"
      size="xs"
      centered
      external={
        <Link
          to="#"
          className="f-700 text-white close modal-close close small text-uppercase thankYouCloseButtonHide font-30"
          onClick={() => {
            dispatch(setTemplateData({}));
            dispatch(closeTemplateModal("ReviewConfimationTemplateModal"));
          }}>
          CLOSE
        </Link>
      }>
      {" "}
      <div className="erc-template-wrapper w-100">
        <div className="title">
          <h3 className="c-tx-primary text-center f-700 mb-20">Are you sure ?</h3>
        </div>
        <p className="c-tx-ninth text-center mb-20">Would you like to submit or would you like to review again ?</p>
        <div className="btn-wrapper text-center">
          <Button className="custom-primary-outline me-3 mb-3 mb-sm-0 new-btn" onClick={() => dispatch(setTemplateModal("ReviewTemplateModal"))}>
            YES, I WANTS TO REVIEW
          </Button>
          <Button
            className="custom-primary new-btn"
            onClick={() => {
              handleSubmit();
            }}>
            Submit
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default memo(ReviewConfirmationTemplateModal);
