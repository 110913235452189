import { apiEndPoints } from "utils/api-endpoints";
import HTTPService from "utils/httpService";

export const getContractViewAPI = (id: string): Promise<IgetContractViewAPIRes> => {
  return HTTPService.get(apiEndPoints.universeAPI.smartContract.view + id);
};

export interface IgetContractViewAPIResData {
  cntrctUser: {
    email: string;
    name: string;
    userId: string;
  };
  createdAt: string;
  deletedAt: any;
  description: string;
  file: string;
  id: string;
  language: string;
  name: string;
  protocol: string;
  status: number;
  updatedAt: string;
}

interface IgetContractViewAPIRes {
  status: number;
  message: string;
  data: IgetContractViewAPIResData;
}
