import Highcharts from 'highcharts/highstock'
import moment from 'moment'

export const getGraphOptions = (mapData: any) => {
  const getSubtitleTextTime = (data: any) => {
    const uniqueDates = Array.from(
      new Set(
        data.map((obj: any) =>
          moment(obj.key * 1000).format('DD/MM/YYYY')
        )
      )
    )
    if (uniqueDates.length === 2) {
      return `Dates: ${uniqueDates[0]} and ${uniqueDates[1]}`
    } else if (uniqueDates.length === 1) {
      return `Date: ${uniqueDates[0]}`
    } else {
      return 'Unknown Date'
    }
  }

  const uniquePeerNames = Array.from(
    new Set(
      mapData
        .map((obj: any) =>
          obj.values
            .filter((data: any) => data)
            .map((name: any) => name.peerName)
        )
        .flat()
    )
  )
  const maxValues =
    mapData.flatMap((channel: any) =>
      channel?.values.map(
        (time: any) => new Date(time?.timestamp * 1000)
      )
    )?.length || 0

  return {
    time: {
      useUTC: false
    },
    chart: {
      zoomType: 'x'
    },
    credits: {
      enabled: false
    },
    title: {
      text: null
    },
    subtitle: {
      text: mapData?.length > 0 ? getSubtitleTextTime(mapData) : null,
      align: 'right',
      y: 10,
      style: {
        fontSize: '12px'
      }
    },
    xAxis: {
      type: 'datetime',
      min: mapData.flatMap((channel: any) =>
        channel?.values?.map((time: any) => time?.timestamp * 1000)
      )?.[0],
      max: mapData.flatMap((channel: any) =>
        channel?.values?.map((time: any) => time?.timestamp * 1000)
      )?.[maxValues - 1],
      dateTimeLabelFormats: {
        millisecond: '%H:%M:%S',
        second: '%H:%M:%S',
        minute: '%H:%M',
        hour: '%H:%M',
        day: '%e of %b'
      },
      title: {
        text: 'Timestamp'
      }
    },
    yAxis: {
      title: {
        text: 'Value'
      }
    },
    plotOptions: {
      line: {
        lineWidth: 2,
        states: {
          hover: {
            lineWidth: 4
          }
        },
        marker: {
          enabled: false
        }
      },
      series: {
        pointStart: mapData.flatMap((channel: any) =>
          channel?.values?.map((time: any) => time?.timestamp * 1000)
        )?.[0],
        pointInterval: 14000
      }
    },
    tooltip: {
      formatter: function (
        this: Highcharts.TooltipFormatterContextObject
      ) {
        const dataIndex = this?.point.index
        const value: any = this?.y
        const timestamp =
          new Date(this?.point?.x).toLocaleString() ||
          'Unknown Time Stamp'
        const peerName =
          mapData.flatMap((channel: any) =>
            channel?.values?.map((name: any) => name.peerName)
          )?.[dataIndex] || 'Unknown Peer Name'

        let tooltipContent = `<b>Value:</b> ${value}<br>`
        tooltipContent += `<b>Peer Name:</b> ${peerName}<br>`
        tooltipContent += `<b>Timestamp:</b> ${timestamp}<br>`
        return tooltipContent
      }
    },
    series: uniquePeerNames.map((peerName) => ({
      type: 'line',
      name: peerName,
      data:
        mapData.length > 0
          ? mapData
              .flatMap((data: any) =>
                data?.values?.filter(
                  (peer: any) => peer?.peerName === peerName
                )
              )
              .map((val: any) => val?.value)
          : null
    })),
    navigation: {
      menuItemStyle: {
        fontSize: '10px'
      }
    }
  }
}
