import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Select from 'react-select'
import { Col, Form, FormGroup, Row, Spinner } from 'reactstrap'
import { setSearchOfPeer } from 'store/peerData/action'
import { IRootReducer } from 'store/root-reducer'
import { timingForGraphArray } from '../constants'

const CommonGraphs = ({
  handleSelectedTime,
  isLoading,
  profile,
  setProfile,
  setStoreChannelId,
  storeChannelId,
  graphName
}: any) => {
  const dispatch = useDispatch()
  const getChannelDataFromRedux = useSelector(
    (state: IRootReducer) =>
      state?.channelDataReducer?.storeChannelData
  )
  const options = useSelector(
    (state: IRootReducer) =>
      state.peerReducer.searchedPeerListForDropdown
  )
  const [selectPastTimeFilter, setSelectPastTimeFilter] = useState(
    timingForGraphArray.length > 0 && timingForGraphArray[0]
  )

  useEffect(() => {
    if (options?.length > 0) {
      setProfile({ label: options[0].label })
    }
  }, [options])

  // get time
  const GetCommonValueTimer = (
    millisecondsOfSelectedTime: string
  ) => {
    const currentDateInMilliseconds = Date.now()
    const convertToNumberMillisecondsOfSelectedTime = parseInt(
      millisecondsOfSelectedTime
    )
    const factorMapping = {
      300000: 3,
      900000: 3,
      1800000: 7,
      3600000: 14,
      7200000: 28,
      21600000: 86,
      43200000: 172,
      86400000: 345
    }
    if (factorMapping.hasOwnProperty(millisecondsOfSelectedTime)) {
      const adjustedTime =
        currentDateInMilliseconds -
        24 * 60 * 60 * 1000 -
        convertToNumberMillisecondsOfSelectedTime
      const timeInSeconds = adjustedTime / 1000
      handleSelectedTime(timeInSeconds)
    }
  }

  return (
    <>
      <Row>
        <div className="d-flex">
          <Col md="3">
            <div className="title">
              <h6 className="c-tx-primary f-600 mb-10">
                {graphName} &nbsp;
                {isLoading && (
                  <div className="loader-center">
                    <Spinner
                      style={{
                        marginTop: '35%',
                        marginLeft: '50%'
                      }}
                      size={'sm'}
                    />
                  </div>
                )}
              </h6>
            </div>
          </Col>
          <Col md="2">
            <div className="title extra-css">
              {/* timer dropdown */}
              <Select
                className="peer-drop-down"
                //@ts-ignore
                defaultValue={
                  timingForGraphArray?.length && {
                    label: timingForGraphArray[0].label,
                    value: timingForGraphArray[0].value
                  }
                }
                value={selectPastTimeFilter}
                options={timingForGraphArray}
                onChange={(value: any) => {
                  setSelectPastTimeFilter(value)
                  GetCommonValueTimer(value.value)
                }}
              />
            </div>
          </Col>
          <Col md="3" className="ml-10">
            <Form>
              <FormGroup>
                {/* peer dropdown */}
                <Select
                  className="peer-drop-down"
                  options={options}
                  onInputChange={(e: string) => {
                    dispatch(setSearchOfPeer(e))
                  }}
                  value={profile}
                  onChange={(value) => {
                    setProfile(value)
                  }}
                />
              </FormGroup>
            </Form>
          </Col>
          {graphName !== 'CPU Usage' &&
            graphName !== 'Memory Usage' && (
              <Col md="3" className="ml-40">
                {/* channel dropdown */}
                <Select
                  className="peer-drop-down"
                  defaultValue={
                    getChannelDataFromRedux?.length > 0 && {
                      label: getChannelDataFromRedux[0].channelName,
                      value: getChannelDataFromRedux[0]?.id
                    }
                  }
                  value={storeChannelId}
                  options={getChannelDataFromRedux.map(
                    (single: any) => {
                      return {
                        label: single?.channelName,
                        value: single?.id
                      }
                    }
                  )}
                  onChange={(e: any) => {
                    setStoreChannelId(e)
                  }}
                />
              </Col>
            )}
        </div>
      </Row>
    </>
  )
}

export default CommonGraphs
