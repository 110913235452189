import { faTimesCircle } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";
import { Modal, ModalBody } from "reactstrap";
import { setFirstTimeVisitorModalVisibility, setFirstTimeVisitorTourVisibility } from "store/reactour/action";
import { IRootReducer } from "store/root-reducer";
import { redirectTo } from "utils/commonFunctions";

const FirstVisitModal = () => {
  const dispatch = useDispatch();
  const { visitorModalVisible } = useSelector((state: IRootReducer) => state.firstTimeTourReducer);

  // toggle the visitor modal and the tour highlights
  const toggleModelVisibility = () => {
    dispatch(setFirstTimeVisitorModalVisibility(false));
    dispatch(setFirstTimeVisitorTourVisibility(true));
  };
  return (
    <Modal centered size={"md"} isOpen={visitorModalVisible} toggle={() => toggleModelVisibility()} backdrop="static">
      <ModalBody className="position-relative">
        <FontAwesomeIcon
          icon={faTimesCircle}
          className="font-20 position-absolute color-light-grey cursor-pointer mt-1"
          style={{ right: 10, top: 5 }}
          onClick={() => toggleModelVisibility()}></FontAwesomeIcon>
        <h2 className="text-center mt-3 mb-4">Welcome to the Universe of edeXa.</h2>
        {/* description */}

        <p className="text-center font-14 mt-2 mb-4 px-3 color-light-grey">
          How about taking a quick Tour to discover all the great features.
          <br />
          {/* Universe provides fantastic ways to build your own Blockchain ecosystem or just simply start using our ready to go Dapps into your business with a simple Click. */}
        </p>
        <p className="text-center font-16 fw-bold mt-3 mb-3">Reach us on</p>
        {/* social urls */}
        <div className="d-flex justify-content-between align-items-center mb-3" style={{ maxWidth: 400, margin: "auto" }}>
          <button className="bg-transparent font-14 color-light-grey" onClick={() => redirectTo(process.env.REACT_APP_FACEBOOK)}>
            Facebook{" "}
          </button>
          <p> - </p>
          <button className="bg-transparent font-14 color-light-grey" onClick={() => redirectTo(process.env.REACT_APP_TWITTER)}>
            Twitter{" "}
          </button>
          <p> - </p>
          <button className="bg-transparent font-14 color-light-grey" onClick={() => redirectTo(process.env.REACT_APP_INSTAGRAM)}>
            Instagram{" "}
          </button>
          <p> - </p>
          <button className="bg-transparent font-14 color-light-grey" onClick={() => redirectTo(process.env.REACT_APP_edexaDomain)}>
            Website{" "}
          </button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default FirstVisitModal;
