import { apiEndPoints } from "utils/api-endpoints";
import HTTPService from "utils/httpService";

export const getLogsApi = (id: any, params: any): Promise<iGetLogsAPi> => {
  return HTTPService.get(`${apiEndPoints.logs.logsHistory}/${id}/logs`, params);
};

interface iGetLogsAPi {
  message: string;
  status: number;
  data: {
    logs: object;
  };
}
