import { apiEndPoints } from "utils/api-endpoints";
import HTTPService from "utils/httpService";

export const getAppListing = (): Promise<IAllAvailableApps> => {
  return new Promise((resolve, reject) => {
    HTTPService.get(apiEndPoints.getAvailableApps)
      .then((response: any) => resolve(response))
      .catch((e) => reject(e));
  });
};

interface IApp {
  appId: string;
  app_url: string;
  desktop_url: string;
  document_url: string;
  extension_url: string;
  id: number;
  ios_url: string;
  isAvailable: number;
  logo: string;
  logoIcon: string;
  name: string;
  order: number;
  redirect_url: string;
}
export interface IAllAvailableApps {
  data: [IApp];
  message: "Application info get successfully";
  status: 200;
}
