import { networkInfoAPI } from "actions/networkInfoAPI";
import { FullPageLoader } from "components/loader";
import { FirstVisitModal, ReactourFirstVisit } from "components/reactTour";
import useComingSoonHook from "hooks/UseComingSoonHook";
import useAuthCookieWatcher from "hooks/UseCookieWatcher";
import useLocalhostCookieSetupOnRedirect from "hooks/UseLocalhostCookieSetupOnRedirect";
import { Suspense, useCallback, useEffect } from "react";
import { Toaster } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import * as io from "socket.io-client";
import { onUserLoggedIn, setUserTour } from "store/auth/action";
import { setGlobalSetting } from "store/globalSettingReducer/action";
import { setLoading } from "store/loader/action";
import { setNetwork } from "store/networkReducer/action";
import { setNotificationCount } from "store/notification/action";
import { IRootReducer } from "store/root-reducer";
import { setScrolling } from "store/scrolling/action";
import { onUserDetailsUpdate, setUserInfo } from "store/user/action";
import { getUnderMaintananceAPI } from "utils/common/helper/getUnderMaintanance";
import { onGetProfileDetails } from "utils/common/helper/profileActions";
import { getDecryptedLocalStorage, getEncryptedCookie, handleErrors, handleLogout, setEncryptedLocalStorage } from "utils/commonFunctions";
import { cookieKeys, localStorageKeys } from "utils/constants";
import { ProtectedRouteComponent } from "./ProtectedRouteComponent";
import { PublicRouteComponent } from "./PublicRouteComponent";
import UnderMaintananceRoute from "./UnderMaintananceRoute";

function RoutingComponent() {
  // prevent console logs and errors
  handleErrors();
  const dispatch = useDispatch();
  const GlobalSettingReducer = useSelector((state: IRootReducer) => state.GlobalSettingReducer);

  /**
   * @isUserLoggedIn
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { isLoggedIn, isLoading } = useSelector((state: any) => ({
    isLoggedIn: state.auth.isLoggedIN,
    isLoading: state.loading.isLoading
  }));
  const user = useSelector((state: IRootReducer) => state.userDetails);

  // if you pass true then you do not have to call the getProfile data function seperately
  useLocalhostCookieSetupOnRedirect();

  // check if user auth cookie changes/deleted then logout him from current app.
  // ⚠ Be cautious while using this hook because it can keep your user logged-out in loop
  const { exist } = useAuthCookieWatcher(`${cookieKeys.cookieInitial}-${cookieKeys.cookieUser}`, 1000);
  useEffect(() => {
    // if the user is logged in but the auth cookie dosen't exist that means he is logged out from some other subdomains so logout him from current app too.
    if (!exist && isLoggedIn) {
      handleLogout();
    }
  }, [exist, isLoggedIn]);

  const getNetworkAction = () => {
    networkInfoAPI().then((res) => {
      if (res?.status === 200) {
        setEncryptedLocalStorage(localStorageKeys.networkInfo, res?.data);
        dispatch(setNetwork(res?.data));
        dispatch(setLoading(false));
        if (res.data.tour) {
          dispatch(setUserTour(true));
        }
      }
    });
  };

  const getProfileData = () => {
    dispatch(setLoading(true));
    onGetProfileDetails()
      .then((response) => {
        setEncryptedLocalStorage(localStorageKeys.isLoggedIN, response.data);
        dispatch(onUserLoggedIn());
        dispatch(setNotificationCount(response.data.unRead));
        dispatch(onUserDetailsUpdate(response.data));
        dispatch(setUserInfo(false));
        if (response?.data?.networkStatus === 1) {
          getNetworkAction();
        } else {
          dispatch(setLoading(false));
        }
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  const getUnderMaintanance = () => {
    getUnderMaintananceAPI().then((res) => {
      if (res.status === 200) {
        dispatch(setGlobalSetting(res.data));
      }
    });
  };

  const listenToSocketChanges = useCallback(
    (userId: string) => {
      const socketConnection = io.io(process.env.REACT_APP_ACCOUNT_SOCKETENDPOINT || "", {
        path: process.env.REACT_APP_ACCOUNT_SOCKETPATH,
        transports: ["websocket", "polling"]
      });

      socketConnection?.on(`userInfo_${userId}`, (data) => {
        setEncryptedLocalStorage(localStorageKeys.isLoggedIN, data);
        dispatch(onUserDetailsUpdate(data));
      });
    }, //eslint-disable-next-line
    [dispatch, user.userId]
  );

  useEffect(
    () => {
      if (user.user?.id) {
        const socketConnection1 = io.io(process.env.REACT_APP_UNIVERSESOCKETPOINT || "", {
          path: "",
          transports: ["websocket", "polling"]
        });
        socketConnection1?.on(`Network_create_${user.user?.id}`, (data: any) => {
          getProfileData();
        });
        socketConnection1?.on("settings", (data: { underMaintenance: boolean }) => {
          dispatch(setGlobalSetting(data));
        });
      }
    }, //eslint-disable-next-line
    [user.user?.id]
  );
  // on first load set cookie if url contains ?use=
  useEffect(() => {
    // if cookie is set in local then get the profile data
    // getProfileData();
    getUnderMaintanance();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (user?.userInfo) {
      getProfileData();
    } //eslint-disable-next-line
  }, [user?.userInfo])

  // login check function
  const checkUserLoginAndSetCookies = () => {
    const cookieUser = getEncryptedCookie(cookieKeys.cookieUser);
    const token = getDecryptedLocalStorage(localStorageKeys.userToken);
    const userLogin = getDecryptedLocalStorage(localStorageKeys.isLoggedIN);
    if (!cookieUser) {
      handleLogout();
    } else {
      listenToSocketChanges(cookieUser.userId);
      if (!userLogin || token !== cookieUser.token) {
        setEncryptedLocalStorage(localStorageKeys.userToken, cookieUser.token);
        getProfileData();
      }
    }
  };
  useEffect(() => {
    checkUserLoginAndSetCookies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, listenToSocketChanges]);

  /**
   * @track_scroll_event
   */
  useEffect(() => {
    var lastScrollTop = 0;
    window.addEventListener("scroll", (e: any) => {
      let st = e.target.documentElement?.scrollTop;
      if (st > lastScrollTop) {
        dispatch(setScrolling(false));
      } else {
        dispatch(setScrolling(true));
      }
      lastScrollTop = st <= 0 ? 0 : st;
    });
  }, [dispatch]);

  // use this state with visitorModalVisible when backend api is ready
  const { tour } = useSelector((state: IRootReducer) => state.auth);
  const { visitorTourVisible, visitorModalVisible } = useSelector((state: IRootReducer) => state.firstTimeTourReducer);
  useComingSoonHook();
  return (
    <>
      {GlobalSettingReducer.underMaintenance ? (
        <UnderMaintananceRoute />
      ) : (
        <>
          {isLoading ? <FullPageLoader /> : null}
          <Toaster />
          <Suspense fallback={<FullPageLoader />}>{isLoggedIn ? <ProtectedRouteComponent /> : <PublicRouteComponent />}</Suspense>
          {tour && visitorModalVisible ? <FirstVisitModal /> : null}
          {GlobalSettingReducer.comingSoon ? null : visitorTourVisible ? <ReactourFirstVisit /> : null}
        </>
      )}
    </>
  );
}

export default RoutingComponent;
