import { getChannelListingAPI } from 'actions/channelListingAPI'
import { getPeerListAPI } from 'actions/peerListAPI'
import { TitleComponent } from 'components/titleComponent'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Col, Row } from 'reactstrap'
import { setChannelDataToStoreInRedux } from 'store/channelData/action'
import { setLoading } from 'store/loader/action'
import {
  setLoadDiffPeer,
  setPeerData,
  setSearchOfPeersListForDropdown
} from 'store/peerData/action'
import { IRootReducer } from 'store/root-reducer'
import { toastError } from 'utils/commonFunctions'
import '../../../../src/styles/PeerNode/peerNodeChart.scss'
import '../../../styles/pages/myblockchain/myblockchain.scss'
import { CertificateAuthoritiesListComponent } from '../certificateAuthorities'
import { CPUUsage, MemoryUsage, PeerNodeChart } from '../charts'
import TransactionCountGraph from '../charts/TransactionCount'
import { OrderManagementListComponent } from '../orderManagement'
import { PeerManagement } from '../peerManagement'

const MyBlockChain = () => {
  const [totalPeerCount, setTotalPeerCount] = useState(0)
  const [increasePageNumber, setIncreasePageNumber] = useState(1)
  const searchedValueOfPeer = useSelector(
    (state: IRootReducer) => state.peerReducer.searchedPeer
  )
  const dispatch = useDispatch()
  const paginationConfig = {
    search: '',
    page: 1,
    limit: 10
  }

  // get channel list
  useEffect(() => {
    if (getChannelDataFromRedux.length < 1) {
      let params: any = {
        limit: paginationConfig.limit,
        page: paginationConfig.page
      }
      if (paginationConfig.search) {
        params.search = paginationConfig.search
      }
      dispatch(setLoading(true))
      getChannelListingAPI(params).then((res: any) => {
        if (res.status === 200) {
          dispatch(setChannelDataToStoreInRedux(res.data?.data))
        }
        dispatch(setLoading(false))
      })
    }
    dispatch(setLoadDiffPeer(true))
  }, [])

  const getChannelDataFromRedux = useSelector(
    (state: IRootReducer) =>
      state?.channelDataReducer?.storeChannelData
  )

  // get peer list
  let params = { limit: 6, page: increasePageNumber }
  useEffect(() => {
    if (totalPeerCount > params.page * params.limit) {
      setIncreasePageNumber((prev) => prev + 1)
    }
    getPeerListAPI(params, searchedValueOfPeer)
      .then((res) => {
        if (res.status === 200) {
          const newoption: any = res?.data?.data?.map((data: any) => {
            return { label: data?.name, value: data?.id }
          })
          const allOption = { label: 'ALL' }
          newoption.unshift(allOption)
          dispatch(setSearchOfPeersListForDropdown(newoption))
          setTotalPeerCount(res?.data?.count)
          // @ts-ignore
          dispatch(setPeerData(res?.data?.data))
        }
      })
      .catch((err) => toastError(err))
  }, [])


  return (
    <div className="my-block-chain-wrapper">
      <TitleComponent title="My BlockChain" />
      <Row>
        <Col md="6" lg="6" xl="6" xxl="4">
          <PeerManagement />
        </Col>
        <Col md="6" lg="6" xl="6" xxl="4">
          <OrderManagementListComponent />
        </Col>
        <Col md="6" lg="6" xl="6" xxl="4">
          <CertificateAuthoritiesListComponent />
        </Col>
      </Row>

      <TitleComponent title="Metrics & Monitoring" />
      <Row>
        <Col md="6" lg="6" xl="6" xxl="6">
          <PeerNodeChart />
        </Col>
        <Col md="6" lg="6" xl="6" xxl="6">
          <TransactionCountGraph />
        </Col>
        <Col md="6" lg="6" xl="6" xxl="6">
          <CPUUsage />
        </Col>
        <Col md="6" lg="6" xl="6" xxl="6">
          <MemoryUsage />
        </Col>
      </Row>
    </div>
  )
}

export default MyBlockChain
