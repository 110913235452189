import { getContractViewAPI, IgetContractViewAPIResData } from "actions/getContractViewAPI";
import { BusinessModalPop } from "components/template";
import { FC, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Modal } from "reactstrap";
import { setLoading } from "store/loader/action";

interface IContractModal {
  businessmodal: {
    open: boolean;
    id: string;
  };
  setBusinessmodal: React.Dispatch<
    React.SetStateAction<{
      open: boolean;
      id: string;
    }>
  >;
}

const ContractModal: FC<IContractModal> = ({ businessmodal, setBusinessmodal }) => {
  const dispatch = useDispatch();

  const [listData, setListData] = useState<IgetContractViewAPIResData>({
    cntrctUser: {
      email: "Email ID",
      name: "Name",
      userId: "User Id"
    },
    createdAt: "14/04/2022",
    deletedAt: "4/04/2022",
    description: "Description",
    file: "File",
    id: "Id",
    language: "Language",
    name: "Name",
    protocol: "Protocol",
    status: 0,
    updatedAt: "4/04/2022"
  });

  const getContractViewAction = () => {
    dispatch(setLoading(true));
    getContractViewAPI(businessmodal.id).then((res) => {
      if (res.status === 200) {
        setListData(res.data);
      }
      dispatch(setLoading(false));
    });
  };

  useEffect(() => {
    businessmodal.open && businessmodal.id && getContractViewAction();
    // eslint-disable-next-line
  }, [businessmodal]);

  return (
    <Modal
      isOpen={businessmodal.open}
      className="custom-popup-channel"
      toggle={() => setBusinessmodal({ open: false, id: "" })}
      size="lg"
      centered
      external={
        <Link to="#" className="f-600 text-white close modal-close close small text-uppercase thankYouCloseButtonHide font-30" onClick={() => setBusinessmodal({ open: false, id: "" })}>
          CLOSE
        </Link>
      }>
      <BusinessModalPop listData={listData} />
    </Modal>
  );
};

export default ContractModal;
