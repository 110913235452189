// @ts-nocheck
import { useState } from "react";
import { AccordionHeader, AccordionItem, FormGroup, Input, Label, UncontrolledAccordion } from "reactstrap";

const AccordianWrap = ({ activeAPICall, clientId, setApiDetails, apiDetails, indexVal, showAccordian1, setShowAccordian1, multipleStamp }) => {
  const [showAccordian, setShowAccordian] = useState(true);
  const [showAccordian2, setShowAccordian2] = useState(false);

  const validateArrayOfNumbers = (value) => {
    return value.split(",").every((item) => !isNaN(item.trim()));
  };

  const validateArrayOfStrings = (value) => {
    return value.split(",").every((item) => item.trim() !== "");
  };

  const renderRequiredInput = (raw, index) => {
    if (raw.type === "text") {
      if (multipleStamp.length === 0) {
        return (
          <Input
            id={`${activeAPICall.name}${activeAPICall?.body?.requireParams.mode}${raw.key}`}
            name={raw.key}
            placeholder={raw.key}
            type={raw?.type}
            value={raw?.value || ""}
            onChange={(e) => {
              apiDetails.data.data[indexVal].body.requireParams.raw[index].value = e.target.value;
              apiDetails.data.data[indexVal].body.requireParams.raw[index].errorReqire = false;

              setApiDetails({
                ...apiDetails,
                apiDetails
              });
            }}
            invalid={raw.errorReqire}
          />
        );
      } else {
        return multipleStamp.map((data, i) => (
          <Input
            key={i}
            className="mt-10"
            id={`${activeAPICall.name}${activeAPICall?.body?.requireParams.mode}${raw.key}`}
            name={raw.key}
            placeholder={raw.key}
            type={raw?.type}
            value={data[raw?.key] || ""}
            onChange={(e) => {
              apiDetails.data.data[indexVal].body.requireParams.raw[index].value = e.target.value;
              apiDetails.data.data[indexVal].body.requireParams.raw[index].errorReqire = false;
              setApiDetails({
                ...apiDetails,
                apiDetails
              });
            }}
            invalid={raw.errorReqire}
          />
        ));
      }
    } else if (raw.type === "boolean") {
      return (
        <div className="switch-block p-0">
          <div className="switch-container ml-0" style={{ marginLeft: "0px" }}>
            <label>
              <input
                className="switch"
                type="checkbox"
                checked={raw?.value}
                onChange={(e) => {
                  apiDetails.data.data[indexVal].body.requireParams.raw[index].value = e.target.checked;
                  setApiDetails({
                    ...apiDetails,
                    apiDetails
                  });
                }}
              />
              <div>
                <div></div>
              </div>
            </label>
          </div>
          <p className="f-700 mb-0 text-secondary">{raw?.value ? "Private Blockchain" : "Public Blockchain"}</p>
        </div>
      );
    } else if (raw.type === "file") {
      return (
        <label htmlFor={`select-${raw.key}`}>
          <Input
            className="d-none"
            id={`select-${raw.key}`}
            name={raw.key}
            placeholder={raw.key}
            type="file"
            onChange={(e) => {
              apiDetails.data.data[indexVal].body.requireParams.raw[index].value = e.target.files[0];
              setApiDetails({
                ...apiDetails,
                apiDetails
              });
            }}
          />
          <p className="form-control d-flex align-items-center justify-content-center">{raw?.value ? raw?.value.name : "Choose File"}</p>
        </label>
      );
    }
    if (raw.type === "arrayOfNumber") {
      return (
        <Input
          id={raw.key}
          name={raw.key}
          placeholder={raw.key}
          type="textarea"
          value={raw?.value || ""}
          onChange={(e) => {
            const value = e.target.value;
            if (validateArrayOfNumbers(value)) {
              apiDetails.data.data[indexVal].body.requireParams.raw[index].value = value;
              raw.errorReqire = false;
            } else {
              raw.errorReqire = true;
            }
            setApiDetails({
              ...apiDetails,
              apiDetails
            });
          }}
        />
      );
    } else if (raw.type === "arrayOfString") {
      return (
        <Input
          id={raw.key}
          name={raw.key}
          placeholder={raw.key}
          type="textarea"
          value={raw?.value || ""}
          onChange={(e) => {
            const value = e.target.value;
            if (validateArrayOfStrings(value)) {
              apiDetails.data.data[indexVal].body.requireParams.raw[index].value = value;
              raw.errorReqire = false;
            } else {
              raw.errorReqire = true;
            }
            setApiDetails({
              ...apiDetails,
              apiDetails
            });
          }}
        />
      );
    } else {
      return (
        <Input
          id={`${activeAPICall.name}${activeAPICall?.body?.requireParams.mode}${raw.key}`}
          name={raw.key}
          placeholder={raw.key}
          type={raw?.type}
          value={raw?.value || ""}
          onChange={(e) => {
            apiDetails.data.data[indexVal].body.requireParams.raw[index].value = e.target.value;
            apiDetails.data.data[indexVal].body.requireParams.raw[index].errorReqire = false;
            setApiDetails({
              ...apiDetails,
              apiDetails
            });
          }}
          invalid={raw.errorReqire}
        />
      );
    }
  };

  const renderOptionalInput = (raw, index) => {
    if (raw.type === "arrayOfNumber") {
      return (
        <Input
          id={raw.key}
          name={raw.key}
          placeholder={raw.key}
          type="textarea"
          value={raw?.value || ""}
          onChange={(e) => {
            const value = e.target.value;
            if (validateArrayOfNumbers(value)) {
              apiDetails.data.data[indexVal].body.requireParams.raw[index].value = value;
              raw.errorReqire = false;
            } else {
              raw.errorReqire = true;
            }
            setApiDetails({
              ...apiDetails,
              apiDetails
            });
          }}
        />
      );
    } else if (raw.type === "arrayOfString") {
      return (
        <Input
          id={raw.key}
          name={raw.key}
          placeholder={raw.key}
          type="textarea"
          value={raw?.value || ""}
          onChange={(e) => {
            const value = e.target.value;
            if (validateArrayOfStrings(value)) {
              apiDetails.data.data[indexVal].body.requireParams.raw[index].value = value;
              raw.errorReqire = false;
            } else {
              raw.errorReqire = true;
            }
            setApiDetails({
              ...apiDetails,
              apiDetails
            });
          }}
        />
      );
    } else if (raw.type === "file") {
      return (
        <label htmlFor={`select-${raw.key}`}>
          <Input
            className="d-none"
            id={`select-${raw.key}`}
            name={raw.key}
            placeholder={raw.key}
            type="file"
            onChange={(e) => {
              apiDetails.data.data[indexVal].body.optinalParams.raw[index].value = e.target.files[0];
              setApiDetails({
                ...apiDetails,
                apiDetails
              });
            }}
          />

          <p className="form-control d-flex align-items-center justify-content-center">{raw?.value ? raw?.value.name : "Choose File"}</p>
        </label>
      );
    } else {
      return (
        <Input
          id={`${activeAPICall.name}${activeAPICall?.body?.optinalParams.mode}${raw.key}`}
          name={raw.key}
          placeholder={raw.key}
          type={raw?.type || "text"}
          value={raw?.value || ""}
          onChange={(e) => {
            apiDetails.data.data[indexVal].body.optinalParams.raw[index].value = e.target.value;

            setApiDetails({
              ...apiDetails,
              apiDetails
            });
          }}
          invalid={raw.errorReqire}
        />
      );
    }
  };

  return (
    <div>
      <UncontrolledAccordion defaultOpen="1">
        <AccordionItem>
          <AccordionHeader onClick={() => setShowAccordian(!showAccordian)} targetId="1">
            HEADER PARAMETER
          </AccordionHeader>
          <AccordionItem accordionId="1" className={`accordion-collapse collapse ${showAccordian ? "show" : ""}`}>
            {activeAPICall?.header?.map((header: any, index) => {
              let value = "";
              if (header?.key === "secret-key") {
                value = activeAPICall?.secretKey;
              } else if (header?.key === "client-id") {
                value = clientId;
              } else {
                value = header.value;
              }

              return (
                <FormGroup key={index}>
                  <Label for={header?.key}>{header?.key}</Label>
                  <div className="position-relative">
                    <Input
                      id={header?.key}
                      name={header?.key}
                      placeholder={header?.key}
                      type={header?.type || "text"}
                      value={header?.value || value || ""}
                      onChange={(e) => {
                        apiDetails.data.data[indexVal].header[index].value = e.target.value;

                        setApiDetails({
                          ...apiDetails,
                          apiDetails
                        });
                      }}
                    />
                  </div>
                </FormGroup>
              );
            })}
          </AccordionItem>
        </AccordionItem>
        {multipleStamp.length === 0 ? (
          Array.isArray(activeAPICall?.body?.requireParams?.raw) &&
          activeAPICall?.body?.requireParams?.raw.length > 0 && (
            <AccordionItem>
              <AccordionHeader targetId="2" onClick={() => setShowAccordian1(!showAccordian1)}>
                REQUIRED PARAMETER
              </AccordionHeader>
              <AccordionItem className={`accordion-collapse collapse ${showAccordian1 ? "show" : ""}`} accordionId="2">
                {Array.isArray(activeAPICall?.body?.requireParams?.raw) &&
                  activeAPICall?.body?.requireParams?.raw?.map((raw: any, index) => {
                    return (
                      <FormGroup key={index}>
                        <Label for={raw.key}>
                          {raw?.key} {(raw?.type === "arrayOfNumber" || raw?.type === "arrayOfString") && `- Enter ${raw?.key} with comma separated`}
                        </Label>
                        <div className="position-relative">{renderRequiredInput(raw, index)}</div>
                      </FormGroup>
                    );
                  })}
              </AccordionItem>
            </AccordionItem>
          )
        ) : (
          <AccordionItem>
            <AccordionHeader targetId="2" onClick={() => setShowAccordian1(!showAccordian1)}>
              REQUIRED PARAMETER
            </AccordionHeader>
            <AccordionItem className={`accordion-collapse collapse ${showAccordian1 ? "show" : ""}`} accordionId="2">
              {multipleStamp.map((raw: any, index) => {
                return (
                  <>
                    <FormGroup key={index}>
                      <Label for={raw.filename}>{Object.keys(raw)[0]}</Label>
                      <div className="position-relative">
                        <Input
                          id={`${activeAPICall.name}${activeAPICall?.body?.requireParams.mode}${raw.key}`}
                          name={raw.key}
                          placeholder={raw.key}
                          type={raw?.type || "text"}
                          value={raw?.filename || ""}
                          invalid={raw.errorReqire}
                        />
                      </div>
                    </FormGroup>
                    <FormGroup key={index}>
                      <Label for={raw.filename}>{Object.keys(raw)[1]}</Label>
                      <div className="position-relative">
                        <Input
                          id={`${activeAPICall.name}${activeAPICall?.body?.requireParams.mode}${raw.key}`}
                          name={raw.key}
                          placeholder={raw.key}
                          type={raw?.type || "text"}
                          value={raw?.hash || ""}
                          invalid={raw.errorReqire}
                        />
                      </div>
                    </FormGroup>
                  </>
                );
              })}
            </AccordionItem>
          </AccordionItem>
        )}

        {Array.isArray(activeAPICall?.body?.optinalParams?.raw) && activeAPICall?.body?.optinalParams?.raw.length > 0 && (
          <AccordionItem>
            <AccordionHeader targetId="3" onClick={() => setShowAccordian2(!showAccordian2)}>
              OPTIONAL PARAMETER
            </AccordionHeader>
            <AccordionItem className={`accordion-collapse collapse ${showAccordian2 ? "show" : ""}`} accordionId="3">
              {Array.isArray(activeAPICall?.body?.optinalParams?.raw) &&
                activeAPICall?.body?.optinalParams?.raw?.map((raw: any, index) => {
                  return (
                    <FormGroup key={index}>
                      <Label for={raw.key}>{raw?.key}</Label>
                      <div className="position-relative">{renderOptionalInput(raw, index)}</div>
                    </FormGroup>
                  );
                })}
            </AccordionItem>
          </AccordionItem>
        )}
      </UncontrolledAccordion>
    </div>
  );
};

export default AccordianWrap;
