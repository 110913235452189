import { faAngleRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  getPeerListAPI,
  IgetPeerListAPIResData
} from 'actions/peerListAPI'
import BadgeBlock from 'components/badgeBlocks/BadgeBlock'
import LineComponent from 'components/lineComponent/LineComponent'
import LoadingSpinner from 'components/loader/LoadingSpinner'
import { NoFoundDiv } from 'components/notFoundPages'
import NoMoreData from 'components/notFoundPages/NoMoreData'
import { memo, useCallback, useEffect, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { setPeerData } from 'store/peerData/action'
import { IRootReducer } from 'store/root-reducer'
import { useWebsocket } from 'utils/WebSocketProvider'
import CreatePeerNodeModal from '../createPeer/CreatePeerModal'
import ViewPeerNodeModal from '../createPeer/ViewPeerNodeDetails'
const AngleRight = <FontAwesomeIcon icon={faAngleRight} />
interface IpeerSocket {
  peerName: string
  status: number
  peerId: string
  module: string
}
const PeerManagement = () => {
  const socket = useWebsocket()
  const { user } = useSelector(
    (state: IRootReducer) => state.userDetails
  )
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [modal, setModal] = useState(false)
  const [viewModal, setViewModal] = useState(false)
  const peerReducer = useSelector(
    (state: IRootReducer) => state.peerReducer
  )
  const [peerList, setPeerList] = useState<IgetPeerListAPIResData[]>(
    peerReducer?.data?.length > 0 ? peerReducer?.data : []
  )
  const [historyLoading, setHistoryLoading] = useState(false)
  const [peerDetailsState, setPeerDetailsState] = useState<string>()
  const [paginationConfig, setPaginationConfig] = useState({
    limit: 6,
    page: 1,
    totalCount: 0
  })

  const getPeerList = useCallback(
    (create?: boolean) => {
      setHistoryLoading(true)
      let params: any = {}
      if (create) {
        setPaginationConfig({
          ...paginationConfig,
          limit: 6,
          page: 1
        })
        params = { limit: 6, page: 1 }
      } else {
        params = {
          limit: paginationConfig.limit,
          page: paginationConfig.page
        }
      }
      getPeerListAPI(params)
        .then((res) => {
          if (res.status === 200) {
            setPaginationConfig({
              ...paginationConfig,
              totalCount: res.data.count
            })
            if (create) {
              setPeerList(res?.data?.data)
              // @ts-ignore
              dispatch(setPeerData(res?.data?.data))
            } else {
              setPeerList([...peerList, ...res.data.data])
              // @ts-ignore
              dispatch(setPeerData([...peerList, ...res.data.data]))
            }
          }
          setHistoryLoading(false)
        })
        .catch((err) => setHistoryLoading(false))
    },
    [paginationConfig, peerList]
  )

  const incrementPage = useCallback(() => {
    setPaginationConfig({
      ...paginationConfig,
      page: paginationConfig.page + 1
    })
  }, [paginationConfig])

  const handleSocketData = (params: IpeerSocket) => {
    setPeerList((prevState) => {
      const newState: any[] = prevState.map((data) => {
        if (data.id === params.peerId) {
          return { ...data, status: params.status }
        }
        return data
      })
      return newState
    })
  }

  useEffect(() => {
    if (user?.id) {
      socket?.on(`Peer_create_${user?.id}`, (data: IpeerSocket) => {
        handleSocketData(data)
      })
    }
  }, [socket, user?.id])


  return (
    <div className="backdrop-block">
      <div className="title">
        <h5 className="c-tx-primary f-600 mb-10">
          Peer Management{' '}
          {paginationConfig?.totalCount > 0 && (
            <span className="c-tx-ninth f-400">
              {' '}
              ({paginationConfig?.totalCount})
            </span>
          )}
        </h5>
      </div>
      <ul
        className="wrapper-block peer-management"
        id="peer-Management"
      >
        <InfiniteScroll
          dataLength={peerReducer?.data?.length}
          next={incrementPage}
          hasMore={
            paginationConfig?.totalCount > peerReducer?.data?.length
          }
          loader={<LoadingSpinner size="sm" />}
          scrollableTarget="peer-Management"
          endMessage={peerList.length > 0 && <NoMoreData />}
        >
          {peerReducer?.data?.length > 0 ? (
            <>
              {peerReducer?.data?.map((data: any, i: number) => (
                <li className="element" key={i}>
                  <div className="block-1">
                    <p className="c-tx-primary f-700 first-title">
                      {data?.name}
                    </p>
                    <p className="c-tx-ninth">
                      {data?.peerRegionId?.name}
                    </p>
                  </div>
                  <div className="block-2 align-items-center">
                    {Number(data?.status) === 1 && (
                      <div>
                        <BadgeBlock name="LOCKED" theme="dark" />
                      </div>
                    )}
                    {Number(data?.status) === 3 && (
                      <div>
                        <BadgeBlock name="In-Progress" theme="dark" />
                      </div>
                    )}
                    {Number(data?.status) === 0 && (
                      <div>
                        <BadgeBlock name="Pending" theme="dark" />
                      </div>
                    )}
                    {Number(data?.status) === 2 && (
                      <div>
                        <BadgeBlock name="Rejected" theme="dark" />
                      </div>
                    )}
                    <div
                      className="angle-block cursor-pointer"
                      onClick={() => {
                        setPeerDetailsState(data.id)

                        navigate('/universe/overview', {
                          state: { ids: data.id }
                        })
                      }}
                    >
                      {AngleRight}
                    </div>
                  </div>
                </li>
              ))}
            </>
          ) : (
            <NoFoundDiv />
          )}
        </InfiniteScroll>
        {historyLoading ? <LoadingSpinner size="sm" /> : null}
      </ul>
      <LineComponent number={peerList?.length} />
      <div className="mt-20 link-wrapper">
        <Link
          to={'#'}
          className="custom-link ps-0"
          onClick={() => setModal(true)}
        >
          Create Peer Node
        </Link>
      </div>
      <CreatePeerNodeModal
        setModal={setModal}
        modal={modal}
        getPeerList={getPeerList}
      />
      <ViewPeerNodeModal
        modal={viewModal}
        setModal={setViewModal}
        data={peerDetailsState}
      />
    </div>
  )
}

export default memo(PeerManagement)
