import { getSmartContractListAPIData } from "actions/getSmartContractListAPI";
import BadgeBlock from "components/badgeBlocks/BadgeBlock";
import { FC, useState } from "react";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import { SmartContractListStatusEnum } from "utils/constants";
import { makeFullS3ObjectUrl } from "utils/makeFullS3ObjectUrl";
const logo = makeFullS3ObjectUrl("zip.png");

interface IListingComponent {
  data: getSmartContractListAPIData;
  setData: React.Dispatch<React.SetStateAction<getSmartContractListAPIData | undefined>>;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const UploadListingComponent: FC<IListingComponent> = ({ data, setData, setOpen }) => {
  const [drp, setdrp] = useState(false);

  const toggle = () => {
    setdrp(!drp);
  };

  const handleStatus = (status: number) => {
    if (status === SmartContractListStatusEnum.INPROGRESS) {
      return "In-Progress";
    } else if (status === SmartContractListStatusEnum.REJECTED) {
      return "Rejected";
    } else if (status === SmartContractListStatusEnum.ACCEPTED) {
      return "Approved";
    } else {
      return "Pending";
    }
  };

  return (
    <div className="element">
      <div className="templateblock">
        <div className="block-1">
          <div className="badge-bl">
            <BadgeBlock theme="dark" name="Smart Contract" />
          </div>
          <img src={logo} className="img-fluid" alt="zip" />
        </div>
        <div className="block-2">
          <div className="bl_1 text-truncate">
            <h6 className="c-tx-primary f-700 mb-0 text-truncate">{data?.name}</h6>
            <p className="c-tx-ninth">{handleStatus(data?.status)}</p>
          </div>
          {data?.status === 1 && (
            <div className="bl_2">
              <div className="align-items-center">
                <Dropdown isOpen={drp} toggle={toggle}>
                  <DropdownToggle className="tlg" tag={"a"}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="4" viewBox="0 0 17 4">
                      <path
                        id="Path_330"
                        data-name="Path 330"
                        d="M8.624,6.781a1.953,1.953,0,0,0-1.906-2,1.953,1.953,0,0,0-1.906,2,1.953,1.953,0,0,0,1.906,2A1.953,1.953,0,0,0,8.624,6.781Zm4.689-2a1.953,1.953,0,0,0-1.906,2,1.908,1.908,0,1,0,3.811,0A1.953,1.953,0,0,0,13.313,4.781Zm-13.189,0a1.953,1.953,0,0,0-1.906,2,1.953,1.953,0,0,0,1.906,2,1.953,1.953,0,0,0,1.906-2A1.953,1.953,0,0,0,.124,4.781Z"
                        transform="translate(1.781 -4.781)"
                        fill="#868686"
                      />
                    </svg>
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem
                      onClick={() => {
                        setData(data);
                        setOpen(true);
                      }}>
                      Install
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default UploadListingComponent;
