import { getTransactionCountGraphApiInfo } from 'actions/universeActions/myBlockChain/blockProcessAPI'
import { HighchartsReact } from 'highcharts-react-official'
import Highcharts from 'highcharts/highstock'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Col, Row } from 'reactstrap'
import { IRootReducer } from 'store/root-reducer'
import { getGraphOptions } from 'utils/graphs/getOptions'
import CommonGraphs from 'utils/graphs/graphCommonFunctions'
import '../../../../src/styles/pages/myblockchain/myblockchain.scss'

const TransactionCountGraph = () => {
  // states
  const [profile, setProfile] = useState<any>([])
  const [storeChannelId, setStoreChannelId] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [
    storeSelectedTimeAndItsValue,
    setStoreSelectedTimeAndItsValue
  ] = useState<any>({})
  const [transactionCountGraphData, setTransactionCountGraphData] =
    useState<any>([])

  //  getting data from reducer
  const peerListing = useSelector(
    (state: IRootReducer) => state.peerReducer.data
  )
  const getChannelDataFromRedux = useSelector(
    (state: IRootReducer) =>
      state?.channelDataReducer?.storeChannelData
  )

  const handleSelectedTime = (startTime: number) => {
    setStoreSelectedTimeAndItsValue({
      startTime,
      endTime: Math.floor(Date.now() - 24 * 60 * 60 * 1000) / 1000
    })
  }

  useEffect(() => {
    if (getChannelDataFromRedux?.length > 0) {
      setStoreChannelId({
        label: getChannelDataFromRedux?.[0].channelName,
        value: getChannelDataFromRedux?.[0]?.id
      })
    }
  }, [getChannelDataFromRedux])

  useEffect(() => {
    if (
      peerListing?.length > 0 &&
      getChannelDataFromRedux.length > 0
    ) {
      setIsLoading(true)
      let param: any = {}
      param = {
        storeSelectedTimeAndItsValue,
        storeChannelId: storeChannelId,
        storePeerId: profile.value
      }
      getTransactionCountGraphApiInfo(param)
        .then((response: any) => {
          setTransactionCountGraphData(response?.data)
          setIsLoading(false)
        })
        .catch((err: any) => {
          setIsLoading(false)
        })
    }
  }, [storeSelectedTimeAndItsValue, profile, storeChannelId])

  //  getting options from common
  const optionsData: any = getGraphOptions(transactionCountGraphData)

  return (
    <>
      <div
        className="backdrop-block"
        style={{ minHeight: '495px', maxHeight: '495px' }}
      >
        <>
          <CommonGraphs
            handleSelectedTime={handleSelectedTime}
            isLoading={isLoading}
            setProfile={setProfile}
            profile={profile}
            storeChannelId={storeChannelId}
            setStoreChannelId={setStoreChannelId}
            graphName="Transaction Count"
          />
        </>

        {transactionCountGraphData.length > 0 && !isLoading ? (
          <Row>
            <Col
              md="12"
              style={{
                visibility: `${isLoading ? 'hidden' : 'visible'}`
              }}
            >
              <div className="hide-highcharts-tag">
                <HighchartsReact
                  highcharts={Highcharts}
                  options={optionsData}
                />
              </div>
            </Col>
          </Row>
        ) : (
          transactionCountGraphData.length === 0 &&
          !isLoading && (
            <p
              style={{
                marginTop: '20%',
                marginLeft: '40%'
              }}
            >
              NO DATA FOUND
            </p>
          )
        )}
      </div>
    </>
  )
}

export default TransactionCountGraph
