// @ts-nocheck
const ThankYou = (props: any) => {
  return (
    <>
      <div className="p-4">
        <div className="user-management-popup confirm-popup-wrapper position-relative">
          <div className="content-wrapper flex-column ">
            <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100">
              <path
                id="Path_123"
                data-name="Path 123"
                d="M98,48a50,50,0,1,0,50,50A49.992,49.992,0,0,0,98,48Zm-7.716,67.716a3.316,3.316,0,0,1-2.115,1.058,3.466,3.466,0,0,1-2.139-1.082L72.567,102.231l4.279-4.279L88.192,109.3l30-30.216,4.207,4.351Z"
                transform="translate(-48 -48)"
                fill="#007de4"
              />
            </svg>
            <h1 className="h1 f-600 mt-20">Thank you</h1>
            <p className="c-tx-ninth text-center mb-20 mt-20">
              We received your request and our team will update you within the next 48 business hours.
              <br /> mean time you can check our
              <a href=" https://edexa.network" target="_blank" rel="noreferrer" className="custom-light-link text-lowercase f-700 c-tx-secondary">
                {" "}
                website
              </a>{" "}
              and
              <a href="https://edexa.network/terms-and-conditions" className="custom-light-link c-tx-secondary text-lowercase f-700" target="_blank" rel="noreferrer">
                {" "}
                terms and conditions{" "}
              </a>
              to get verified.
            </p>
            <p className="c-tx-ninth text-center mb-20 mt-20">
              {" "}
              for further inquiries, please contact{" "}
              <a href="mailto:helpdesk@edexa.com?subject=Support Request" target="_blank" rel="noreferrer" className="custom-light-link c-tx-secondary text-lowercase f-700">
                {" "}
                helpdesk@edexa.com
              </a>
              .
            </p>
          </div>
        </div>
      </div>
      {/* <div className="custom-card-wrapper ">
        <div className="user-management-popup confirm-popup-wrapper position-relative  ">
          <div className="content-wrapper flex-column ">
            <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100">
              <path
                id="Path_123"
                data-name="Path 123"
                d="M98,48a50,50,0,1,0,50,50A49.992,49.992,0,0,0,98,48Zm-7.716,67.716a3.316,3.316,0,0,1-2.115,1.058,3.466,3.466,0,0,1-2.139-1.082L72.567,102.231l4.279-4.279L88.192,109.3l30-30.216,4.207,4.351Z"
                transform="translate(-48 -48)"
                fill="#007de4"
              />
            </svg>
            <div className="content-title">
              <h1 className="h1">Thank you</h1>
            </div>

            <div className="content">
              <h5 className="c-tx-ninth text-center f-400 mb-0">
                We received your request and our team will update you within the next 48 business hours.
                <br /> mean time you can check our
                <a href=" https://edexa.network" target="_blank" rel="noreferrer" className="custom-light-link text-lowercase f-700 c-tx-secondary">
                  {" "}
                  website
                </a>{" "}
                and
                <a href="https://edexa.network/terms-and-conditions" className="custom-light-link c-tx-secondary text-lowercase f-700" target="_blank" rel="noreferrer">
                  {" "}
                  terms and conditions{" "}
                </a>
                to get verified.
              </h5>
            </div>
          </div>
          <div className="footer-bl">
            <p className="mb-0 c-tx-ninth f-700">
              for further inquiries, please contact{" "}
              <a href="mailto:helpdesk@edexa.com?subject=Support Request" target="_blank" rel="noreferrer" className="custom-light-link c-tx-secondary text-lowercase f-700">
                {" "}
                helpdesk@edexa.com
              </a>
              .
            </p>
          </div>
        </div>
      </div> */}
    </>
  );
};
export default ThankYou;
