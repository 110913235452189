import { IgetChannelListingAPIResData } from "actions/channelListingAPI";
import { ParticipantsChannelTableAPI } from "actions/ParticipantsChannelTableAPI";
import { NoFoundData } from "components/notFoundPages";
import { useDebouncedEffect } from "hooks/UseDebouncedEffect";
import Pagination from "rc-pagination";
import "rc-pagination/assets/index.css";
import { memo, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Input, Row, Table } from "reactstrap";
import { setLoading } from "store/loader/action";
import { IRootReducer } from "store/root-reducer";
import { useWebsocket } from "utils/WebSocketProvider";
const ChannelManagementTableHeader = ["NO.", "NAME", "PARTICIPANTS", "CHANNEL HEIGHT"];
export interface IPaginationConfig {
  search: string;
  page: number;
  limit: number;
}

const ParticipantsChannelTable = () => {
  const dispatch = useDispatch();
  const socket = useWebsocket();
  const { user } = useSelector((state: IRootReducer) => state.userDetails);
  const [search, setSearch] = useState("");
  const [totalCount, setTotalCount] = useState(0);
  const [channelData, setChannelData] = useState<IgetChannelListingAPIResData[]>([]);
  const [paginationConfig, setPaginationConfig] = useState({
    search: "",
    page: 1,
    limit: 10
  });

  const GetChannelListingAPI = useCallback(() => {
    dispatch(setLoading(true));
    let params: any = {
      limit: paginationConfig.limit,
      page: paginationConfig.page
    };
    if (paginationConfig.search) {
      params.search = paginationConfig.search;
    }
    ParticipantsChannelTableAPI(params).then((res: any) => {
      if (res.status === 200) {
        setTotalCount(res?.data?.count);
        setChannelData(res?.data?.data);
      }
      dispatch(setLoading(false));
    });
  }, [dispatch, paginationConfig.limit, paginationConfig.page, paginationConfig.search]);

  useEffect(() => {
    GetChannelListingAPI();
    // eslint-disable-next-line
  }, [paginationConfig.page, paginationConfig.search]);

  const handleSerach = useCallback(
    (str: string) => {
      setPaginationConfig({ ...paginationConfig, search: str });
    },
    [paginationConfig]
  );

  useEffect(() => {
    if (user?.id) {
      socket?.on(`Channel_shared_${user?.id}`, (data: any) => {
        if (paginationConfig.page === 1) {
          GetChannelListingAPI();
        } else {
          setPaginationConfig({ ...paginationConfig, page: 1 });
        }
      });
    } // eslint-disable-next-line
  }, [user?.id]);

  useDebouncedEffect(() => handleSerach(search), [search], 500);

  return (
    <div className="backdrop-block">
      <div className="channel-wrapper">
        <div className="title d-sm-flex justify-content-between">
          <h5 className="c-tx-primary f-600">Shared Channels</h5>
          <Input className="select-component" value={search} placeholder="Search..." onChange={(e) => setSearch(e.target.value)} type="email" />
        </div>
        <div className="tbb">
          <div className="ct-table">
            <Table className="position-relative">
              <thead className="table-sticky">
                <tr>
                  {ChannelManagementTableHeader.map((data: string, i: number) => (
                    <th key={i}>{data}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {channelData.length > 0 ? (
                  channelData.map((data, i: number) => (
                    <tr key={i}>
                      <td>{paginationConfig.limit * paginationConfig.page + (i + 1) - paginationConfig.limit}</td>
                      <td>{data?.channelName}</td>
                      <td>{data?.totalParticipate}</td>
                      <td>{data?.channelHeight}</td>
                    </tr>
                  ))
                ) : (
                  <NoFoundData columnCount={ChannelManagementTableHeader.length} />
                )}
              </tbody>
            </Table>
          </div>
          <div className="pagination-wrap">
            <Row>
              <Col md="12" className="d-flex justify-content-end">
                <Pagination
                  pageSize={paginationConfig.limit}
                  onChange={(e: any) =>
                    setPaginationConfig({
                      ...paginationConfig,
                      page: e
                    })
                  }
                  current={paginationConfig.page}
                  total={totalCount}
                />
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(ParticipantsChannelTable);
