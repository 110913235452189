import { faCopy, faStar as faStarEmpty } from "@fortawesome/free-regular-svg-icons";
import { faCheck, faStar, faStarHalfAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ConfirmationModal } from "components/models";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem, Button, Col, Container, FormGroup, Input, InputGroup, InputGroupText, Label, Row } from "reactstrap";
import { setConfirmationModalVisibility } from "store/confirmationModule/action";
import { setLoading } from "store/loader/action";
import { IRootReducer } from "store/root-reducer";
import LeftArrow from "utils/common/helper/LeftArrow";
import { getAPIDataById } from "utils/common/helper/getAPIDataById";
import { subscribeFromAPI, unsubscribeFromAPI } from "utils/common/helper/unSubscribe";
import { numberFormatter, toastSuccess } from "utils/commonFunctions";
import { applicationRating, defualtAppLogo } from "utils/constants";
import "../../../styles/apiDetails/apiDetails.scss";

const Filledstar = <FontAwesomeIcon icon={faStar} />;
const Check = <FontAwesomeIcon icon={faCheck} />;
const Copy = <FontAwesomeIcon icon={faCopy} />;
const halfStar = <FontAwesomeIcon icon={faStarHalfAlt} />;
const faStarEmptyIcon = <FontAwesomeIcon icon={faStarEmpty} />;

const Apidetails = () => {
  const { isScrolling } = useSelector((state: IRootReducer) => state.scrollingReducer);
  const { userId } = useSelector((state: IRootReducer) => state.userDetails?.user) || {};
  const [apiDetails, setApiDetails] = useState({
    data: {
      id: "",
      name: "",
      description: "",
      features: [],
      app_url: "",
      ErrorTypes: [],
      HTTPstatus: [],
      logo: "",
      logoIcon: "",
      ErrorDescription: "",
      shortDescription: "",
      subtitle: "",
      isAvailable: 0,
      document_url: "",
      secretKey: "",
      redirect_url: "",
      inUse: false
    },
    subscribed: 0
  });

  const [copied, setCopied] = useState("Copy");
  const params: any = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [imgError, setImgError] = useState(false);

  // change copied text to default after 3 sec from user's click
  useEffect(() => {
    if (copied === "Copied") {
      setTimeout(() => {
        setCopied("Copy");
      }, 3000);
    }
    return () => {
      // setCopied("Copy Link");
    };
  }, [copied]);

  useEffect(() => {
    dispatch(setLoading(true));
    getAPIDataById(params.id).then((response: any) => {
      dispatch(setLoading(false));
      setApiDetails({
        data: response.data,
        subscribed: response.data.inUse
      });
    });
  }, [dispatch, params.id]);
  const [docUrlCopied, setDocUrlCopied] = useState(false);
  const handleCopyLink = (text?: string, field?: string) => {
    if (field === "docUrl" && text) {
      window.navigator.clipboard.writeText(text).then(() => {
        setDocUrlCopied(true);
        toastSuccess("Document url copied to clipboard");
        setTimeout(() => {
          setDocUrlCopied(false);
        }, 2000);
      });
      return null;
    }
    if (text && field) {
      window.navigator.clipboard.writeText(text).then(() => {
        field === "clientId" ? toastSuccess("Client Id copied to clipboard") : toastSuccess("Base url copied to clipboard");
      });
      return;
    }
    window.navigator.clipboard.writeText(apiDetails.data.app_url).then(() => setCopied("Copied"));
  };

  const subscibeAPIToggle = () => {
    function handleSubscrbebuttion(response: any, subscribed: number) {
      dispatch(setConfirmationModalVisibility(false));
      setApiDetails({
        ...apiDetails,
        subscribed,
        data: {
          ...apiDetails.data,
          secretKey: response.data ? response.data.secretKey : ""
        }
      });
      toastSuccess(response.message);
      dispatch(setLoading(false));
    }

    let subscribed = apiDetails.subscribed;
    dispatch(setLoading(true));
    if (subscribed) {
      // unsubscribe
      unsubscribeFromAPI({
        applicationId: parseInt(apiDetails.data.id),
        subscribe: 0
      }).then((response) => {
        handleSubscrbebuttion(response, subscribed ? 1 : 0);
      });
    } else {
      // subscribe
      subscribeFromAPI({
        applicationId: +apiDetails.data.id,
        subscribe: 1
      }).then((response) => {
        handleSubscrbebuttion(response, subscribed ? 1 : 0);
      });
    }
  };
  const goBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const handleImg = () => setImgError(true);

  return (
    <div className="api-detail-wrapper">
      <div className={`custom-stick ${isScrolling ? "" : "top-0"}`}>
        <div className="breadcum-block">
          <Container>
            <div className="breadcum-wrapper">
              <span className="backarrow cursor-pointer d-inline-flex d-md-none" onClick={() => goBack()}>
                <LeftArrow />
              </span>
              <Breadcrumb className="mb-0">
                <BreadcrumbItem>
                  <Link to="/apis">Api's</Link>
                </BreadcrumbItem>
                <BreadcrumbItem active>API Details</BreadcrumbItem>
              </Breadcrumb>
            </div>
          </Container>
        </div>
        <div className="api-details-header">
          <Container>
            <Row className="align-items-center">
              <Col md="12" lg="6" className="position-relative">
                <span className="backarrow cursor-pointer d-none d-md-flex" onClick={() => goBack()}>
                  <LeftArrow />
                </span>
                <h1 className="h2 c-tx-third mb-0">
                  API Details of <span className="f-600"> {apiDetails.data.name} </span>{" "}
                </h1>
              </Col>
              <Col md="12" lg="6" className="justify-content-lg-end">
                <div className="api-detail-btn-list btn-custom-group">
                  <Button className="custom-primary-outline" onClick={() => navigate(`/apis/explorer/${apiDetails?.data?.id}`)}>
                    Api Explorer
                  </Button>
                  {/* <Button
                    className={`custom-primary${apiDetails?.subscribed === 1 ? "-outline" : ""}`}
                    onClick={() => {
                      // if user is subscribed then show him confirmation before unsubscribing
                      if (apiDetails?.subscribed === 1) {
                        dispatch(setConfirmationModalVisibility(true));
                        return;
                      }
                      subscibeAPIToggle();
                    }}>
                    {`${apiDetails?.subscribed === 1 ? "UNSUBSCRIBE" : "SUBSCRIBE"}`}
                  </Button> */}
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <div className="details-wrapper">
        <Container>
          <Row>
            <Col md="3">
              <div className={`image-wrapper ${isScrolling ? "" : "is-stickey"}`}>
                <div className="img-block position-relative d-flex w-100 h-100 align-items-center justify-content-center">
                  {(apiDetails?.data?.logoIcon || apiDetails.data.logo) && (
                    <img
                      src={imgError ? defualtAppLogo : apiDetails?.data?.logoIcon ? apiDetails?.data?.logoIcon : apiDetails.data.logo}
                      alt="app_logo"
                      className="img-fluid"
                      style={{ height: 150 }}
                      onError={handleImg}
                    />
                  )}
                  <div className="custum-badge">available</div>
                </div>
              </div>
            </Col>
            <Col md="9">
              <div className="content-block-wrapper mt-4 mt-md-0">
                <div className="inner-title">
                  <Row className="align-items-center">
                    <Col md="6">
                      <h3 className="mb-0 c-tx-third f-700">About</h3>
                    </Col>
                    <Col md="6">
                      <div className="d-sm-flex my-1 justify-content-md-end align-items-center">
                        {apiDetails.data?.isAvailable ? (
                          <ul className="d-inline-sm-flex d-flex star-list">
                            <li>
                              <p className="small c-tx-third mb-0">{Filledstar}</p>
                            </li>
                            <li>
                              <p className="small c-tx-third mb-0">{Filledstar}</p>
                            </li>
                            <li>
                              <p className="small c-tx-third mb-0">{Filledstar}</p>
                            </li>
                            <li>
                              <p className="small c-tx-third mb-0">{Filledstar}</p>
                            </li>
                            {applicationRating.find((item) => item.id === parseInt(params.id))?.rating === 5 ? (
                              <li>
                                <p className="small c-tx-third mb-0  icon-d">{Filledstar}</p>
                              </li>
                            ) : (
                              <li>
                                <p className="small c-tx-third mb-0  icon-d">{halfStar}</p>
                              </li>
                            )}
                          </ul>
                        ) : (
                          <ul className="d-inline-sm-flex d-flex star-list">
                            <li>
                              <p className="small c-tx-third mb-0">{faStarEmptyIcon}</p>
                            </li>
                            <li>
                              <p className="small c-tx-third mb-0">{faStarEmptyIcon}</p>
                            </li>
                            <li>
                              <p className="small c-tx-third mb-0">{faStarEmptyIcon}</p>
                            </li>
                            <li>
                              <p className="small c-tx-third mb-0">{faStarEmptyIcon}</p>
                            </li>
                            <li>
                              <p className="small c-tx-third mb-0  icon-d">{faStarEmptyIcon}</p>
                            </li>
                          </ul>
                        )}
                        <div className="inline-flex ms-sm-2 mt-2 mt-sm-0">
                          <p className="c-tx-third mb-0">
                            {" "}
                            ({apiDetails.data?.isAvailable ? applicationRating.find((item) => item.id === parseInt(params.id))?.reviewCount : 0}) | Subscribed User :{" "}
                            {apiDetails.data?.isAvailable ? numberFormatter(applicationRating.find((item) => item.id === parseInt(params.id))?.review || 2200, 1) : 0}
                          </p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
                <div className="divider"></div>
                <div className="content-bl">
                  <p className="c-tx-ninth">{apiDetails.data.description}</p>
                </div>
              </div>
              <div className="content-block-wrapper mt-4">
                <div className="inner-title">
                  <Row className="align-items-center">
                    <Col md="12">
                      <h3 className="c-tx-third text-capitalize f-700">Features</h3>
                    </Col>
                  </Row>
                </div>
                <div className="divider"></div>
                <div className="">
                  <Row>
                    <Col md="12" lg="6">
                      <ul className="point-list mt-0">
                        {apiDetails?.data?.features &&
                          apiDetails?.data?.features?.map((feature: any, index: number) => {
                            return (
                              <li key={index}>
                                <div className="icon">
                                  <p className="c-tx-tenth mb-0">{Check}</p>
                                </div>
                                <p className="c-tx-tenth mb-0">{feature}</p>
                              </li>
                            );
                          })}
                      </ul>
                    </Col>
                  </Row>
                </div>
              </div>
              <div className="mt-30 c-tx-ninth">
                <b className="post-api-color me-1">Note:</b>
                The API Playground serves as a testing ground for live APIs in Test Mode. API access keys are employed for edeXa SDKs or direct API calls, and you have the option to generate multiple
                access keys. Explore the{" "}
                <a href={`${process.env.REACT_APP_accountDomain}/api-keys`} target="_blank" rel="noreferrer" className="f-700 c-tx-forth">
                  Developer Settings
                </a>{" "}
                to generate new keys.
              </div>

              {/**
               * @api_credentials
               */}
              <div className="form-block">
                {apiDetails?.data?.inUse && (
                  <Row className="mt-5">
                    {/* client id */}
                    {/* {userId && (
                      <Col md="6">
                        <FormGroup>
                          <Label for="clientId">Client ID</Label>
                          <div
                            className="position-relative"
                            onClick={() => {
                              handleCopyLink(userId, "clientId");
                            }}>
                            <div className="copy-section prevent-select">
                              <div className="w-90 overflow-hidden text-truncate" style={{ width: "95%" }}>
                                {userId || ""}
                              </div>
                              <span style={{ color: "#0d6efd" }} data-name="userIdCopy">
                                {Copy}
                              </span>
                            </div>
                          </div>
                        </FormGroup>
                      </Col>
                    )} */}
                    {/* secret */}
                    {/* {apiDetails?.data?.secretKey && (
                      <Col md="6">
                        <FormGroup>
                          <Label for="secretKey">Secret Key</Label>
                          <div className="position-relative">
                            <Input
                              id="secretKey"
                              name="text"
                              placeholder="Secret Key"
                              // @ts-ignore
                              type={"text"}
                              value={apiDetails?.data?.secretKey || ""}
                            />
                            <div className="icon-absolute ">
                              <Link
                                to="/"
                                onClick={(event) => {
                                  event?.preventDefault();
                                  handleCopyLink(apiDetails?.data?.secretKey, "secretKey");
                                }}>
                                <span data-name="userIdCopy">{Copy}</span>
                              </Link>
                            </div>
                          </div>
                        </FormGroup>
                      </Col>
                    )} */}
                    {/**
                     * @document_url
                     */}

                    {apiDetails?.data?.document_url && (
                      <Col md="12">
                        <FormGroup>
                          <Label for="apiurl">API Document Url</Label>
                          <div className="position-relative">
                            <InputGroup>
                              <InputGroupText
                                style={{
                                  backgroundColor: "rgb(244 112 35)",
                                  color: "#fff",
                                  borderColor: "#dadce0",
                                  borderTopLeftRadius: "10px",
                                  borderBottomLeftRadius: "10px",
                                  fontSize: "14px"
                                }}>
                                Postman Url
                              </InputGroupText>
                              <Input
                                id="apiurl"
                                name="email"
                                placeholder="Api Document Url"
                                type="text"
                                value={apiDetails?.data?.document_url || ""}
                                disabled
                                className="pe-all"
                                style={{
                                  backgroundColor: "#fff",
                                  borderColor: "#dadce0"
                                }}
                              />
                            </InputGroup>
                            <div className="icon-absolute ">
                              <Link
                                to="/"
                                style={{ color: "#ff6c37" }}
                                onClick={(event) => {
                                  event?.preventDefault();
                                  handleCopyLink(apiDetails?.data?.document_url, "docUrl");
                                }}>
                                {!docUrlCopied ? "Copy" : "Copied"}
                              </Link>
                            </div>
                          </div>
                        </FormGroup>
                      </Col>
                    )}
                  </Row>
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="base-url-block">
        <Container>
          <div className="base-url-inner-block">
            <div className="block-1">
              <p className="text-white mb-0 text-uppercase">base Url: </p>
            </div>
            <div className="block-2">
              <p className="text-white mb-0 ">{apiDetails.data.redirect_url}</p>
              <Link className="copy-link" to="#" onClick={() => handleCopyLink(apiDetails.data.redirect_url, "baseUrl")}>
                {copied}
              </Link>
            </div>
          </div>
        </Container>
      </div>
      <div className="document-block common-pad">
        <Container>
          <Row className="align-items-center">
            <Col md="12">
              <h3 className="mb-0 c-tx-third f-700">Secure your documents long term with blockchain</h3>
              <div className="divider"></div>
              <div className="content my-0">
                <p className="c-tx-ninth mb-0">Secured File Save save your documents are safe for sure for long term. Your important documents are protected from changes and unwanted access.</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="Error-block common-pad">
        <Container>
          <Row className="align-items-center">
            <Col md="12">
              <h3 className="mb-0 c-tx-third f-700">Error & Code</h3>
              <div className="divider"></div>
              <div className="content my-0">
                <p className="c-tx-ninth mb-0">{apiDetails.data.ErrorDescription}</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="http-status-wrapper">
        <Container>
          <Row>
            <Col sm="12" md="7" lg="8" xl="8">
              <div className="inner-common-block mb-3 mb-md-0">
                <div className="status-header common-pad mb-0">
                  <p className="mb-0 c-tx-primary text-uppercase c-bg-sixth f-700">HTTP STATUS CODE SUMMARY</p>
                </div>
                <ul className="common-pad mb-0 code-list py-0">
                  {apiDetails.data.HTTPstatus.map(
                    (
                      status: {
                        message: string;
                        status_code: number;
                      },
                      index: number
                    ) => {
                      return (
                        <li key={index}>
                          <div className="block-1">
                            <p className="c-tx-forth mb-0 f-700">{status.status_code}</p>
                          </div>
                          <div className="block-2">
                            <p className="c-tx-eleven mb-0">{status.message}</p>
                          </div>
                        </li>
                      );
                    }
                  )}
                </ul>
              </div>
            </Col>
            <Col sm="12" md="5" lg="4" xl="4">
              <div className="inner-common-block error-block">
                <div className="error-header common-pad mb-0">
                  <p className="text-white mb-0 text-uppercase">Error Types : </p>
                </div>
                <div className="">
                  <ul className="common-pad mb-0 code-list py-0">
                    {apiDetails.data.ErrorTypes.map((error: string, index: number) => {
                      return (
                        <li key={index} className="border-color-primary">
                          <p className="c-tx-eleven mb-0 text-white">
                            <span className="c-tx-green">{error?.split("=")?.[1] ? error?.split("=")?.[0] : ""}</span>
                            {error?.split("=")?.[1] || error}
                          </p>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      {/* unsubscribe action confirmation */}
      <ConfirmationModal handleOnClickAction={subscibeAPIToggle} title="Are you sure you want to unsubscribe" />
    </div>
  );
};

export default Apidetails;
