import { TokenEngineFormAPI } from "actions/tokenEngineFormAPI";
import { ThankYou } from "components/ThankyouPage";
import { useFormik } from "formik";
import { FC, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Button, Col, FormGroup, Input, Label, Modal, Row, Spinner } from "reactstrap";

import { setLoading } from "store/loader/action";
import { toastSuccess } from "utils/commonFunctions";
import { TokenEngineFORMEnum } from "utils/constants";
import * as Yup from "yup";

interface ITokenEngineModal {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}
// This component hidden by Dinesh bhai beacuse of no need to Client
const TokenEngineModal: FC<ITokenEngineModal> = ({ isOpen, setIsOpen }) => {
  const disaptch = useDispatch();
  // eslint-disable-next-line
  const [validation, setValidation] = useState(false);
  const [sectionState, setSection] = useState<TokenEngineFORMEnum>(TokenEngineFORMEnum.INITSTATE);

  const formik = useFormik({
    initialValues: {
      chainCode: "",
      channel: "",
      tokenType: "",
      tokenName: "",
      totalSupply: 0,
      tokenUrl: "",
      symbol: ""
    },
    validationSchema: Yup.object({
      chainCode: Yup.string()
        .required("Chain Code is required")
        .matches(/^\S+$/, "Chain Code not allow to space")
        .min(3, "Chain Code must be at least 3 characters")
        .max(50, "Chain Code must be at most 50 characters"),
      channel: Yup.string()
        .required("Channel Name is required")
        .matches(/^\S+$/, "Channel Name not allow to space")
        .min(3, "Channel Name must be at least 3 characters")
        .max(50, "Channel Name must be at most 50 characters"),
      tokenType: Yup.string()
        .required("Token Type is required")
        .matches(/^\S+$/, "Token Type not allow to space")
        .min(3, "Token Type must be at least 3 characters")
        .max(50, "Token Type must be at most 50 characters"),
      tokenName: Yup.string().required("Token Name is required").min(3, "Token Name must be at least 3 characters").max(50, "Token Name must be at most 50 characters"),
      totalSupply: Yup.number().required("Total Supply is required").max(999999999999999999, "Total Supply must be less than or equal to 1000000000000000000").moreThan(0),
      tokenUrl: Yup.string().when("tokenType", (tokenType, field) => (tokenType === "erc721" ? field.required("Token Type is required") : field)),
      symbol: Yup.string().required("Symbol is required").matches(/^\S+$/, "Symbol not allow to space").min(3, "Symbol must be at least 3 characters").max(50, "Symbol must be at most 50 characters")
    }),
    onSubmit: (values) => {
      disaptch(setLoading(true));
      let data: any = {};
      Object.keys(values).forEach((key, value) => {
        if (key === "tokenUrl") {
          // @ts-ignore
          if (values[key]) {
            // @ts-ignore
            data[key] = values[key];
          }
        } else {
          // @ts-ignore
          data[key] = values[key];
        }
      });
      TokenEngineFormAPI(data).then((res) => {
        if (res.status === 200) {
          setSection(TokenEngineFORMEnum.SUCCESS);
          toastSuccess(res.message);
          disaptch(setLoading(false));
        }
      });
    }
  });

  const { handleChange, handleSubmit, values, errors, handleBlur, touched, resetForm } = formik;

  const handleSubmitAction = () => {
    setValidation(true);
    handleSubmit();
  };

  const toggle = () => {
    resetForm();
    setSection(TokenEngineFORMEnum.LOADING);
    setValidation(false);
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (isOpen) {
      setSection(TokenEngineFORMEnum.INITSTATE);
    }
  }, [isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      className="custom-stepper-popup"
      toggle={toggle}
      size="xl"
      centered
      external={
        <Link
          to="/"
          className="f-700 text-white close modal-close close small text-uppercase thankYouCloseButtonHide font-30"
          onClick={(e) => {
            e.preventDefault();
            toggle();
          }}>
          CLOSE
        </Link>
      }>
      <div className={"token-engine-1 custom-card-wrapper"}>
        <div className="tab-1 tab-block h-100">
          <div className={"left-block p-0 h-100 w-100"}>
            {sectionState === TokenEngineFORMEnum.INITSTATE ? (
              <div className="flex-wrap-bl order-2 order-lg-1">
                <h5 className="mb-10 c-tx-primary f-700">Token Emgine Form</h5>
                <Row>
                  <Col sm={6}>
                    <FormGroup className="m-0 mb-0">
                      <Label for="chainCode">Chain Code</Label>
                      <Input
                        onBlur={handleBlur}
                        invalid={touched.chainCode ? (errors?.chainCode ? true : false) : false}
                        onChange={handleChange}
                        value={values.chainCode}
                        id="chainCode"
                        name="chainCode"
                        placeholder="Enter the Chain Code"
                        type="text"
                      />
                      <div className="text-danger" style={{ height: "20px" }}>
                        {" "}
                        {touched.chainCode ? (errors?.chainCode ? errors?.chainCode : "") : ""}
                      </div>
                    </FormGroup>
                  </Col>
                  <Col sm={6}>
                    <FormGroup className="m-0 mb-0">
                      <Label for="channel">Channel Name</Label>
                      <Input
                        onBlur={handleBlur}
                        invalid={touched?.channel ? (errors?.channel ? true : false) : false}
                        onChange={handleChange}
                        value={values.channel}
                        id="channel"
                        name="channel"
                        placeholder="Enter the Channel Name"
                        type="text"
                      />
                      <div className="text-danger" style={{ height: "20px" }}>
                        {touched?.channel ? (errors?.channel ? errors?.channel : "") : ""}
                      </div>
                    </FormGroup>
                  </Col>
                  <Col sm={12}>
                    <FormGroup>
                      <Label for="tokenType">Token Type</Label>
                      <Input
                        onBlur={handleBlur}
                        invalid={touched?.tokenType ? (errors?.tokenType ? true : false) : false}
                        onChange={handleChange}
                        value={values.tokenType}
                        id="tokenType"
                        name="tokenType"
                        type="select">
                        <option value="" disabled>
                          Select Token Type
                        </option>
                        <option value="erc20">ERC 20</option>
                        <option value="erc721">ERC 721</option>
                        <option value="erc1155">ERC 1155</option>
                      </Input>
                      <div className="text-danger" style={{ height: "20px" }}>
                        {touched?.tokenType ? (errors?.tokenType ? errors?.tokenType : "") : ""}
                      </div>
                    </FormGroup>
                  </Col>
                  <Col sm={6}>
                    <FormGroup className="m-0 mb-0">
                      <Label for="tokenName">Token Name</Label>
                      <Input
                        onBlur={handleBlur}
                        invalid={touched?.tokenName ? (errors?.tokenName ? true : false) : false}
                        onChange={handleChange}
                        value={values.tokenName}
                        id="tokenName"
                        name="tokenName"
                        placeholder="Enter the Token name"
                        type="text"
                      />
                      <div className="text-danger" style={{ height: "20px" }}>
                        {touched?.tokenName ? (errors?.tokenName ? errors?.tokenName : "") : ""}
                      </div>
                    </FormGroup>
                  </Col>
                  <Col sm={6}>
                    <FormGroup className="m-0 mb-0">
                      <Label for="totalSupply">Total Supply</Label>
                      <Input
                        onBlur={handleBlur}
                        invalid={touched?.totalSupply ? (errors?.totalSupply ? true : false) : false}
                        onChange={handleChange}
                        value={values.totalSupply}
                        id="totalSupply"
                        name="totalSupply"
                        placeholder="Enter the Total Supply"
                        type="number"
                      />
                      <div className="text-danger" style={{ height: "20px" }}>
                        {touched?.totalSupply ? (errors?.totalSupply ? errors?.totalSupply : "") : ""}
                      </div>
                    </FormGroup>
                  </Col>

                  <Col sm={6}>
                    <FormGroup className="m-0 mb-0">
                      <Label for="symbol">Symbol</Label>
                      <Input
                        onBlur={handleBlur}
                        invalid={touched?.symbol ? (errors?.symbol ? true : false) : false}
                        onChange={handleChange}
                        value={values.symbol}
                        id="symbol"
                        name="symbol"
                        placeholder="Enter the Symbol"
                        type="text"
                      />
                      <div className="text-danger" style={{ height: "20px" }}>
                        {touched?.symbol ? (errors?.symbol ? errors?.symbol : "") : ""}
                      </div>
                    </FormGroup>
                  </Col>
                  {values.tokenType === "erc721" ? (
                    <Col sm={6}>
                      <FormGroup className="m-0 mb-0">
                        <Label for="tokenUrl">Token URL</Label>
                        <Input
                          onBlur={handleBlur}
                          invalid={touched?.tokenUrl ? (errors?.tokenUrl ? true : false) : false}
                          onChange={handleChange}
                          value={values.tokenUrl}
                          id="tokenUrl"
                          name="tokenUrl"
                          placeholder="Enter the Token URL"
                          type="text"
                        />
                        <div className="text-danger" style={{ height: "20px" }}>
                          {touched?.tokenUrl ? (errors?.tokenUrl ? errors?.tokenUrl : "") : ""}
                        </div>
                      </FormGroup>
                    </Col>
                  ) : null}
                  <Col sm={12} className="mt-1">
                    <Button color="primary" onClick={() => handleSubmitAction()}>
                      Submit
                    </Button>
                  </Col>
                </Row>
              </div>
            ) : null}
            {sectionState === TokenEngineFORMEnum.LOADING ? (
              <div className="h-100 d-flex justify-content-center align-items-center">
                <div className="d-flex justify-content-center align-items-center">
                  <span className="fw-bold me-3">Loading...</span> <Spinner size={"sm"} />
                </div>
              </div>
            ) : null}
            {sectionState === TokenEngineFORMEnum.SUCCESS ? <ThankYou /> : null}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default TokenEngineModal;
