import useLocalhostCookieSetupOnRedirect from "hooks/UseLocalhostCookieSetupOnRedirect";

const Login = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const userData: string = urlParams.get("use") || "";
  // if we have cookie in param then return and do not redirect user
  if (userData !== "") {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useLocalhostCookieSetupOnRedirect();
    return null;
    // window.location.href = "/";
  }

  if (process.env.REACT_APP_ENV === "DEVELOPMENT") {
    window.location.href = process.env.REACT_APP_LOCAL_authWithEdexaLogin + window.location.href?.replace("login", "") || "";
  } else {
    window.location.href = process.env.REACT_APP_authWithEdexaLogin || "";
  }
  return <></>;
};

export default Login;
