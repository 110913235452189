import { getMetricsMonitoringListAPI } from "actions/getMetricsMonitoringListAPI";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Col, Row } from "reactstrap";
import { setLoading } from "store/loader/action";
import MetricMonitoringCPUUsage from "./MetricMonitoringCPUUsage";
import MetricMonitoringMemoryUsage from "./MetricMonitoringMemoryUsage";

const MetricsMonitoring = () => {
  const dispatch = useDispatch();
  // eslint-disable-next-line
  const [metricsMonitoringList, setMetricsMonitoringList] = useState<any[]>([]);
  const [pagination, setPagination] = useState({
    limit: 10,
    page: 1,
    totalCount: 0
  });
  const getMetricsMonitoringListAction = () => {
    dispatch(setLoading(true));
    getMetricsMonitoringListAPI({
      limit: pagination.limit,
      page: pagination.page
    }).then((res: any) => {
      if (res?.status === 200) {
        setMetricsMonitoringList(res?.data?.data);
        setPagination({ ...pagination, totalCount: res?.data?.count });
      }
      dispatch(setLoading(false));
    });
  };
  useEffect(() => {
    getMetricsMonitoringListAction();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="page-title">
        <Row>
          <Col md="6">
            <h5 className="c-tx-primary f-700 mb-20">Metrics & Monitoring</h5>
          </Col>
        </Row>
      </div>
      <Row>
        <MetricMonitoringCPUUsage />
        <MetricMonitoringMemoryUsage />
      </Row>
    </>
  );
};

export default MetricsMonitoring;
