import { apiEndPoints } from "utils/api-endpoints";
import HTTPService from "utils/httpService";

export const viewTemplateAPI = (id: string): Promise<IviewTemplateAPI> => {
  return HTTPService.get(apiEndPoints.universeAPI.templates.view + id);
};

export interface IviewTemplateAPIFields {
  default: string;
  key: string;
  placeholder: string;
  required: boolean;
  title: string;
  type: string;
  visible: boolean;
}

export interface IviewTemplateAPIData {
  id: string;
  name: string;
  fields: IviewTemplateAPIFields[];
}

interface IviewTemplateAPI {
  status: number;
  message: string;
  data: IviewTemplateAPIData;
}
