import { faPen, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IlistFolderAPIResData } from "actions/listFolderAPI";
import { FC, useState } from "react";
import { ContextMenu, ContextMenuTrigger, MenuItem } from "react-contextmenu";
import { useNavigate } from "react-router-dom";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import "../../styles/template/template.scss";

const deleteIcon = <FontAwesomeIcon icon={faTrash} />;
const renameIcon = <FontAwesomeIcon icon={faPen} />;
interface IFolder {
  data: IlistFolderAPIResData;
  setCreateModal: React.Dispatch<
    React.SetStateAction<{
      open: boolean;
      id: string;
      rename: string;
    }>
  >;
  setConfimation: React.Dispatch<
    React.SetStateAction<{
      id: string;
      open: boolean;
    }>
  >;
}
export const Folder: FC<IFolder> = ({ data, setCreateModal, setConfimation }) => {
  const navigate = useNavigate();
  const [drp, setdrp] = useState(false);
  return (
    <>
      <ContextMenuTrigger id={`contextmenu${data?.id}`} collect={() => setdrp(false)}>
        <div
          className="templateblock folder"
          onDoubleClick={() =>
            navigate(`/universe/template-contact/${data?.name}`, {
              state: { folderId: data?.id }
            })
          }>
          <div className="block-2 d-flex">
            <div className="bl_1">
              <h6 className="c-tx-primary f-700 mb-0 text-truncate">{data?.name}</h6>
            </div>
            <div className="bl_2">
              <div className="align-items-center">
                <Dropdown isOpen={drp} toggle={() => setdrp(!drp)}>
                  <DropdownToggle className="tlg" tag={"a"}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="4" viewBox="0 0 17 4">
                      <path
                        id="Path_330"
                        data-name="Path 330"
                        d="M8.624,6.781a1.953,1.953,0,0,0-1.906-2,1.953,1.953,0,0,0-1.906,2,1.953,1.953,0,0,0,1.906,2A1.953,1.953,0,0,0,8.624,6.781Zm4.689-2a1.953,1.953,0,0,0-1.906,2,1.908,1.908,0,1,0,3.811,0A1.953,1.953,0,0,0,13.313,4.781Zm-13.189,0a1.953,1.953,0,0,0-1.906,2,1.953,1.953,0,0,0,1.906,2,1.953,1.953,0,0,0,1.906-2A1.953,1.953,0,0,0,.124,4.781Z"
                        transform="translate(1.781 -4.781)"
                        fill="#868686"
                      />
                    </svg>
                  </DropdownToggle>
                  <DropdownMenu style={{ padding: "10px 0" }}>
                    <DropdownItem
                      onClick={() => {
                        setCreateModal({
                          open: true,
                          id: data?.id,
                          rename: data?.name
                        });
                      }}>
                      {renameIcon}
                      <span style={{ marginLeft: "10px" }}>Rename</span>
                    </DropdownItem>
                    <DropdownItem
                      onClick={() => {
                        setConfimation({
                          id: data?.id,
                          open: true
                        });
                      }}>
                      {deleteIcon}
                      <span style={{ marginLeft: "10px" }}>Remove</span>
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </div>
            </div>
          </div>
        </div>
      </ContextMenuTrigger>
      <ContextMenu id={`contextmenu${data?.id}`}>
        <MenuItem
          onClick={() => {
            setCreateModal({
              open: true,
              id: data?.id,
              rename: data?.name
            });
          }}>
          {renameIcon}
          <span>Rename</span>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setConfimation({
              id: data?.id,
              open: true
            });
          }}>
          {deleteIcon}
          <span>Remove</span>
        </MenuItem>
      </ContextMenu>
    </>
  );
};

export default Folder;
