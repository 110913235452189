import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { logoutCurrentUserAPI } from "actions/logoutApi";
import { RegularBell, SolidBell } from "components/bell";
import { UserHeaderImage } from "components/header";
import useWindowSize from "hooks/UseWindowSize";
import { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Collapse, DropdownItem, DropdownMenu, DropdownToggle, Nav, NavItem, NavLink, Navbar, NavbarBrand, Tooltip, UncontrolledDropdown } from "reactstrap";
import { setLoading } from "store/loader/action";
import { setNotificationCount } from "store/notification/action";
import { IRootReducer } from "store/root-reducer";
import { toggleUniverseSidebar } from "store/sidebar/action";
import { useWebsocket } from "utils/WebSocketProvider";
import AppMenu from "utils/common/helper/AppMenu";
import { getAppListing } from "utils/common/helper/getAppListing";
import { handleLogout, redirectTo, redirectToEdexa, toastSuccess } from "utils/commonFunctions";
import { makeFullS3ObjectUrl } from "utils/makeFullS3ObjectUrl";
import "../../styles/common/common.scss";
import "../../styles/header/header.scss";

const logo = makeFullS3ObjectUrl("logo_full.svg");

interface InotificationSocket {
  data: {
    id: string;
    message: string;
    userId: string;
  };
  unRead: number;
}

const HeaderComponent = () => {
  const socket = useWebsocket();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { pathname: currentPath } = useLocation();
  const user = useSelector((state: any) => state.userDetails.user);
  const { isLoggedIN } = useSelector((state: any) => state.auth);
  const { isScrolling } = useSelector((state: any) => state.scrollingReducer);

  const notificationCount = useSelector((state: IRootReducer) => state.NoticationReducer.count);
  const [toggleNavbar, setToggleNavbar] = useState(true);
  const [externalAppArray, setExternalAppArray] = useState([]);
  const [toolTipStatus, setToolTipStatus] = useState<any>({});

  const handleNavbarToggle = () => {
    setToggleNavbar(!toggleNavbar);
  };

  const toogleTooltip = (i: number) => {
    setToolTipStatus({
      [i]: !toolTipStatus[i]
    });
  };

  const handleRedirect = (data: { redirect_url: string }) => {
    window.open(data.redirect_url, "_blank");
  };

  /**
   * @header_app_dropdown
   */
  useEffect(() => {
    if (isLoggedIN) {
      getAppListing().then((res: any) => setExternalAppArray(res.data));
    } // eslint-disable-next-line
  }, []);

  const handleRedireact = (url: string) => {
    navigate(url);
    handleNavbarToggle();
  };

  useEffect(() => {
    socket?.on(`Notification_${user?.id}`, (data: InotificationSocket) => {
      dispatch(setNotificationCount(data?.unRead));
    });
  }, [dispatch, socket, user?.id]);

  // toggle universe sidebar in mobile
  const { isUniverseSdebarActive } = useSelector((state: IRootReducer) => state.universeSidebarReducer);
  const [mobileMenu, setMobileMenu] = useState(false);
  const { viewPortWidth } = useWindowSize();

  useEffect(() => {
    if (viewPortWidth < 1199) {
      setMobileMenu(true);
    } else {
      setMobileMenu(false);
    }
  }, [viewPortWidth]);

  const handleLogoutAction = () => {
    dispatch(setLoading(true));
    logoutCurrentUserAPI()
      .then((res: any) => {
        if (res.status === 200) {
          handleLogout();
        }
        dispatch(setLoading(true));
      })
      .catch((err) => {
        dispatch(setLoading(true));
      });
  };

  return (
    <div className={`header-wrapper header ${isScrolling ? "stickey" : ""}`} style={{ transition: "all 0.1s" }}>
      <Navbar expand="md" color="faded" light>
        <NavbarBrand className="order-0 order-md-0" href="/application">
          <img src={logo} alt="logo" />
        </NavbarBrand>
        <Collapse className="order-2 order-md-1" isOpen={false} navbar>
          <Nav className="mx-auto" navbar>
            <NavItem>
              <NavLink href="#" onClick={() => handleRedireact("/")} className={`${currentPath === "/" ? "active-nav-item" : ""}`}>
                edeXa Universe
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="#" onClick={() => handleRedireact("/application")} className={`${currentPath === "/application" ? "active-nav-item" : ""}`}>
                Applications
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="#" onClick={() => handleRedireact("/apis")} className={`${currentPath === "/apis" ? "active-nav-item" : ""}`}>
                API's
              </NavLink>
            </NavItem>{" "}
            {/* <NavItem>
              <NavLink href="#" onClick={() => handleRedireact("/chat")} className={`${currentPath === "/chat" ? "active-nav-item" : ""}`}>
                edeXAInnovate
              </NavLink>
            </NavItem>
            {/* <NavItem>
              <NavLink href="#" onClick={() => handleRedireact("/webhooks")} className={`${currentPath === "/webhooks" ? "active-nav-item" : ""}`}>
                Webhooks
              </NavLink>
            </NavItem> */}
            <NavItem className="d-md-none d-lg-inline-flex">
              {user?.ownerOrg === true && (
                <NavLink
                  href="#"
                  onClick={(event) => {
                    event.preventDefault();
                    user?.kycApproved === 1 ? handleRedireact("/user-management") : toastSuccess("To Access this feature, you need to get your kyc approved.");
                  }}
                  className={`${currentPath === "/user-management" ? "active-nav-item" : ""}`}>
                  User Management
                </NavLink>
              )}
            </NavItem>
          </Nav>
        </Collapse>
        <ul className="menus-wrapper order-1 order-md-2">
          <NavItem className="notification-link">
            {mobileMenu && currentPath.includes("/universe") ? (
              <div className="sidebar-toggle-btn nav-link position-relative" onClick={() => dispatch(toggleUniverseSidebar(!isUniverseSdebarActive))}>
                <FontAwesomeIcon icon={faBars} />
              </div>
            ) : null}
          </NavItem>
          <NavItem className="notification-link">
            <Link className="nav-link position-relative" to="/notifications">
              {Number(notificationCount) > 0 ? <span className="notification-count">{notificationCount > 99 ? "99+" : notificationCount}</span> : null}
              {currentPath === "/notifications" ? <SolidBell /> : <RegularBell />}
            </Link>
          </NavItem>

          <li className="app-dropdown">
            <UncontrolledDropdown>
              <DropdownToggle nav>
                <AppMenu color="white" />
              </DropdownToggle>
              <DropdownMenu end className="border-grey rounded-10">
                <DropdownItem tag="div" className="p-0">
                  <div className="menu-list">
                    <div className="projects-section">
                      <div className="project-list ">
                        {externalAppArray.length > 0 &&
                          externalAppArray.map((item: any, index) => {
                            return item.isAvailable === 0 ? (
                              ""
                            ) : (
                              <div
                                id={`statusReason${index}`}
                                key={index}
                                className={`project d-inline-flex align-items-center justify-content-start flex-column ${item.isAvailable === 0 && "disable"}`}
                                onClick={() => {
                                  handleRedirect(item);
                                }}>
                                {item.isAvailable === 0 && (
                                  <Tooltip placement="top" style={{ zIndex: 999 }} isOpen={toolTipStatus[`${index}`]} target={`statusReason${index}`} toggle={() => toogleTooltip(index)}>
                                    Coming Soon
                                  </Tooltip>
                                )}
                                {item.name.toLowerCase() === "account" ? (
                                  <div className="customUserLogo">
                                    <p>{user?.firstName?.charAt(0).toUpperCase() || user?.email?.charAt(0).toUpperCase()}</p>
                                  </div>
                                ) : (
                                  <div className="app-img">
                                    <img src={item.logoIcon} alt="logo" className="img-fluid" />
                                  </div>
                                )}
                                <p className="font-14 color-dark">{item.name}</p>
                              </div>
                            );
                          })}
                      </div>
                      <DropdownItem divider />
                      <div className="project-list ">
                        {externalAppArray.length > 0 &&
                          externalAppArray.map((item: any, index) => {
                            return item.isAvailable === 1 ? (
                              ""
                            ) : (
                              <div
                                id={`statusReason${index}`}
                                key={index}
                                className={`project d-inline-flex align-items-center justify-content-start flex-column ${item.isAvailable === 0 && "disable"}`}>
                                {item.isAvailable === 0 && (
                                  <Tooltip placement="top" style={{ zIndex: 999 }} isOpen={toolTipStatus[`${index}`]} target={`statusReason${index}`} toggle={() => toogleTooltip(index)}>
                                    Coming Soon
                                  </Tooltip>
                                )}
                                <div className="app-img">
                                  <img src={item.logoIcon} alt="logo" className="img-fluid" />
                                </div>
                                <p className="font-14 color-dark">{item.name}</p>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                </DropdownItem>
                <DropdownItem className="more-button" onClick={() => navigate("/application")}>
                  More From edeXa Library
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </li>
          <li className="user-dropdown">
            <UncontrolledDropdown>
              <DropdownToggle nav>
                <div className="img-wrapper">
                  <UserHeaderImage url={user?.cropProfilePicture} />
                  {/* <img src={user?.cropProfilePicture ? user?.cropProfilePicture : Img4} alt="profile" className="img-fluid" /> */}
                </div>
              </DropdownToggle>
              <DropdownMenu className="rounded-10 profile-list">
                <DropdownItem className="project-list" tag="div">
                  <p className="font-11 color-black2 bg-lightblue text-center profile-title rounded-5">
                    This account is managed by edeXa.
                    <span className="color-blue custom-padding-header" onClick={redirectToEdexa} style={{ padding: "0px !important" }}>
                      &nbsp; Learn more
                    </span>
                  </p>
                  <div className="profile-detail">
                    <UserHeaderImage url={user?.cropProfilePicture} width="80px" height="80px" />

                    {/* <img src={user?.cropProfilePicture ? user?.cropProfilePicture : Img4} alt="profile" width="80px" height="80px" /> */}
                    <p className="color-black1 font-16 fw-bold">{user?.firstName ? user?.firstName + " " + user?.lastName : user?.email.split("@")[0]}</p>
                    <p className="color-black1  font-14 fw-normal">{user?.email}</p>
                    <button className="font-13 bg-blue color-white rounded-5 manage-button w-100" onClick={() => redirectTo(process.env.REACT_APP_userProfile || "")}>
                      Manage your edeXa Account
                    </button>
                  </div>
                </DropdownItem>
                <div className="profile-footer w-100">
                  <div className="d-flex justify-content-center align-items-center logout">
                    <button className="font-13 color-black-2 rounded-5 border-grey w-100" onClick={handleLogoutAction}>
                      Logout
                    </button>
                  </div>
                  <div className="d-flex justify-content-center align-items-center policy">
                    <a
                      href="#"
                      className="font-11 color-light-grey"
                      onClick={(event) => {
                        event?.preventDefault();
                        redirectTo(process.env.REACT_APP_privacy || "");
                      }}>
                      Privacy Policy
                    </a>
                    <span className="font-11" style={{ padding: "0 10px" }}>
                      -
                    </span>
                    <a
                      href="#"
                      className="font-11 color-light-grey"
                      onClick={(event) => {
                        event?.preventDefault();
                        redirectTo(process.env.REACT_APP_tCondition || "");
                      }}>
                      Terms of Service
                    </a>
                  </div>
                </div>
              </DropdownMenu>
            </UncontrolledDropdown>
          </li>
        </ul>
      </Navbar>
    </div>
  );
};

export default memo(HeaderComponent);
