import { faTimesCircle } from "@fortawesome/free-regular-svg-icons";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FolderListComponent } from "components/folder";
import { FailedPop, SucessfulPop } from "components/template";
import { UploadNewContract } from "../uploadNewContract";
// import { FailedPop } from "component/Template/Failedpop";
// import { SucessfulPop } from "component/Template/SucessfulPop";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Button, Col, Modal, Row } from "reactstrap";
// import "../../../styles/pages/template_and_contact/template.scss";
import "../../styles/template/template.scss";
// import FolderListComponent from "./Folder/FolderListComponent";
import { TemplateListComponent } from "components/templateDetails";
import ContractTemplateListComponent from "../template/ContractTemplateListComponent";
import ContractTableListComponent from "./ContractTableListComponent";

const closeIconRegular = <FontAwesomeIcon icon={faTimesCircle} />;
const caret = <FontAwesomeIcon icon={faCaretDown} />;

const TemmplateAndContracts = () => {
  const [sucessfulmodal, setSucessfulmodal] = useState(false);
  const [stepperModalOpen, setStepperModalOpen] = useState(false);
  const [select, setSelect] = useState<any>(null);
  const toggleStepperModal = () => {
    setStepperModalOpen(!stepperModalOpen);
  };

  useEffect(() => {
    window.addEventListener("click", () => {
      setSelect("");
    });
    return window.removeEventListener("click", () => {
      setSelect("");
    });
  }, []);

  return (
    <>
      <div className="template-and-contact-wrapper position-relative">
        <div className="page-title mb-20">
          <Row className="align-items-center">
            <Col md="6" className="align-items-center">
              <h5 className="c-tx-primary f-700  mb-0">Templates and Smart Contracts</h5>
            </Col>
            <Col md="6" className="text-md-end  my-3 my-md-0">
              <Button className="custom-primary new-btn" onClick={toggleStepperModal}>
                UPLOAD NEW CONTRACT <span className="ps-2">{caret}</span>
              </Button>
            </Col>
          </Row>
        </div>

        {/* (Default Templates section) */}
        <TemplateListComponent />

        {/* (Folder section) */}
        <FolderListComponent select={select} setSelect={setSelect} />

        <ContractTemplateListComponent />

        <ContractTableListComponent />
      </div>

      <Modal
        isOpen={sucessfulmodal}
        className="custom-popup-channel"
        toggle={() => setSucessfulmodal(!sucessfulmodal)}
        size="xs"
        centered
        external={
          <Link to="/" className=" text-white close modal-close close small text-uppercase thankYouCloseButtonHide font-30" onClick={() => setSucessfulmodal(!sucessfulmodal)}>
            {closeIconRegular}
          </Link>
        }>
        {/* sucessful pop up start */}
        <SucessfulPop />
        {/* sucessful pop up end */}
        {/* failed pop up start */}
        <FailedPop />
        {/* failed pop up end */}
      </Modal>
      <UploadNewContract stepperModalOpen={stepperModalOpen} toggleStepperModal={toggleStepperModal} />
    </>
  );
};

export default TemmplateAndContracts;
