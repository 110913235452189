import { moveFolderAPI } from "actions/moveTemplateAPI";
import { useDebouncedEffect } from "hooks/UseDebouncedEffect";
import { FC, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import Select from "react-select";
import { Button, FormGroup, Modal } from "reactstrap";
import { setLoading } from "store/loader/action";
import { listChainCodeAPI } from "utils/common/helper/listChainCode";
import { toastSuccess } from "utils/commonFunctions";

interface IAddTemplateFolderModal {
  open: boolean;
  setOpen: any;
  handleAction: () => void;
}

const AddTemplateFolderModal: FC<IAddTemplateFolderModal> = ({ open, setOpen, handleAction }) => {
  const dispatch = useDispatch();
  const location: any = useLocation();
  const [profile, setProfile] = useState<any>([]);
  const [options, setOptions] = useState<any[]>([]);
  const handleToggle = () => {
    setOpen(false);
    setProfile([]);
  };

  const listTemplateAction = (str?: string) => {
    let data: any = { limit: 20, page: 1, module: "Folder" };
    if (str) data.search = str;
    listChainCodeAPI(data).then((res) => {
      if (res.status === 200) {
        const newOption = res?.data.data?.map((data) => {
          return { label: data.fields.tokenName, value: data.id };
        });
        setOptions(newOption);
      }
    });
  };

  useEffect(() => {
    open && listTemplateAction();
  }, [open]);

  const handleSubmit = () => {
    dispatch(setLoading(true));
    let arrId = profile.map((data: any) => {
      return data.value;
    });
    moveFolderAPI(location?.state?.folderId, {
      chaincodeId: arrId
    }).then((res) => {
      if (res.status === 200) {
        toastSuccess(res.message);
        handleAction();
        handleToggle();
      }
      dispatch(setLoading(false));
    });
  };
  const [search, setSearch] = useState("");

  const handleSerach = (e: string) => {
    listTemplateAction(e);
  };

  useDebouncedEffect(() => handleSerach(search), [search], 500);

  return (
    <Modal
      isOpen={open}
      className="custom-popup-channel"
      size="md"
      toggle={handleToggle}
      centered
      external={
        <Link to="#" className="fw-bold text-white close modal-close close small text-uppercase thankYouCloseButtonHide font-30" onClick={handleToggle}>
          CLOSE
        </Link>
      }>
      {" "}
      <div className="erc-template-wrapper d-flex flex-column">
        <div className="flex-grow-1">
          <div className="title">
            <h5 className="c-tx-primary f-700 mb-20">Add Template</h5>
          </div>
          <FormGroup className="position-relative mb-20">
            <Select
              onInputChange={(e: string) => {
                setSearch(e);
              }}
              options={options}
              isMulti
              value={profile}
              onChange={(value) => {
                setProfile(value);
              }}
            />
            <p className="c-tx-ninth small mt-2">You can select multiple Template</p>
          </FormGroup>
        </div>
        <div className="btn-wrapper text-right align-self-end">
          <Button className="custom-primary new-btn" disabled={options.length === 0 || profile?.length === 0} onClick={handleSubmit}>
            Add Template
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default AddTemplateFolderModal;
