import { faFolderPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IlistFolderAPIResData, listFolderAPI } from "actions/listFolderAPI";
import { FolderEmpty } from "components/template";
import { Folder } from "components/template/Folder";
import { FC, useCallback, useEffect, useState } from "react";
import { ContextMenu, ContextMenuTrigger, MenuItem } from "react-contextmenu";
import { useDispatch } from "react-redux";
import { setLoading } from "store/loader/action";
import { deleteFolderAPI } from "utils/common/helper/deleteFolder";
import { toastSuccess } from "utils/commonFunctions";
import "../folder/test.scss";
import ConfirmationFolderModal from "./ConfirmationFolderModal";
import CreateFolderModal from "./CreateFolderModal";

const folderCreateIcon = <FontAwesomeIcon icon={faFolderPlus} />;

interface IFolderListComponent {
  select: any;
  setSelect: React.Dispatch<any>;
}

const FolderListComponent: FC<IFolderListComponent> = ({ select, setSelect }) => {
  const dispatch = useDispatch();

  const [folderList, setFolderList] = useState<IlistFolderAPIResData[]>([]);
  const [pagination, setPagination] = useState({
    limit: 10,
    page: 1,
    totalCount: 0
  });
  const [createModal, setCreateModal] = useState({
    open: false,
    id: "",
    rename: ""
  });
  const [confirmation, setConfimation] = useState({
    id: "",
    open: false
  });

  const getFodlerListAction = useCallback(() => {
    dispatch(setLoading(true));
    const { limit, page } = pagination;
    listFolderAPI({ limit: limit, page: page }).then((res) => {
      if (res.status === 200) {
        setFolderList(res.data.data);
        setPagination({ ...pagination, totalCount: res.data.count });
      }
      dispatch(setLoading(false));
    });
  }, [dispatch, pagination]);

  const handleConfirmation = () => {
    deleteFolderAPI(confirmation.id).then((res) => {
      if (res.status === 200) {
        getFodlerListAction();
        setConfimation({
          id: "",
          open: false
        });
        toastSuccess(res.message);
      }
    });
  };

  useEffect(() => {
    getFodlerListAction();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <ContextMenuTrigger id="contextmenu">
        <div className="backdrop-block white-wrapper coupon">
          <h5 className="mb-20 c-tx-primary f-600">Folders</h5>
          <div className="column_list">
            <FolderEmpty setCreateModal={setCreateModal} />
            {
              folderList.length > 0
                ? folderList.map((data: IlistFolderAPIResData, i: number) => (
                    <div
                      className={`element cursor-pointer ${`folder${i}` === select && "selectedFolder"}`}
                      key={i}
                      onClick={(e) => {
                        e.stopPropagation();
                        setSelect(`${"folder" + i}`);
                      }}>
                      <Folder data={data} setCreateModal={setCreateModal} setConfimation={setConfimation} />
                    </div>
                  ))
                : null // <FolderEmpty setCreateModal={setCreateModal} />
            }
          </div>
        </div>
      </ContextMenuTrigger>
      <ContextMenu id="contextmenu">
        <MenuItem data={{ copy: "MI50" }} onClick={() => setCreateModal({ ...createModal, open: true })}>
          {folderCreateIcon}
          <span>Folder</span>
        </MenuItem>
      </ContextMenu>
      <ConfirmationFolderModal open={confirmation} setOpen={setConfimation} handleAction={handleConfirmation} />
      <CreateFolderModal createModal={createModal} setCreateModal={setCreateModal} handleAction={getFodlerListAction} />
    </>
  );
};

export default FolderListComponent;
