/* eslint-disable react-hooks/exhaustive-deps */
import { getMemoryUsageGraphApiInfo } from "actions/universeActions/myBlockChain/blockProcessAPI";
import { HighchartsReact } from "highcharts-react-official";
import Highcharts from "highcharts/highstock";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Select from "react-select";
import { Col, Row, Spinner } from "reactstrap";
import { IRootReducer } from "store/root-reducer";
import { getDecryptedLocalStorage } from "utils/commonFunctions";
import { getGraphOptions } from "utils/graphs/getOptions";
import "../../../../src/styles/pages/myblockchain/myblockchain.scss";
import "../../../styles/PeerNode/peerNodeChart.scss";
import { localStorageKeys, timingForMetricGraphArray } from "../../../utils/constants";

const MetricMonitoringMemoryUsage = () => {
  const logData = getDecryptedLocalStorage(localStorageKeys.universeLogs);

  const [storeSelectedTimeAndItsValue, setStoreSelectedTimeAndItsValue] = useState<any>({});
  const [memoryUsageGraphData, setMemoryUsageGraphData] = useState([]);
  const [selectPastTimeFilter, setSelectPastTimeFilter] = useState(timingForMetricGraphArray.length > 0 && timingForMetricGraphArray[0]);
  const [isLoading, setIsLoading] = useState(false);
  const getChannelDataFromRedux = useSelector((state: IRootReducer) => state?.channelDataReducer?.storeChannelData);

  const GetCommonValueTimer = (millisecondsOfSelectedTime: string) => {
    const currentDateInMilliseconds = Date.now();
    const convertToNumberMillisecondsOfSelectedTime = parseInt(millisecondsOfSelectedTime);
    const factorMapping = {
      300000: 3,
      900000: 3,
      1800000: 7,
      3600000: 14,
      7200000: 28,
      21600000: 86,
      43200000: 172,
      86400000: 345
    };
    if (factorMapping.hasOwnProperty(millisecondsOfSelectedTime)) {
      const adjustedTime = currentDateInMilliseconds - 24 * 60 * 60 * 1000 - convertToNumberMillisecondsOfSelectedTime;
      const timeInSeconds = adjustedTime / 1000;
      handleSelectedTime(timeInSeconds);
    }
  };

  const handleSelectedTime = (startTime: number) => {
    setStoreSelectedTimeAndItsValue({
      startTime,
      endTime: Math.floor(Date.now() - 24 * 60 * 60 * 1000) / 1000
    });
  };

  useEffect(() => {
    setIsLoading(true);
    getMemoryUsageGraphApiInfo(storeSelectedTimeAndItsValue, logData?.id)
      .then((response: any) => {
        setMemoryUsageGraphData(response?.data);
        setIsLoading(false);
      })
      .catch((err: any) => {
        setIsLoading(false);
      });
  }, [storeSelectedTimeAndItsValue, getChannelDataFromRedux]);

  const optionsData: any = getGraphOptions(memoryUsageGraphData);

  return (
    <div className="backdrop-block" style={{ minHeight: "495px", maxHeight: "495px" }}>
      <Row>
        <div className="d-flex justify-content-between">
          <Col md="3">
            <div className="title">
              <h6 className="c-tx-primary">
                <span className="f-600 mb-10">Memory &nbsp;</span>
                {isLoading && (
                  <div className="loader-center-metric-monitoring">
                    <Spinner
                      style={{
                        marginLeft: "45%"
                      }}
                      size={"sm"}
                    />
                  </div>
                )}
                <br />
                {memoryUsageGraphData.length > 0 && !isLoading ? (
                  <span className="color-light-grey d-block mt-2" style={{ fontWeight: "500", width: "200%" }}>
                    This node memory (RAM) utilization over the past 24 hours.
                  </span>
                ) : null}
              </h6>
            </div>
          </Col>

          <Col md="2">
            <div className="title extra-css">
              <Select
                className="peer-drop-down"
                options={timingForMetricGraphArray}
                value={selectPastTimeFilter}
                onChange={(e: any) => {
                  setIsLoading(true);
                  setSelectPastTimeFilter(e);
                  GetCommonValueTimer(e.value);
                }}
              />
            </div>
          </Col>
        </div>
      </Row>

      {memoryUsageGraphData.length > 0 && !isLoading ? (
        <Row>
          <Col
            md="12"
            style={{
              visibility: `${isLoading ? "hidden" : "visible"}`
            }}>
            <div className="hide-highcharts-tag">
              <HighchartsReact highcharts={Highcharts} options={optionsData} />
            </div>
          </Col>
        </Row>
      ) : (
        memoryUsageGraphData.length === 0 &&
        !isLoading && (
          <p
            style={{
              marginTop: "10%",
              marginLeft: "40%"
            }}>
            NO DATA FOUND
          </p>
        )
      )}
    </div>
  );
};

export default MetricMonitoringMemoryUsage;
