import axios from "axios";
import { apiEndPoints } from "utils/api-endpoints";
import { getEncryptedCookie } from "utils/commonFunctions";
import { cookieKeys } from "utils/constants";

export const logoutCurrentUserAPI = async (): Promise<IlogoutCurrentUserAPI> => {
  const cookie = getEncryptedCookie(cookieKeys.cookieUser);
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${cookie.token}`
  };
  return new Promise((resolve, reject) => {
    axios
      .post(process.env.REACT_APP_ACCOUNTS_APIBASEURL + apiEndPoints.auth.logout, {}, { headers })
      .then((response) => resolve(response.data))
      .catch((error) => reject(error.response || error));
  });
};

interface IlogoutCurrentUserAPI {
  message: string;
  status: number;
}
