import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { memo } from "react";
import "../../styles/dashboard_layout/documentationblock.scss";
import { toastSuccess } from 'utils/commonFunctions';

const Documentation = () => {
  return (
    <div className="backdrop-block documentation-wrapper ">
      <div className="title">
        <h5 className="c-tx-primary f-600">Documentation</h5>
      </div>
      <ul className="documentation-list">
        <li className="element" onClick={() => toastSuccess("Coming Soon...")}>
          <div className="block-1 px-10">
            <p className="c-tx-primary f-700 first-title">Plans</p>
            <div className="description">
              <p className="c-tx-ninth">Send transactions to the blockchain network using these end points along with valid application credentials.</p>
              <FontAwesomeIcon icon={faChevronRight} className="c-tx-ninth" role="button" />
            </div>
          </div>
        </li>
        <li className="element" onClick={() => toastSuccess("Coming Soon...")}>
          <div className="block-1 px-10">
            <p className="c-tx-primary f-700 first-title">Resource Limits</p>
            <div className="description">
              <p className="c-tx-ninth">Send transactions to the blockchain network using these end points along with valid application credentials.</p>
              <FontAwesomeIcon icon={faChevronRight} className="c-tx-ninth" role="button" />
            </div>
          </div>
        </li>
        <li className="element" onClick={() => toastSuccess("Coming Soon...")}>
          <div className="block-1 px-10">
            <p className="c-tx-primary f-700 first-title">Billing Providers</p>
            <div className="description">
              <p className="c-tx-ninth">Send transactions to the blockchain network using these end points along with valid application credentials.</p>
              <FontAwesomeIcon icon={faChevronRight} className="c-tx-ninth" role="button" />
            </div>
          </div>
        </li>
      </ul>
    </div>
  );
};

export default memo(Documentation);
