import { IgetContractViewAPIResData } from "actions/getContractViewAPI";
import LoadingSpinner from "components/loader/LoadingSpinner";
import { FC } from "react";
import { useSelector } from "react-redux";
import { Button } from "reactstrap";

import { IRootReducer } from "store/root-reducer";
import { getFormattedDate, toastSuccess } from "utils/commonFunctions";
import { SmartContractListStatusEnum } from "utils/constants";
import "../../styles/template/template.scss";
interface IBusinessModalPop {
  listData?: IgetContractViewAPIResData;
}
const BusinessModalPop: FC<IBusinessModalPop> = ({ listData }) => {
  const loader = useSelector((state: IRootReducer) => state.loading);

  const handleStatus = (status: number) => {
    if (status === SmartContractListStatusEnum.INPROGRESS) {
      return "In-Progress";
    } else if (status === SmartContractListStatusEnum.REJECTED) {
      return "Rejected";
    } else if (status === SmartContractListStatusEnum.ACCEPTED) {
      return "Approve";
    } else {
      return "Pending";
    }
  };
  return (
    <>
      <div className="custom-card-wrapper h-auto ct-modal-wrapper-read businesss ">
        <div className="left-block">
          <h5 className="mb-20 c-tx-primary f-700">Smart Contract</h5>
          <div className="ct-table mh-100 px-0">
            <ul className="details-list">
              {loader.isLoading ? (
                <LoadingSpinner size="sm" />
              ) : (
                <>
                  <li className="busin_element">
                    <div className="fir_bl">
                      <p className="c-tx-primary f-700">App ID</p>
                    </div>
                    <div className="colon_bl">
                      <p className="c-tx-primary f-700">:</p>
                    </div>
                    <div className="sec_bl">
                      <p className="c-tx-primary ">{listData?.id}</p>
                    </div>
                  </li>
                  <li className="busin_element">
                    <div className="fir_bl">
                      <p className="c-tx-primary f-700">Name</p>
                    </div>
                    <div className="colon_bl">
                      <p className="c-tx-primary f-700">:</p>
                    </div>
                    <div className="sec_bl">
                      <p className="c-tx-primary ">{listData?.name}</p>
                    </div>
                  </li>
                  <li className="busin_element">
                    <div className="fir_bl">
                      <p className="c-tx-primary f-700">Description</p>
                    </div>
                    <div className="colon_bl">
                      <p className="c-tx-primary f-700">:</p>
                    </div>
                    <div className="sec_bl">
                      <p className="c-tx-primary ">{listData?.description}</p>
                    </div>
                  </li>
                  <li className="busin_element">
                    <div className="fir_bl">
                      <p className="c-tx-primary f-700">Protocol</p>
                    </div>
                    <div className="colon_bl">
                      <p className="c-tx-primary f-700">:</p>
                    </div>
                    <div className="sec_bl">
                      <p className="c-tx-primary ">{listData?.protocol}</p>
                    </div>
                  </li>
                  <li className="busin_element">
                    <div className="fir_bl">
                      <p className="c-tx-primary f-700">Type</p>
                    </div>
                    <div className="colon_bl">
                      <p className="c-tx-primary f-700">:</p>
                    </div>
                    <div className="sec_bl">
                      <p className="c-tx-primary ">{listData?.language}</p>
                    </div>
                  </li>
                  <li className="busin_element">
                    <div className="fir_bl">
                      <p className="c-tx-primary f-700">Owner</p>
                    </div>
                    <div className="colon_bl">
                      <p className="c-tx-primary f-700">:</p>
                    </div>
                    <div className="sec_bl">
                      <p className="c-tx-primary ">{listData?.cntrctUser?.name || listData?.cntrctUser?.email.split("@")[0]}</p>
                    </div>
                  </li>
                  <li className="busin_element">
                    <div className="fir_bl">
                      <p className="c-tx-primary f-700">Created Date</p>
                    </div>
                    <div className="colon_bl">
                      <p className="c-tx-primary f-700">:</p>
                    </div>
                    <div className="sec_bl">
                      <p className="c-tx-primary ">{listData?.createdAt && getFormattedDate(listData?.createdAt)}</p>
                    </div>
                  </li>
                  <li className="busin_element">
                    <div className="fir_bl">
                      <p className="c-tx-primary f-700">Status</p>
                    </div>
                    <div className="colon_bl">
                      <p className="c-tx-primary f-700">:</p>
                    </div>
                    <div className="sec_bl">
                      <p className="c-tx-primary">{listData?.status && handleStatus(listData?.status)}</p>
                    </div>
                  </li>
                </>
              )}
            </ul>
            <Button className="custom-primary w-auto new-btn mt-10" onClick={() => toastSuccess("Coming soon")}>
              CREATE NEW VERSION
            </Button>
          </div>
        </div>
        <div className="right-block">
          <div>
            <h5 className="mb-20">Documentation</h5>
            <ul className="documentation-list">
              <li>
                <p className="c-tx-primary f-700 mb-1">Contract Management</p>
                <p className="c-tx-ninth small">Documentation</p>
              </li>
              <li>
                <p className="c-tx-primary f-700 mb-1">How does to Work ?</p>
                <p className="c-tx-ninth small">Blog</p>
              </li>
              <li>
                <p className="c-tx-primary f-700 mb-1">What are Contracts ?</p>
                <p className="c-tx-ninth small">Blog</p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default BusinessModalPop;
