import { viewTemplateAPI } from "actions/viewTemplateAPI";
import { memo, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Modal } from "reactstrap";
import { setLoading } from "store/loader/action";
import { IRootReducer } from "store/root-reducer";
import { closeTemplateModal, setTemplateGetData } from "store/templateReducer/action";
import { TEMPLATE_KEY } from "utils/constants";
import * as Yup from "yup";
import DynamicFiledRender from "./DynamicFiledRender";

const InstallationTemplateModal = () => {
  const dispatch = useDispatch();
  const { IInstallationTemplateModal } = useSelector((state: IRootReducer) => state?.TemplateReducer?.modal);
  const { id } = useSelector((state: IRootReducer) => state?.TemplateReducer);

  const [formikState, setFormikState] = useState<any>();
  const [templateData, setTemplateData] = useState<any>();
  const [validationSchema, setValidationSchema] = useState<any>();

  const getTemplateData = useCallback(() => {
    dispatch(setLoading(true));
    viewTemplateAPI(id).then((res) => {
      dispatch(setTemplateGetData(res.data));
      let stateNew: any = {};
      let validationObject: any = {};
      if (res.status === 200) {
        //  eslint-disable-next-line
        res.data.fields.map((data: any) => {
          if (data.type === TEMPLATE_KEY.dynamic) {
            stateNew[data.key.replaceAll(/\s/g, "")] = {};
          } else if (data.type === TEMPLATE_KEY.text) {
            stateNew[data.key.replaceAll(/\s/g, "")] = "";
          } else if (data.type === TEMPLATE_KEY.number) {
            stateNew[data.key.replaceAll(/\s/g, "")] = 0;
          } else if (data.type === TEMPLATE_KEY.spclAlphaNumeric) {
            stateNew[data.key.replaceAll(/\s/g, "")] = "";
          } else {
            stateNew[data.key.replaceAll(/\s/g, "")] = "";
          }

          if (data.required) {
            if (data.type === TEMPLATE_KEY.text) {
              validationObject[data.key.replaceAll(/\s/g, "")] = Yup.string()
                .required(`${data.title} is required`)
                .matches(/^[a-zA-Z][a-zA-Z\\s]+$/, "Only alphabets are allowed");
            } else if (data.type === TEMPLATE_KEY.number) {
              validationObject[data.key.replaceAll(/\s/g, "")] = Yup.number().required(`${data.title} is required`).nullable().positive("Must be more than 0").integer("Must be more than 0");
            } else if (data.type === TEMPLATE_KEY.dynamic) {
              validationObject[data.key.replaceAll(/\s/g, "")] = Yup.object().required();
            } else if (data.type === TEMPLATE_KEY.spclAlphaNumeric) {
              validationObject[data.key.replaceAll(/\s/g, "")] = Yup.string().required();
            } else {
              validationObject[data.key.replaceAll(/\s/g, "")] = Yup.string().required();
            }
          }
        });
      }

      setValidationSchema(Yup.object().shape(validationObject));
      setTemplateData(res.data);
      setFormikState(stateNew);
      dispatch(setLoading(false));
    });
  }, [dispatch, id]);

  useEffect(() => {
    if (id) getTemplateData();
    // eslint-disable-next-line
  }, [id, getTemplateData]);

  return (
    <Modal
      isOpen={IInstallationTemplateModal}
      className="custom-popup-channel"
      toggle={() => dispatch(closeTemplateModal("IInstallationTemplateModal"))}
      size="lg"
      centered
      external={
        <Link
          to="#"
          className="f-700 text-white close modal-close close small text-uppercase thankYouCloseButtonHide font-30"
          onClick={() => {
            dispatch(closeTemplateModal("IInstallationTemplateModal"));
            dispatch(setTemplateData({}));
          }}>
          CLOSE
        </Link>
      }>
      <div className="erc-template-wrapper w-100">
        <div className="title">
          <h5 className="c-tx-primary f-600 mb-20">{templateData?.name} Template</h5>
        </div>
        {formikState && <DynamicFiledRender formikState={formikState} validationSchema={validationSchema} templateData={templateData} />}
      </div>
    </Modal>
  );
};

export default memo(InstallationTemplateModal);
