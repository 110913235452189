import { useDebouncedEffect } from "hooks/UseDebouncedEffect";
import { useEffect, useState } from "react";
import { UncontrolledTooltip } from "reactstrap";

const LogInput = ({ loadMore, reset }: any) => {
  const [number, setnumber] = useState<number>(24);
  const handleChange = (e: any) => {
    setnumber(e.target.value);
  };

  useDebouncedEffect(() => loadMore(number), [number], 1000);

  useEffect(() => {
    if (reset) {
      setnumber(24);
    }
  }, [reset]);

  return (
    <div className="d-flex mr-10">
      <UncontrolledTooltip placement="top" target="decrementTarget">
        Decrement
      </UncontrolledTooltip>
      <button
        className="btn btn-outline-primary mr-10"
        type="button"
        id="decrementTarget"
        onClick={(e) => {
          if (number > 1) {
            setnumber((num) => num - 1);
          }
        }}
        disabled={number < 1}>
        -
      </button>
      <UncontrolledTooltip placement="top" target="LastUpdated">
        Get Last 24 hours updated logs
      </UncontrolledTooltip>
      <input type="number" className="log-input" id="LastUpdated" value={number} onChange={handleChange} />
      <UncontrolledTooltip placement="top" target="incrementTarget">
        Increment
      </UncontrolledTooltip>
      <button className="btn btn-outline-primary ml-10" type="button" onClick={() => setnumber((num) => Number(num) + 1)} id="incrementTarget">
        +
      </button>
    </div>
  );
};

export default LogInput;
