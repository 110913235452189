import useWindowSize from "hooks/UseWindowSize";
import moment from "moment";
import { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { Col, Container, Row } from "reactstrap";
import { setFirstTimeVisitorTourVisibility } from "store/reactour/action";
import { IRootReducer } from "store/root-reducer";
import { toggleUniverseSidebar } from "store/sidebar/action";
import "../../styles/footer/footer.scss";

const FooterComponent = () => {
  const location = useLocation();
  const rediretUrl = (type: any) => {
    if (type === "privacy") {
      window.open(`${process.env.REACT_APP_privacy}`, "_blank");
    } else if (type === "terms") {
      window.open(`${process.env.REACT_APP_tCondition}`, "_blank");
    }
  };
  const globalSetting = useSelector((state: IRootReducer) => state.GlobalSettingReducer);
  const dispatch = useDispatch();
  const { tour } = useSelector((state: IRootReducer) => state.NetworkReducer);
  const { isUniverseSdebarActive } = useSelector((state: IRootReducer) => state.universeSidebarReducer);
  const { viewPortWidth } = useWindowSize();
  return (
    <div className="footer-wrapper">
      <Container>
        <Row>
          <Col sm="6" className="text-center text-sm-start">
            <p className="c-tx-ninth mb-0">edeXa - Customer Admin Universe © {moment().year()}</p>
          </Col>
          <Col sm="6">
            <ul className="page-link-list justify-content-center justify-content-sm-end">
              <li
                onClick={(event) => {
                  event?.preventDefault();
                  rediretUrl("privacy");
                }}>
                <a href="#">Privacy</a>
              </li>
              {globalSetting.comingSoon || !location?.pathname.includes("universe") ? null : tour !== undefined ? (
                <li
                  onClick={(event) => {
                    dispatch(toggleUniverseSidebar(true));

                    // if sidebar is already visible then just dispatch the event otherwise wait for few miliseconds and then dispatch tour to render ui correctly
                    isUniverseSdebarActive || viewPortWidth > 1191
                      ? dispatch(setFirstTimeVisitorTourVisibility(true))
                      : setTimeout(() => {
                          dispatch(setFirstTimeVisitorTourVisibility(true));
                        }, 500);
                  }}>
                  <a href="#">App Tour</a>
                </li>
              ) : null}
              <li
                onClick={(event) => {
                  event?.preventDefault();
                  rediretUrl("terms");
                }}>
                <a href="#">Terms</a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default memo(FooterComponent);
