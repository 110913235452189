import { installationContractListAPI } from "actions/installationContractAPI";
import BadgeBlock from "components/badgeBlocks/BadgeBlock";
import { NoFoundTemplate } from "components/notFoundPages";
import PaginationComponent from "components/pagination/PaginationComponent";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row } from "reactstrap";

import { setLoading } from "store/loader/action";
import { IRootReducer } from "store/root-reducer";
import { socketConnection } from "utils/commonFunctions";
import { SmartContractListStatusEnum } from "utils/constants";
import { makeFullS3ObjectUrl } from "utils/makeFullS3ObjectUrl";

const logo = makeFullS3ObjectUrl("zip.png");

const InstallationContractTemplate = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state: IRootReducer) => state.userDetails);

  // eslint-disable-next-line
  const [historyLoading, setHistoryLoading] = useState(false);
  const [templateList, setTemplateList] = useState<any[]>([]);
  const [pagination, setPagination] = useState({
    limit: 5,
    page: 1,
    totalCount: 0
  });

  const getInstallationContractAction = () => {
    dispatch(setLoading(true));
    installationContractListAPI({
      limit: pagination.limit,
      page: pagination.page,
      approved: false
    })
      .then((res: any) => {
        if (res.status === 200) {
          setTemplateList(res.data.data);
          setPagination({ ...pagination, totalCount: res.data.count });
        }
        dispatch(setLoading(false));
      })
      .catch((err) => setHistoryLoading(false));
  };

  const handleStatus = (status: number) => {
    if (status === SmartContractListStatusEnum.INPROGRESS) {
      return "In-Progress";
    } else if (status === SmartContractListStatusEnum.REJECTED) {
      return "Rejected";
    } else {
      return "Pending";
    }
  };

  useEffect(() => {
    getInstallationContractAction();
    // eslint-disable-next-line
  }, [pagination.page]);

  useEffect(() => {
    socketConnection?.on(`contract_installtion_${user?.id}`, (data: any) => {
      if (pagination.page === 1) {
        getInstallationContractAction();
      } else {
        setPagination({ ...pagination, page: 1 });
      }
    });

    return () => {
      socketConnection.off(`Contract_status_${user?.id}`);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.id]);

  return (
    <>
      <div className="column_list">
        {templateList.length > 0 ? (
          templateList.map((data: any, i: number) => (
            <div key={i} className="element">
              <div className="templateblock">
                <div className="block-1">
                  <div className="badge-bl">
                    <BadgeBlock theme="dark" name="Smart Contract" />
                  </div>
                  <img src={logo} className="img-fluid" alt="zip" />
                </div>
                <div className="block-2">
                  <div className="bl_1 text-truncate">
                    <h6 className="c-tx-primary f-700 mb-0 text-truncate">{data?.contrChlContract?.name}</h6>
                    <p className="c-tx-ninth">{handleStatus(data?.status)}</p>
                  </div>
                </div>
              </div>
            </div>
          ))
        ) : (
          <NoFoundTemplate message="No Found Smart Contracts" bageName="Smart Contract" logo={logo} />
        )}
      </div>
      <div className="pagination-wrap mt-0">
        <Row>
          <Col md="12" className="d-flex justify-content-end">
            <PaginationComponent pagination={pagination} setPagination={setPagination} />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default InstallationContractTemplate;
