import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Tour from "reactour";
import { setUserTour } from "store/auth/action";
import { setFirstTimeVisitorTourVisibility } from "store/reactour/action";
import { IRootReducer } from "store/root-reducer";
import "../../styles/reactTour/reactour.scss";

const steps = [
  {
    selector: ".sidebar_menu_dashboard",
    content: (
      <div className="reactour-text-container font-14">
        <h3>Dashboard</h3>
        <p>
          <b>Once again thanks for showing the trust on edeXa</b>
          <br />
        </p>

        <ul style={{ listStyle: "disc" }} className="ms-3 mt-1">
          <li className="ms-3">Dashboard is about the overview of each part of your network, activity, request status and many more</li>
          <li className="ms-3">You can explore our MarketPlace - Application and Api </li>
        </ul>
      </div>
    )
  },
  {
    selector: ".sidebar_menu_my_blockchain",
    content: (
      <div className="reactour-text-container font-14">
        <h3>My Blockchain</h3>
        <p>
          <b>Complete management of your peer and Orderer</b>
          <br />
        </p>

        <ul style={{ listStyle: "disc" }} className="ms-3 mt-1">
          <li className="ms-3">Metric Montierting and Many more</li>
        </ul>
      </div>
    )
  },
  {
    selector: ".sidebar_menu_channel_management",
    content: (
      <div className="reactour-text-container font-14">
        <h3>Channel management</h3>
        <p>
          <b>All About about managing the channels in your network </b>
          <br />
        </p>
        <ul style={{ listStyle: "disc" }} className="ms-3 mt-1">
          <li className="ms-3">View Channels</li>
          <li className="ms-3">Create a Channel</li>
          <li className="ms-3">Invite other to join your channel</li>
        </ul>
      </div>
    )
  },
  {
    selector: ".sidebar_menu_template_contracts",
    content: (
      <div className="reactour-text-container font-14">
        <h3>Templates and Smart Contracts</h3>
        <p>
          <b>Use the template provided by edeXa, Also you can upload your on Smart Contract </b>
          <br />
        </p>

        <ul style={{ listStyle: "disc" }} className="ms-3 mt-1">
          <li className="ms-3">View Channels</li>
          <li className="ms-3">Create a Channel</li>
          <li className="ms-3">Invite other to join your channel</li>
        </ul>
      </div>
    )
  }
];
const ReactourFirstVisit = () => {
  const { visitorTourVisible } = useSelector((state: IRootReducer) => state.firstTimeTourReducer);
  const navigate = useNavigate();

  const [currentStep, setCurrentStep] = useState(0);
  const dispatch = useDispatch();

  return (
    <Tour
      steps={steps}
      isOpen={visitorTourVisible}
      onRequestClose={() => dispatch(setFirstTimeVisitorTourVisibility(false))}
      rounded={0}
      highlightedMaskClassName="masked-highlight"
      maskSpace={0}
      closeWithMask={false}
      prevButton={
        <span style={{ display: "block", height: "25px", width: "40px" }} className="cursor-pointer" onClick={() => dispatch(setFirstTimeVisitorTourVisibility(false))}>
          Skip
        </span>
      }
      lastStepNextButton={
        <span
          onClick={() => {
            dispatch(setFirstTimeVisitorTourVisibility(false));
            // make global user tour flag false
            // we set this true while user login
            // if user is first time visitor then set this flag and it will show him app tour
            dispatch(setUserTour(false));
            setTimeout(() => {
              navigate("/universe/dashboard");
            }, 200);
          }}>
          Finish
        </span>
      }
      nextButton={<span onClick={() => setCurrentStep(currentStep + 1)}>Next</span>}
      className={"reactour_touristGuide "}
      showCloseButton={false}
      showNumber={false}
      accentColor="#073D83"
      disableFocusLock={false}
      disableInteraction={true}
      disableDotsNavigation={true}
      getCurrentStep={(currentStep) => {
        // open dropdown
        // preventing from infinite loop
        // if we do not count rerender condition then this will execute infinite times
        if (currentStep === 1) {
          setTimeout(() => {
            navigate("/universe/blockchain");
          }, 100);
        } else if (currentStep === 2) {
          setTimeout(() => {
            navigate("/universe/channel-management");
          }, 100);
        } else if (currentStep === 3) {
          setTimeout(() => {
            navigate("/universe/template-contact");
          }, 100);
        }
      }}
    />
  );
};

export default ReactourFirstVisit;
