import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AccordianWrap } from "components/accrordian";
import cryptojs from "crypto-js";
import { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Input, Modal, ModalBody, Row } from "reactstrap";
import { setLoading } from "store/loader/action";
import { capitalizeFirstLetter, getAPIResponseFromUrl, handleApiColor, handleApiToaster, handleJson, scrollToTargetAdjusted, toastError } from "utils/commonFunctions";
import { toasterMessages } from "utils/constants";
const Check = <FontAwesomeIcon icon={faCheck} />;

interface IApiDetailComponent {
  apiDetailsState: any;
  apiExplor: any;
  index: number;
}

const ApiDetailComponent: FC<IApiDetailComponent> = ({ apiDetailsState, apiExplor, index }) => {
  const dispatch = useDispatch();

  const { isScrolling } = useSelector((state: any) => state.scrollingReducer);
  const [bStampFile, setBstampFile] = useState("");
  const [response, setResponse] = useState<any>(apiExplor.respParams);
  const [subscribeModal, setSubscribeModal] = useState(false);
  // eslint-disable-next-line
  const [currentActiveHttpRequest, setCurrentActiveHttpRequest] = useState([]);
  const [apiDetails, setApiDetails] = useState<any>();
  const [forceRender, setForceRender] = useState(false);
  const [multipleStamp, setMultipleStamp] = useState<any[]>([]);
  const [showAccordian1, setShowAccordian1] = useState(false);
  // eslint-disable-next-line
  const [isSandboxOrLive, setIsSandboxOrLive] = useState(true);
  const [currentActiveIndex, setCurrentActiveIndex] = useState(0);
  const setActiveHttpRequest = (Iindex: number) => {
    const newHttpTabs = apiDetails?.data?.data?.map((item: Object, index: number) => index === Iindex);
    setCurrentActiveHttpRequest(newHttpTabs);
  };
  const validateData = (data: any) => {
    let valid = true;
    let error = false;
    data.raw.forEach((e: any, i: any) => {
      if (e.type !== "boolean") {
        if (!e.value && !error) {
          valid = false;
          toastError(toasterMessages.fillAllRequireField);
          error = true;
        }
      }
    });
    return valid;
  };

  useEffect(() => {
    if (apiDetailsState) {
      setApiDetails(apiDetailsState);
    }
  }, [apiDetailsState]);

  const setReqVisible = (exploreVal: any, key: any) => {
    apiDetails.data.data[key].isExecute = exploreVal;
    setApiDetails(apiDetails);
    setForceRender(!forceRender);
  };
  const appendTokenToAllApisAfterLogin = (token: string) => {
    apiDetails?.data?.data.map((el: any, i: any) => {
      const apiName = el.name.toLowerCase();
      let authAPI = false;
      if (apiName === "login" || apiName === "register" || apiName === "authenticate") {
        authAPI = true;
      }
      if (!authAPI) {
        const index = el.header.findIndex((x: any) => x.key === "Authorization");
        if (index === -1) {
        } else {
          el.header[index].value = `Bearer ${token}`;
        }
        el["auth"] = token;
      }
      return el;
    });
    setApiDetails(apiDetails);
    setForceRender(!forceRender);
  };

  /**
   * @start
   * @api_execution
   */
  // handle the  api execution
  const apiCall = (url: string, data: any, i: number, headers?: any, method?: string) => {
    dispatch(setLoading(true));
    const reqResData: any = {
      url,
      data,
      headers,
      method
    };
    // @ts-ignore
    getAPIResponseFromUrl(reqResData)
      .then((result: any) => {
        setMultipleStamp([]);
        apiDetails.data.data[i].respParams = result.data;
        setApiDetails(apiDetails);
        handleApiToaster(result.data.status, result.data.message);
        setReqVisible(true, i);
        setResponse(result.data);
        if (result.data.data.token) {
          appendTokenToAllApisAfterLogin(result.data.data.token);
        }
        dispatch(setLoading(false));
      })
      .catch((err: any) => {
        apiDetails.data.data[i].respParams = err;
        setApiDetails(apiDetails);
        handleApiToaster(err?.response?.data?.status, err?.response?.data?.message);
        setResponse(err?.response?.data);
        setReqVisible(true, i);
        dispatch(setLoading(false));
      });
  };
  const validateAttachmentsAndMetaData = (data: any) => {
    let noError = true;
    let error = false;
    data.raw.forEach((el: any) => {
      if (el.type === "text" && !el.value && !error) {
        toastError(toasterMessages.fillAllRequireField);
        noError = false;
        error = true;
      } else if (el.type === "file" && !el.value) {
        toastError(toasterMessages.chooseAttachmentFile);
        noError = false;
        error = true;
      }
    });
    return noError;
  };
  const convertToFormData = (item: any, index: any): FormData => {
    const formData = new FormData();
    const requireParams = item.body.requireParams;
    const optionalParams = item.body.optinalParams;
    const obj: any = {};
    requireParams.raw.forEach((el: any) => {
      if (el.key === "message" || el.key === "metadata") {
        const searchString: any = { meta: el.value };
        obj[el.key] = el.value;
        formData.append(el.key, JSON.stringify(searchString));
      } else if (el.type === "file") {
        formData.append(el.key, el.value);
        obj[el.key] = el.value.name;
      } else {
        formData.append(el.key, el.value);
        obj[el.key] = el.value;
      }
    });

    if (optionalParams?.raw?.length) {
      optionalParams.raw.forEach((el: any) => {
        if (el.value) {
          formData.append(el.key, el.value);
          obj[el.key] = el.value;
        }
      });
    }

    apiDetails.data.data[index].requestParams = obj;
    setApiDetails(apiDetails);
    return formData;
  };

  const executeAPI = (item: any, i: any) => {
    if (!apiDetails?.data?.inUse) {
      setSubscribeModal(true);
    } else {
      const data = item?.body?.requireParams;
      if (data.mode === "raw" || data.mode === "json") {
        const isValid = validateData(data);
        if (isValid) {
          // Construct a single json request from an array of objects
          let request: any = {};
          if (multipleStamp.length > 1) {
            request = multipleStamp;
          } else {
            data?.raw.forEach((e: any) => {
              if (e.key === "Hash") {
                const value = e.value[0];
                e.value = [value];
              }
              request[e.key] = e.type === "array" ? e.value.split(",") : e.value;
            });

            let optionalParms = item?.body?.optinalParams;
            if (item?.body?.optinalParams?.raw?.length) {
              optionalParms.raw.forEach((e: any) => {
                if (e.value) {
                  request[e.key] = e.value;
                }
              });
            }
            apiDetails.data.data[i].requestParams = request;
            setApiDetails(apiDetails);
          }

          let url = item.url;
          if (!isSandboxOrLive) {
            url = item.url;
          } else {
            url = item.liveUrl;
          }
          apiCall(url, request, i, item.header, item.request_type);
        } else {
          data.raw.forEach((e: any) => {
            if (!e.value) {
              e.errorReqire = true;
              setTimeout(() => {
                scrollToTargetAdjusted(350, `${item.name}${data.mode}${e.key}`);
              }, 0);
            }
          });
          setApiDetails(apiDetails);
        }
      } else if (data.mode === "formdata" || data.mode === "formData") {
        // Attachments & MetaData
        const isValid = validateAttachmentsAndMetaData(data);

        if (isValid) {
          // convert to form data
          const formdata = convertToFormData(item, i);
          let url = item.url;
          if (!isSandboxOrLive) {
            url = item.url;
          } else {
            url = item.liveUrl;
          }
          apiCall(url, formdata, i, item.header, item.request_type);
        } else {
          data.raw.forEach((e: any) => {
            if (!e.value) {
              e.errorReqire = true;
              setTimeout(() => {
                scrollToTargetAdjusted(350, `${item.name}${data.mode}${e.key}`);
              }, 0);
            }
          });
          setApiDetails(apiDetails);
        }
      } else {
        let url = item.url;
        if (!isSandboxOrLive) {
          url = item.url;
        } else {
          url = item.liveUrl;
        }
        apiCall(url, {}, i, item.auth, item.request_type);
      }
    }
  };
  const multipleStampFileSelect = (e: any, indexVal: number) => {
    let fileArray: any = [];
    // eslint-disable-next-line
    Object.keys(e.target.files).map((data: any) => {
      let fileObject = {
        filename: "",
        hash: ""
      };
      let fileData = e.target.files[data];
      let getBase64 = new Promise(async (resolve, reject) => {
        try {
          var reader = new FileReader();
          reader.readAsDataURL(fileData);
          reader.onload = function () {
            resolve(reader.result);
          };
          reader.onerror = function (error) {};
        } catch (e) {
          reject(false);
        }
      });
      getBase64
        .then((value: any) => {
          let hashVal = cryptojs.SHA256(value).toString();
          setBstampFile("Stamp Status");
          if (hashVal) {
            apiDetails.data.data[indexVal].body.requireParams.raw.forEach((elChild: any) => {
              if (elChild.key === "filename") {
                elChild.value = fileData.name;
                fileObject.filename = fileData.name;
                elChild.errorReqire = false;
              } else if (elChild.key === "hash") {
                elChild.value = hashVal;
                fileObject.hash = hashVal;
                elChild.errorReqire = false;
              }
            });
            setApiDetails({
              ...apiDetails,
              apiDetails
            });
            fileArray.push(fileObject);
            setForceRender(!forceRender);
          }
        })
        .catch((error) => {});
    });
    setMultipleStamp(fileArray);
  };

  const stampFileSelect = (e: any, indexVal: number) => {
    let fileData = e.target.files[0];
    let getBase64 = new Promise(async (resolve, reject) => {
      try {
        var reader = new FileReader();
        reader.readAsDataURL(fileData);
        reader.onload = function () {
          resolve(reader.result);
        };
        reader.onerror = function (error) {};
      } catch (e) {
        reject(false);
      }
    });
    getBase64
      .then((value: any) => {
        let hashVal = cryptojs.SHA256(value).toString();
        setBstampFile(fileData.name);

        if (hashVal) {
          apiDetails.data.data[indexVal].body.requireParams.raw.forEach((elChild: any) => {
            if (elChild.key === "filename") {
              elChild.value = fileData.name;
              elChild.errorReqire = false;
            } else if (elChild.key === "hash") {
              elChild.value = hashVal;
              elChild.errorReqire = false;
            }
          });
          setApiDetails({
            ...apiDetails,
            apiDetails
          });
          setForceRender(!forceRender);
        }
      })
      .catch((error) => {});
  };

  return (
    <Row
      key={index}
      style={{ marginBottom: "64px" }}
      onWheel={() => {
        // set active index of left side anchors
        // if this div is in the scroll view then set the active index of anchor index
        // @todo - optimize the setstate to only call once
        if (currentActiveIndex !== index) {
          // set the index of current global index
          // so we can track and check - if the current index is same as scrolling div index then do not change anything
          // but if the index is of new div then change the current active index
          // it will optimize the setState as we will not be setting state on every scroll
          // we sill setState only when currentIndex is not equal to the current index.
          setCurrentActiveIndex(index);
          // set the active index of sidebar menu
          setActiveHttpRequest(index);
        }
      }}>
      <Col sm="12" md="12" lg="12" xl="7" xxl="7">
        <div className="inner-common-block mt-3 mt-lg-0 mb-3 mb-xl-0" id={`${apiExplor.id}`}>
          <div className="status-header with-btn common-pad mb-0">
            <div className="bl-1">
              <p className="mb-0 c-tx-primary text-uppercase c-bg-sixth f-700">
                {handleApiColor(apiExplor?.request_type, true)} | {apiExplor?.name}
              </p>
            </div>
            <div className="bl-2">
              <button
                className="small custom-primary"
                onClick={() => {
                  !showAccordian1 && setShowAccordian1(true);
                  executeAPI(apiExplor, index);
                }}>
                Execute
              </button>
            </div>
          </div>
          <div className="common-pad mb-0 py-0 era-wrapper">
            <div className="">
              <p className="c-tx-primary cy-15 mb-0 f-700 text-uppercase lh-17 inner-title-cl">Description</p>
              <p className="c-tx-ninth cb-15">{apiExplor?.decription}</p>
            </div>
            {/**
             * @api_attributes
             */}
            <div className="datalist details-wrapper mt-0 ">
              <ul className=" point-list">
                {apiExplor?.attributes?.requireField.length > 0 &&
                  apiExplor?.attributes?.requireField?.map((attribute: any, index: number) => {
                    return (
                      <li key={index}>
                        <div className="icon">
                          <p className="c-tx-tenth mb-0">{Check}</p>
                        </div>
                        <p className="c-tx-tenth mb-0">
                          <span className="c-tx-primary f-700">{attribute?.key} : </span>
                          <span className="c-tx-forth  f-700">Require </span>({capitalizeFirstLetter(attribute?.description)})
                        </p>
                      </li>
                    );
                  })}
                {apiExplor?.attributes?.optionalField?.length > 0 &&
                  apiExplor?.attributes?.optionalField?.map((attribute: any, index: number) => {
                    return (
                      <li key={index}>
                        <div className="icon">
                          <p className="c-tx-tenth mb-0">{Check}</p>
                        </div>
                        <p className="c-tx-tenth mb-0">
                          <span className="c-tx-primary f-700">{attribute?.key} : </span>
                          <span className="c-tx-forth  f-700">Optional </span>({attribute?.description})
                        </p>
                      </li>
                    );
                  })}
              </ul>
            </div>
            {/**
             * @api_url
             */}
            <div className="url-block-inner">
              <div className="sp-1 d-inline-flex align-items-center justify-content-center">
                <p className="c-tx-primary f-700 mb-0">URL:</p>
              </div>
              <div className="sp-2 d-inline-flex align-items-center ">
                <p className="c-tx-forth f-700 mb-0">{!isSandboxOrLive ? apiExplor?.url : apiExplor?.liveUrl}</p>
              </div>
            </div>
            {/**
             * @note
             */}
            {apiExplor?.messages?.notes?.length > 0 && (
              <div className="custom-card-block">
                <p className="c-tx-primary f-700 mb-0 lh-17">Note</p>
                <div className="divider"></div>
                {apiExplor?.messages?.notes?.map((message: string, index: number) => {
                  return (
                    <p key={index} className="c-tx-ninth mb-0">
                      {message}
                    </p>
                  );
                })}
              </div>
            )}
            {/**
             * @tips
             */}
            {apiExplor?.messages?.tips?.length > 0 && (
              <div className="custom-card-block">
                <p className="c-tx-primary f-700 mb-0 lh-17">Tips</p>
                <div className="divider"></div>
                {apiExplor?.messages?.tips?.map((message: string, index: number) => {
                  return (
                    <p key={index} className="c-tx-ninth mb-0">
                      {message}
                    </p>
                  );
                })}
              </div>
            )}
            {/**
             * @important
             */}
            {apiExplor?.messages?.important?.length > 0 && (
              <div className="custom-card-block">
                <p className="c-tx-primary f-700 mb-0 lh-17">Important</p>
                <div className="divider"></div>
                {apiExplor?.messages?.important?.map((message: string, index: number) => {
                  return (
                    <p key={index} className="c-tx-ninth mb-2">
                      {message}
                    </p>
                  );
                })}
              </div>
            )}

            {apiExplor.body?.bStamp && (apiExplor.body?.bStamp === "create" || apiExplor.body?.bStamp === "e-sign" || apiExplor.body?.bStamp === "create-multi") && (
              <div>
                <label htmlFor={`upload-file${index}`}>
                  <Input
                    className="d-none"
                    type="file"
                    id={`upload-file${index}`}
                    name="upload-file"
                    ngf-max-size="30MB"
                    multiple={apiExplor.body?.bStamp === "create-multi"}
                    onChange={(e) => {
                      if (apiExplor.body?.bStamp === "create-multi") {
                        multipleStampFileSelect(e, index);
                      } else {
                        stampFileSelect(e, index);
                      }
                    }}
                  />
                  <p className="form-control d-flex align-items-center justify-content-center">{bStampFile ? bStampFile : "Choose File"}</p>
                </label>
              </div>
            )}
            <div>
              <AccordianWrap
                multipleStamp={multipleStamp}
                activeAPICall={apiExplor}
                apiDetails={apiDetails}
                indexVal={index}
                setApiDetails={setApiDetails}
                showAccordian1={showAccordian1}
                setShowAccordian1={setShowAccordian1}
                clientId={apiDetails?.data?.clientId}></AccordianWrap>
            </div>
            <div className="execute-button text-end">
              <button
                className="small custom-primary"
                onClick={() => {
                  !showAccordian1 && setShowAccordian1(true);
                  executeAPI(apiExplor, index);
                }}>
                Execute
              </button>
            </div>
          </div>
        </div>
      </Col>
      <Col sm="12" md="12" lg="12" xl="5" xxl="5">
        <div className={`inner-common-block error-block st-sticky ${isScrolling ? "" : "stickey-149"}`}>
          <div className="error-header common-pad mb-0">
            <ul className="code-links">
              <li className={`${!apiExplor.isExecute ? "active" : ""}`} onClick={() => setReqVisible(!apiExplor.isExecute, index)}>
                <p className=" mb-0 text-uppercase">Request </p>
              </li>
              <li className={`${apiExplor.isExecute ? "active" : ""}`} onClick={() => setReqVisible(!apiExplor.isExecute, index)}>
                <p className=" mb-0 text-uppercase">Response </p>
              </li>
            </ul>
          </div>
          <div className="">
            <ul className="common-pad mb-0 code-list py-0">
              {!apiExplor.isExecute ? (
                <>
                  <li>
                    <pre className="tx-white" style={{ color: "white" }}>
                      header:
                      {JSON.stringify(handleJson(apiExplor.header), null, 2)}
                    </pre>
                  </li>
                  {Object.keys(apiExplor?.requestParams).length === 0 ? null : (
                    <li>
                      <pre className="tx-white" style={{ color: "white" }}>
                        data:
                        {JSON.stringify(apiExplor.requestParams, null, 2)}
                      </pre>
                    </li>
                  )}
                </>
              ) : (
                <li>
                  <pre className="tx-white" style={{ color: "white" }}>
                    {JSON.stringify(response, null, 2)}
                  </pre>
                </li>
              )}
            </ul>
          </div>
        </div>
      </Col>
      <Modal centered isOpen={subscribeModal} toggle={() => setSubscribeModal(!subscribeModal)} style={{ maxWidth: "450px" }} backdropClassName="confirmation-model-parent">
        <ModalBody>
          <div style={{ padding: "30px" }}>
            <h4 className="fw-bold text-center mb-3">Wants to Execute?</h4>
            <p className="text-center font-16 mt-20" style={{ color: "#868686" }}>
              Subscription is required before you try to execute. So Please subscribe and come back again.
            </p>
            <div className="d-flex justify-content-center mt-20">
              <button
                className="small custom-primary"
                onClick={() => {
                  setSubscribeModal(!subscribeModal);
                }}>
                ok
              </button>
            </div>
          </div>
        </ModalBody>
      </Modal>
      {/* <ConfirmationModal title="Are you sure you want to unsubscribe" /> */}
    </Row>
  );
};

export default ApiDetailComponent;
