import { getChainCodeListAPI } from "actions/getChainCodeListAPI";
import { getTemplateListAPI, IgetTemplateListAPIData } from "actions/getTemplateListAPI";
import { NoFoundTemplate } from "components/notFoundPages";
import { TemplateBlock } from "components/template";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { Col, FormGroup, Row } from "reactstrap";
import { setLoading } from "store/loader/action";
import { IRootReducer } from "store/root-reducer";
import { makeFullS3ObjectUrl } from "utils/makeFullS3ObjectUrl";
import { useWebsocket } from "utils/WebSocketProvider";
import PaginationComponent from "../pagination/PaginationComponent";
import ReviewConfirmationTemplateModal from "../templateAndContacts/ReviewConfirmationTemplateModal";
import InstallTemplateModal from "./InstallTemplateForm";
import SuccessTemplateModal from "./SuccessTemplateModal";
import TemplateViewDetailsModal from "./TemplateViewDetailsModal";

const logo = makeFullS3ObjectUrl("edexa-logo.png");
const languageOptions = [
  { value: "1", label: "Default Templates" },
  { value: "2", label: "Installed Templates" }
];

interface socketChainCode {
  chainCodeId: string;
  fields: {
    tokenName: string;
    tokenSymbol: string;
    totalSupply: number;
  };
  module: string;
  status: number;
}

const TemplateListComponent = () => {
  const socket = useWebsocket();
  const dispatch = useDispatch();
  const { user } = useSelector((state: IRootReducer) => state.userDetails);

  const [templateListData, setTemplateListData] = useState<IgetTemplateListAPIData[]>([]);
  const [template, setTemplate] = useState<any>({
    value: "1",
    label: "Default Templates"
  });
  const [pagination, setPagination] = useState({
    limit: 5,
    page: 1,
    totalCount: 0
  });

  const getTemplateList = useCallback(() => {
    dispatch(setLoading(true));
    getTemplateListAPI({
      page: pagination.page,
      limit: pagination.limit
    }).then((res) => {
      if (res.status === 200) {
        setPagination({
          ...pagination,
          totalCount: res.data.count
        });
        setTemplateListData(res.data.data);
      }
      dispatch(setLoading(false));
    });
  }, [dispatch, pagination, setTemplateListData, setPagination]);

  const getChainCodeList = useCallback(() => {
    dispatch(setLoading(true));
    getChainCodeListAPI({
      page: pagination.page,
      limit: pagination.limit
    }).then((res) => {
      if (res.status === 200) {
        setPagination({
          ...pagination,
          totalCount: res.data.count
        });
        const arr = res.data.data.map((data: any) => {
          return {
            id: data?.id,
            name: data?.fields?.tokenName,
            disable: false,
            description: data?.chainCodeTemplateId?.description,
            status: data?.status
          };
        });
        setTemplateListData(arr);
      }
      dispatch(setLoading(false));
    });
  }, [dispatch, pagination, setTemplateListData, setPagination]);

  const handleTemplate = useCallback(
    (e: any) => {
      setTemplate(e);
      setPagination({ ...pagination, page: 1 });
    },
    [pagination]
  );

  useEffect(() => {
    template?.value === "1" ? getTemplateList() : getChainCodeList();
    // eslint-disable-next-line
  }, [template, pagination.page]);

  useEffect(() => {
    if (user?.id) {
      socket?.on(`Chaincode_create_${user?.id}`, (data: socketChainCode) => {
        setTemplateListData((res: any[]) => {
          let newArray = res.map((item) => {
            if (item.id === data.chainCodeId) {
              return { ...item, status: data.status };
            }
            return item;
          });
          return newArray;
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.id]);

  return (
    <div className="backdrop-block white-wrapper">
      <div className="d-sm-flex justify-content-between">
        <h5 className="c-tx-primary f-600">
          {template?.value === "1" ? "Created by edeXa (Default Templates)" : `Installed Template ${templateListData.length === 0 ? "" : "(" + templateListData.length + ")"}`}
        </h5>
        <FormGroup className="select-component">
          <Select options={languageOptions} id="language" onChange={(e) => handleTemplate(e)} value={template} placeholder="Select Language" />
          <p className="c-tx-ninth small">You can select Template</p>
        </FormGroup>
      </div>
      <div className="column_list">
        {templateListData.length > 0 ? (
          templateListData.map((data, i) => (
            <div className="element" key={i}>
              <TemplateBlock data={data} type={template?.value === "1" ? "template" : "chainCode"} />
            </div>
          ))
        ) : (
          <NoFoundTemplate message="No Template Found" bageName="Template" logo={logo} />
        )}
      </div>
      {/* installed template modal  */}
      <InstallTemplateModal />

      {/* Erc information pop up start */}
      <TemplateViewDetailsModal />
      {/* Erc information pop up end */}

      {/* Success modal */}
      <SuccessTemplateModal />

      <ReviewConfirmationTemplateModal />
      <div className="pagination-wrap mt-0">
        <Row>
          <Col md="12" className="d-flex justify-content-end">
            <PaginationComponent pagination={pagination} setPagination={setPagination} />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default TemplateListComponent;
